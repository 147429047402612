import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Avatar, Popover, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams, useSearchParams, useRouteError } from "react-router-dom";

import TVChartContainer from '../../components/TVChartContainer';


import emitter from '../../unilt/emit';
import axios from '../../unilt/axios';
import mianmenu11 from '../../static/mianmenu11.png'
import mianmenu12 from '../../static/mianmenu12.png'

import mianmenu31 from '../../static/mianmenu31.png'
import mianmenu32 from '../../static/mianmenu32.png'
import search from '../../static/search.png'

import copy from '../../static/copy.png'
import Clipboard from 'clipboard'


import close from '../../static/close.png'
import looktopbtn from '../../static/looktopbtn.png'
import lookdownbtn from '../../static/lookdownbtn.png'

import errico from '../../static/errico.png'

import btc from '../../static/btc.png'
import newselect from '../../static/newselect.png'
import starh from '../../static/star-h.png'
import starb from '../../static/star-b.png'

import addpool from '../../static/addpool.png'


import getgoll from '../../static/getgoll.png'

import * as echarts from 'echarts';
import dayjs from 'dayjs'
import newlocal from '../../unilt/newlocal';
import Api from '../../unilt/api'
import linkData from '../../unilt/linkTypeData'

import loadingz from '../../static/loadingz.png'
import loadingzwhite from '../../static/loadingzwhite.png'

import { ConfigProvider } from 'antd';

import enUs from 'antd/es/locale/en_US';
import zhHK from 'antd/es/locale/zh_HK';


import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'


var Web3 = require('web3')

const keymsg = 'loadinddsghy';

const Avaterlist = ['#F28D0A', '#7C26CC', '#F45E3E', '#0A8DF2', '#3FBD6D', '#08C8C0', '#D50202', '#32AA92']

const jsonrc1 = [
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [{ "name": "account", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "allowance",
    "inputs": [{ "name": "owner", "type": "address" }, { "name": "spender", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "approve",
    "inputs": [{ "name": "spender", "type": "address" }, { "name": "amount", "type": "uint256" }],
    "outputs": []
  }
]

const jsonrc2 = [
  {
    "type": "function",
    "name": "createPool",
    "inputs": [{ "name": "collateralToken", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "queryPoolAddress",
    "inputs": [{ "name": "collateralToken", "type": "address" }],
    "outputs": [{ "name": "address", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


const jsonrc3 = [
  {
    "type": "function",
    "name": "getVaultState",
    "inputs": [],
    "outputs": [{ "name": "poolAmount", "type": "uint256" }, { "name": "maxPoolAmount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getVaultConfig",
    "inputs": [],
    "outputs": [{ "name": "factoryAddress", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "minExecutionFee", "type": "uint256" }, { "name": "collateralToken", "type": "address" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }, { "name": "minOrderAmount", "type": "uint256" }, { "name": "maxNumOfOrdersPerUser", "type": "uint256" }, { "name": "priceOracle", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


const jsonrc4 = [
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [{ "name": "account", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "addLiquidity",
    "inputs": [{ "name": "amount", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "removeLiquidity",
    "inputs": [{ "name": "lpAmount", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "openPosition",
    "inputs": [{ "name": "amount", "type": "uint256" },
    { "name": "intervalType", "type": "uint8" },
    { "name": "indexAssetId", "type": "uint8" },
    { "name": "isLong", "type": "bool" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getUserOrders",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'marketId',
          type: 'uint8',
        },
        {
          name: 'amount',
          type: 'uint256',
        },
        {
          name: 'isLong',
          type: 'bool',
        },
        {
          name: 'winningRate',
          type: 'uint256',
        },
        {
          name: 'executionFee',
          type: 'uint256',
        },
        {
          name: 'createAt',
          type: 'uint256',
        },
        {
          name: 'expiryAt',
          type: 'uint256',
        },
      ],
    }]
  },
  {
    "type": "function",
    "name": "closePosition",
    "inputs": [{ "name": "user", "type": "address" },
    { "name": "orderTime", "type": "uint256" },
    { "name": "executionFeeReceiver", "type": "address" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "refundPosition",
    "inputs": [
      { "name": "user", "type": "address" },
      { "name": "orderTime", "type": "uint256" }
    ],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getUserLiquidity",
    "inputs": [
      { "name": "user", "type": "address" }
    ],
    "outputs": [
      { "name": "liquidityTime", "type": "uint256" },
      { "name": "tokenAmount", "type": "uint256" },
      { "name": "profit", "type": "int256" }
    ]
  },
  {
    "type": "function",
    "name": "totalSupply",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "isReward",
    "inputs": [],
    "outputs": [{ "name": "isend", "type": "bool" }]
  },
  {
    "type": "function",
    "name": "getRewards",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getUserFee",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "amount", "type": "uint256" }]
  }
]

const jsonrc5 = [
  {
    "type": "function",
    "name": "getTokenPrice",
    "inputs": [
      { "name": "marketId", "type": "uint8" },
      { "name": "timestamp", "type": "uint256" }
    ],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  }
]


const jsonrc6 = [
  {
    "type": "function",
    "name": "symbol",
    "inputs": [],
    "outputs": [{ "name": "symbol", "type": "string" }]
  }
]

const jsonrc7 = [
  {
    "type": "function",
    "name": "totalSupply",
    "inputs": [],
    "outputs": [{ "name": "d", "type": "uint256" }]
  }
]


const jsonrc8 = [
  {
    "type": "function",
    "name": "getSqrtTWAP",
    "inputs": [{ "name": "uniswapV3Pool", "type": "address" },{ "name": "twapInterval", "type": "uint32" },{ "name": "token0", "type": "address" },{ "name": "token1", "type": "address" }],
    "outputs": [{ "name": "d", "type": "uint256" }]
  }
]

let czTime = 0
let timer = false
let timer1 = false
let oldlist = []
let newwlist = []
let oldtype = 0
let keyliness = []
let createp = 0
let isadd = false
let tvScriptLoadingPromise;
let socketLastMsgTime = false;


let refundPositionTime = 0
let closePositionTime = 0
let SHTime = 0
let ZYTime = 0
let approveTime = 0
let goTransTime = 0
let TQTime = 0
let wscsflag = false
let getOrderlistlock = false

let hiddentime = 0
let provider = false

let goGetOrderList = false
let getOrderListIsCheck = false
let getOrderListHash = false
let oldOrderList = []
let goGetJlReward = false
let getJlRewardRes = false


let gogetallowance = false
let gogetallowanceRes = false

let gogeban = false
let gogebanRes = false

let gogetVaultState = false
let gogetVaultStateRes = false

let gogetLp = false
let gogetLpRes = false

let gogetZyinfo = false
let gogetZyinfoRes = false

let gogettotalSupply = false
let gogettotalSupplyRes = false

let hy1w = false
let hy2w = false
let hy3w = false
let hy4w = false
let hy5w = false

let nowcz = false

let userw = false

let userlistlength = 0

let loadingpage = false

let nowuniSwap = false


function MainNew() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const user = useSelector(state => state.user)
  const language = useSelector(state => state.language)
  const assetsType = useSelector(state => state.assetsType)
  const linktype = useSelector(state => state.linktype)
  const activelist = useSelector(state => state.activelist)
  const assets = useSelector(state => state.assets)
  const bbzPrice = useSelector(state => state.bbzPrice)
  const btcPrice = useSelector(state => state.btcPrice)
  const onLoadScriptRef = useRef(false);
  const renderRef = useRef(true)
  const renderRefhy1 = useRef(true)
  const renderRefhy2 = useRef(true)
  const renderRefhy3 = useRef(true)
  const renderReflinktype = useRef(true)
  const renderRefhy = useRef(true)

  const [getParams, setParam] = useSearchParams()
  const renderRefhy4 = useRef(true)
  const myChart = useRef(false)
  const araeDiv = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let [menuState, setmenuState] = useState(1)
  let [symbol, setsymbol] = useState(false)
  let [symbolPair, setSymbolPair] = useState(false)
  let [symbolList, setsymbolList] = useState([])
  let [symbolBl, setsymbolBl] = useState([50, 50])
  let [payingNumber, setPayingNumber] = useState(0)
  let [keyLineDate, setKeyLineDate] = useState([])
  let [ekeyLineDate, seteKeyLineDate] = useState([])
  let [bkeyLineDate, setbKeyLineDate] = useState([])
  let [eurkeyLineDate, seteurKeyLineDate] = useState([])
  let [keyConfig, setKeyConfig] = useState(false)
  let [tab, setTab] = useState(1)
  let [tab2, setTab2] = useState(1)
  let [showPriceChangeBox, setShowPriceChangeBox] = useState(false)
  let [showTimeChangeBox, setShowTimeChangeBox] = useState(false)
  let [time, settime] = useState(0)
  let [changeSymbol, setChangeSymbol] = useState(false)
  let [windowActive, setwindowActive] = useState(false)
  let [reload1, setreload1] = useState(false)
  let [changeOrder, setchangeOrder] = useState('')
  let [userTip, setUserTip] = useState(false)
  let [orderlist, setorderlist] = useState([])
  let [orderlist1, setorderlist1] = useState([])
  let [orderlist2, setorderlist2] = useState([])

  let [endorderlist, setendorderlist] = useState([])
  let [alltz, setalltz] = useState(0)
  let [alllr, setalllr] = useState(0)
  let [poolList, setpoolList] = useState([])
  let [cz, setcz] = useState(false)

  let [showmode1, setshowmode1] = useState(false)
  let [showmode2, setshowmode2] = useState(false)
  let [showmode3, setshowmode3] = useState(false)
  let [showmode4, setshowmode4] = useState(false)
  let [showmode5, setshowmode5] = useState(false)

  let [pageSize, setpageSize] = useState(10)
  let [pageNumber, setpageNumber] = useState(1)
  let [pageTotal, setpageTotal] = useState(0)


  let [zy1, setzy1] = useState(0)
  let [zy2, setzy2] = useState(0)

  let [totalSupply, settotalSupply] = useState(false)

  let [frameflag, setframeflag] = useState(false)
  let [frameflag1, setframeflag1] = useState(1)


  let [hy1, sethy1] = useState(false)
  let [hy2, sethy2] = useState(false)
  let [hy3, sethy3] = useState(false)
  let [hy4, sethy4] = useState(false)
  let [hy5, sethy5] = useState(false)
  let [hy1send, sethy1send] = useState(false)
  let [hy2send, sethy2send] = useState(false)
  let [hy3send, sethy3send] = useState(false)
  let [hy4send, sethy4send] = useState(false)
  let [hy5send, sethy5send] = useState(false)


  let [liquidityTime, setliquidityTime] = useState(0)
  let [tokenAmount, settokenAmount] = useState(false)
  let [profit, setprofit] = useState(false)


  let [poolAmount, setpoolAmount] = useState(false)
  let [maxPoolAmount, setmaxPoolAmount] = useState(false)
  let [maxPoolAmountuser, setmaxPoolAmountuser] = useState(false)


  let [isSq, setisSq] = useState(false)
  let [sqAmount, setsqAmount] = useState(0)
  let [collateralToken, setcollateralToken] = useState(false)
  let [maxNumOfOrdersPerUser, setmaxNumOfOrdersPerUser] = useState(false)
  let [maxOrderAmountFraction, setmaxOrderAmountFraction] = useState(false)
  let [minExecutionFee, setminExecutionFee] = useState(false)
  let [minOrderAmount, setminOrderAmount] = useState(0)
  let [priceOracle, setpriceOracle] = useState(false)
  let [treasuryFee, settreasuryFee] = useState(false)
  let [winningRate, setwinningRate] = useState(false)

  let [lp, setlp] = useState(false)



  let [balance, setbalance] = useState(0)
  let [price, setprice] = useState('25')
  let [priceErrormsg, setpriceErrormsg] = useState('')
  let [showpriceErrormsg, setshowpriceErrormsg] = useState(false)
  let [showpriceErrormsg2, setshowpriceErrormsg2] = useState(false)
  let [showpriceErrormsg3, setshowpriceErrormsg3] = useState(false)

  let [approveBox, setApproveBox] = useState(false)



  let [timelist, settimelist] = useState([])


  let [show1, setshow1] = useState(false)
  let [show2, setshow2] = useState(false)

  let [address, setaddress] = useState('')
  let [taddress, settaddress] = useState('')
  let [iscz, setsicz] = useState(false)
  let [tname, settname] = useState(false)
  let [value, setvalue] = useState('')

  let [jyloading, setjyloading] = useState(false)


  let [cvaleu1, setcvaleu1] = useState('')
  let [cvaleu2, setcvaleu2] = useState(200)
  let [cvaleu3, setcvaleu3] = useState(7500)
  let [cvaleu4, setcvaleu4] = useState(500)

  const renderRefs = useRef(true)
  const renderRefcopy = useRef(true)
  const renderh5Refs = useRef(true)
  const [message1, setMessage] = useState(false);

  let [startPageTime, setstartPageTime] = useState(false)


  let [jlamount, setjlamount] = useState('0')
  const [isgerawd, setisgerawd] = useState(false);


  const nprovider = useSelector(state => state.nprovider)

  let ws = false


  let [loadam1, setloadam1] = useState(1)
  let [loadam2, setloadam2] = useState(1)


  let [orderItem, setorderItem] = useState(false)
  let [showorderItem, setordershowItem] = useState(false)
  let [orderItem2, setorderItem2] = useState(false)
  let [showorderItem2, setordeshowItem2] = useState(false)


  const signtoken = useSelector(state => state.signtoken)
  const [pageInfo, setpageInfo] = useState(false)


  let [tokenp, settokenp] = useState(100)

  useEffect(() => {
    provider = nprovider
  }, [nprovider])



  useEffect(() => {
    if (renderh5Refs.current === true) {
      renderh5Refs.current = false
      setInterval(() => {
        checkorderItem1()
        checkorderItem2()
      }, 1000);
    }

  }, [orderItem, orderItem2])

  const checkorderItem1 = (() => {
    if (orderItem) {
      let newobj = orderItem
      let newtime = dayjs().diff(dayjs(orderItem.expiryAt * 1000), 'seconds')
      let min = (parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))
      let sec = (parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))
      let xsTime = `${min}:${sec}`
      newobj.xsTime = xsTime
      setorderItem(newobj)
    }


  })

  const checkorderItem2 = (() => {
    if (orderItem2) {
      let newobj = orderItem
      let newtime = dayjs().diff(dayjs(orderItem2.expiryAt * 1000), 'seconds')
      let min = (parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))
      let sec = (parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))
      let xsTime = `${min}:${sec}`
      newobj.xsTime = xsTime
      setorderItem2(newobj)
    }

  })

  useEffect(
    () => {
      if (onLoadScriptRef.current === false) {
        onLoadScriptRef.current = true;
      }

    },
    [language]
  );






  useEffect(() => {
    if (renderRefs.current) {
      renderRefs.current = false
      wscsflag = true
      initws()
      optest()
      return () => {
      };
    } else {
      if (wscsflag) {
        try {
          if (user === false || user === null || user === 'null') {
            ws && ws.close()
            initws()
          } else {
            ws && ws.close()
            initws()
          }
        } catch {

        }
      }
    }
  }, [ws, user]);

  const initws = (() => {
    ws && ws.close()
    ws = new WebSocket(Api.wss);
    ws.onopen = (() => {
      ws.send(`${user}`)
      ws.send(`get start `)
    })
    ws.onerror = ((error, s) => {
      ws.close()
      // initws()
    })
    ws.onclose = ((error, s) => {
      ws.close()
    })
    ws.onmessage = gosend
  })

  const optest = (() => {
    let nowtime = dayjs().unix()
    if (socketLastMsgTime !== false && (nowtime - socketLastMsgTime >= 5)) {
      ws.close()
      initws()
    }
    setTimeout(() => {
      optest()
    }, 1000)
  })


  const gosend = ((e) => {
    socketLastMsgTime = dayjs().unix()
    let mess = e.data
    mess = mess.slice(0, -2)
    mess = JSON.parse(mess)
    setMessage(mess)
  })

  useEffect(() => {
    if (message1) {
      let mess = message1
      if (mess.type === 'connection') {
      }
      if (mess.type === 'closePosition') {
        setpageNumber(1)
        getendlist()
        goGetOrderList = true
        gogetallowance = true
        gogeban = true
        if (mess.orderInfo.orderResult === 3) {

        } else {
          message.info({
            content: <div>
              <div>{newlocal['main']['p74'][language]}</div>
              <div>
                <span>{mess.orderInfo.orderType === 0 ? newlocal['main']['p71'][language] : ''}{mess.orderInfo.orderType === 1 ? newlocal['main']['p72'][language] : ''}</span>
                <span style={{ marginLeft: '5px', color: (mess.orderInfo.orderResult === 1 || mess.orderInfo.orderResult === 2) ? '#31CB7F' : '#FF5F57' }}>{(mess.orderInfo.orderResult === 1 || mess.orderInfo.orderResult === 2) ? '+' : '-'}{mess.orderInfo.profit}</span>
              </div></div>, duration: 3
          });
        }
      }
      if (mess.type === 'order') {
        setchangeOrder(mess.orderInfo.orderId)
      }
      if (mess.type === 'price' && mess.pair.symbol === 'BTCUSD') {
        dispatch({ type: 'SETBTCPRICE', btcPrice: mess.pair })
        createp = mess.pair
      }
      if (mess.type === 'price' && mess.pair.symbol === 'BTCUSD') {
        mess.pair.dataTime = dayjs(mess.pair.time).format('HH:mm:ss')
        let data = [...bkeyLineDate, mess.pair]
        if (bkeyLineDate.length <= 0) {
          setbKeyLineDate([])
        } else {
          if (mess.pair.time > bkeyLineDate[0].time) {
            let data = [...bkeyLineDate, mess.pair]
            data = data.slice(data.length - 10000, data.length)
            setbKeyLineDate(data)
            setKeyLineDate(data)
            keyliness = data
          }
        }
      }
    }
  }, [message1]);







  useEffect(() => {
    if (windowActive) {
      if (Number(dayjs().unix()) * 1000 > hiddentime) {
        setframeflag(true)
      }
    }
  }, [windowActive])










  const getType = (async (iscallback = false, ift = 'no') => {
    let str = ''
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    message.loading({ content: str, key: 'czlodaingg', duration: 0 });
    let res = axios({
      url: 'pool/poolList',
      method: 'GET',
    })
    res.then(async (resp) => {
      const pool = getParams.getAll('pool')
      const type = getParams.getAll('type')
      let num = 0
      let tb = type.length > 0 ? type[0] : 1
      setTab2(Number(tb))
      let lastaddrss = localStorage.getItem('afterCZ')

      if (resp.content.length <= 0) {
        message.destroy('czlodaingg')
        return false
      }

      for (let i in resp.content) {
        resp.content[i].winningRate = (Number(resp.content[i].winningRate) / 100).toString()
        resp.content[i].colorindex = i % 7
      }
      for (let i in resp.content) {
        resp.content[i].issc = false
        if (pool.length > 0 && pool[0].toLowerCase() === resp.content[i].address.toLowerCase()) {
          num = i
          break;
        }
        if (resp.content[i].address === lastaddrss) {
          num = i
        }
      }
      if (loadingpage === false && pool.length > 0) {
        goldCZ(pool, tb)
        if (user === false || user === null || user === 'null') {
          message.destroy('czlodaingg')
          setpoolList(resp.content)
        } else {
          getType2(resp.content, num, tb, iscallback)
        }
      } else {
        if (user === false || user === null || user === 'null') {
          message.destroy('czlodaingg')
          setpoolList(resp.content)
          setcz(resp.content[Number(num)])
          nowcz = resp.content[Number(num)]
          setloadam1(1)
          setloadam2(1)
          setTab2(Number(tb))
        } else {
          getType1(resp.content, num, tb, iscallback)
        }
      }
      loadingpage = true

    })
  })

  const goldCZ = (async (pool, tb) => {
    let res = axios({
      url: 'pool/pool-info',
      method: 'GET',
      data: {
        poolAddress: pool[0]
      }
    })
    res.then(async (resp) => {

      resp.content.winningRate = (Number(resp.content.winningRate) / 100).toString()
      setcz(resp.content)
      nowcz = resp.content
      setloadam1(1)
      setloadam2(1)
      setTab2(Number(tb))
    })
  })

  const getType1 = (async (list, a, b, iscallback) => {
    let fisrtsc = 0
    let flag = false
    let nowlist = list ? list : poolList
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      for (let i in resp.content) {
        for (let j in nowlist) {
          if (nowlist[j].address === resp.content[i].address) {
            nowlist[j].issc = true
            flag = true
            if (!flag) {
              fisrtsc = i
            }
          }
        }
      }
      setpoolList(nowlist)
      if (Number(a) === 0) {
        setcz(nowlist[Number(fisrtsc)])
        setloadam1(1)
        setloadam2(1)
        nowcz = nowlist[Number(fisrtsc)]
      } else {
        setcz(nowlist[Number(a)])
        setloadam1(1)
        setloadam2(1)
        nowcz = nowlist[Number(a)]
      }
      setTab2(Number(b))
      if (iscallback) {
        getHyYe()
      } else {
        message.destroy('czlodaingg')
      }
    })
  })

  const getType2 = (async (list, a, b, iscallback) => {
    let fisrtsc = 0
    let flag = false
    let nowlist = list ? list : poolList
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      for (let i in resp.content) {
        for (let j in nowlist) {
          if (nowlist[j].address === resp.content[i].address) {
            nowlist[j].issc = true
            flag = true
            if (!flag) {
              fisrtsc = i
            }
          }
        }
      }
      setpoolList(nowlist)
      if (iscallback) {
        getHyYe()
      } else {
        message.destroy('czlodaingg')
      }
    })
  })

  const getTransTime = (() => {
    let res = axios({
      url: 'config/intervals',
      method: 'GET',
      isnotlog: true
    })
    res.then(async (resp) => {
      let list = resp.content
      for (let i in list) {
        if (list[i] >= 3600) {
          list[i] = {
            value: parseInt(list[i] / 3600),
            type: 'p99'
          }
        } else if (list[i] < 3600 && list[i] >= 60) {
          list[i] = {
            value: parseInt(list[i] / 60),
            type: 'p98'
          }
        } else {
          list[i] = {
            value: list[i],
            type: 'p97'
          }
        }
      }
      settimelist(list)
    })
  })

  const goshouc = (async (item, type, e) => {
    e && e.stopPropagation()
    e && e.nativeEvent.stopImmediatePropagation()
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    let str = ''
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    message.loading({ content: str, key: 'czlodaingg', duration: 0 });

    let res = axios({
      url: 'pool/collect/follow',
      method: 'POST',
      data: {
        address: user,
        poolAddress: item.address,
        isFollow: type
      }
    })
    res.then(async (resp) => {
      if (type === 'YES') {
        message.success({ content: newlocal['main']['p87'][language], key: 'sdffggs' })
      }
      getType(false)
    })
  })



  useEffect(() => {
    if (cz) {
      oldlist = []
      localStorage.setItem('afterCZ', cz.address)
      getHyYe()
      getuniswap()
      return
    }
  }, [cz])




  const getKline = ((type = 'no', fst = 'no') => {
    symbol = 'BTCUSD'
    let res = axios({
      url: 'data/prices',
      method: 'GET',
      data: {
        symbol: symbol,
        pageSize: 10000
      },
      isnotlog: true
    })
    res.then((resp) => {
      let datalist = resp.content
      for (let i in datalist) {
        datalist[i].dataTime = dayjs(datalist[i].time).format('HH:mm:ss')
      }
      let list = datalist.reverse()
      if (symbol === 'BTCUSD') {
        setbKeyLineDate(list)
        setKeyLineDate(list)
        keyliness = list
      }
      if (type === 're') {
        if (myChart.current && list.length > 0) {
          let mm = list[list.length - 1].time - list[0].time + 3600000
          let nn = list[list.length - 1].time - list[0].time
          if (symbol === 'symbol') {
            let opt = myChart.current.getOption()
            myChart.current.clear()
            myChart.current = echarts.init(araeDiv.current)
            opt.dataZoom[0].start = 57.5
            opt.dataZoom[0].end = 59.5
            opt.yAxis[0].min = function (value) {
              return value.min - 1;
            }
            opt.yAxis[0].max = function (value) {
              return value.max + 1;
            }
            myChart.current.setOption(opt, { notMerge: true })
          }
        }
      }
    })
  })


  useEffect(() => {
    if (tab === 2) {
      setpageNumber(1)
      getendlist()
      return
    }
  }, [tab])

  const changepagenumber = ((page, size) => {
    if (size !== pageSize) {
      setpageNumber(1)
      setpageSize(size)
    } else {
      setpageNumber(page)

    }
  })






  useEffect(() => {
    getendlist()
  }, [pageNumber, pageSize])


  const getendlist = (() => {
    if (user === false || user === null || user === 'null') {
      return false
    }
    let res = axios({
      url: 'pool/order-list-page',
      method: 'GET',
      data: {
        address: user,
        orderStatus: 1,
        poolAddress: cz.address,
        pageNumber: pageNumber,
        pageSize: pageSize

      }
    })
    res.then(async (resp) => {
      let list = resp.content.list
      let newlist = []
      for (let i in list) {
        let newitem = list[i]
        list[i].isLong = list[i].orderType === 1 ? true : false
        let newtime = dayjs(list[i].createAt * 1000).diff(dayjs(list[i].expiryAt * 1000), 'seconds')
        let nowPrice = list[i].expiryPrice
        let creatPrice = list[i].createPrice
        let isWin = 1
        let lr = 0
        if (Number(newitem.orderResult) === 2) {
          isWin = 3
        } else if (Number(newitem.orderResult) === 1) {
          isWin = 1
        } else if (Number(newitem.orderResult) === 3) {
          isWin = 4
        } else {
          isWin = 2
        }
        newitem.isWin = isWin
        let min = (parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))
        let sec = (parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))
        let xsTime = `${min}:${sec}`
        newitem.xsTime = xsTime
        if (isWin === 1) {
          lr = Number(Number(newitem.amount) / 10 ** 18) * Number(cz.winningRate) / 100
        } else if (isWin === 2) {
          lr = 0 - Number(Number(newitem.amount) / 10 ** 18)
        } else if (isWin === 3) {
          lr = 0
        }
        newitem.lr = lr
        newitem.openPrice = creatPrice
        newitem.createTime = newitem.createAt * 1000
        newitem.closeTimes = newitem.expiryAt * 1000
        if (newitem.isLong) {
          newitem.orderType = 'UP'
        } else {
          newitem.orderType = 'DOWN'
        }
        newlist.push(newitem)
      }
      setendorderlist(newlist)
      setpageTotal(resp.content.total)
    })
  })


  useEffect(() => {
    if (tab2 === 2) {
      if (renderRefcopy.current) {
        renderRefcopy.current = false
        const copy2 = new Clipboard('.copy-btn2');
        copy2.on('success', e => {
          message.success({ content: newlocal['main']['copytip'][language], key: 'sdff' })
        });
        copy2.on('error', function (e) {
          console.error('Action:', e.action);
          console.error('Trigger:', e.trigger);
        });
      }
      return
    }
  }, [tab2])





  useEffect(() => {
    if (symbol) {
      seteKeyLineDate([])
      setbKeyLineDate([])
      return
    }
  }, [symbol])

  useEffect(() => {

    if (renderRef.current) {
      oldtype = 0
      gogetallowance = false
      gogetallowanceRes = false

      gogeban = false
      gogebanRes = false

      gogetVaultState = false
      gogetVaultStateRes = false

      gogetLp = false
      gogetLpRes = false

      gogetZyinfo = false
      gogetZyinfoRes = false

      gogettotalSupply = false
      gogettotalSupplyRes = false

      loadingpage = false

      renderRef.current = false
      getTransTime()
      hiddentime = Number(dayjs().add(30, 'minute').unix()) * 1000
      var hiddenProperty = 'hidden' in document ? 'hidden' :
        'webkitHidden' in document ? 'webkitHidden' :
          'mozHidden' in document ? 'mozHidden' : null;
      var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
      var onVisibilityChange = function () {
        if (!document[hiddenProperty]) {
          setwindowActive(true)
        } else {
          setwindowActive(false)
        }
      }
      document.addEventListener(visibilityChangeEvent, onVisibilityChange);
      setInterval(()=>{
        gettokenp()
      },1000)

    }
  }, [])


  const getuniswap = (()=>{
    let res = axios({
      url: 'system/load/uniswap',
      method: 'GET',
      isLetUrl: 1,
      data: {
        tokenAddress: nowcz.tokenAddress,
        chain: linkData[linktype.toString()].xm
      }
    })
    res.then(async (resp) => {
      nowuniSwap = resp.content
    })
  })

  const gettokenp = (async () => {
    if (nowcz !== false && nowuniSwap !== false  && nowuniSwap !== "0x0000000000000000000000000000000000000000") {
      const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
      web3.eth.defaultAccount = user
      var contractAddress = linkData[linktype.toString()].jladdress;
      var hy = await new web3.eth.Contract(jsonrc8, contractAddress)
      var res = await hy.methods.getSqrtTWAP(nowuniSwap,3600,linkData[linktype.toString()].lbaddresstbbz,nowcz.tokenAddress).call()
      settokenp(Number(res) / (10 ** 18))
    } else {
      settokenp(100)
    }
  })




  useEffect(() => {

    if (renderRefhy.current && hy1 && hy2 && hy3 && hy4 && hy5) {
      renderRefhy.current = false
      goCheck()
    }
  }, [hy1, hy2, hy3, hy4, hy5])


  const goCheck = (() => {
    if (goGetOrderList) {
      getorDerList()
    }
    if (goGetJlReward) {
      getisrawd()
    }
    if (gogetallowance || loadam2 === 1) {
      getallowance()
    }
    if (gogeban || loadam1 === 1) {
      geban()
    }
    if (gogetVaultState) {
      getVaultState()
    }
    if (gogetLp) {
      getLp()
    }
    if (gogetZyinfo) {
      getZyinfo()
    }

    if (gogettotalSupply) {
      gettotalSupply()
    }

    setTimeout(() => {
      goCheck()
    }, 5000)
  })


  useEffect(() => {
    userw = user
    if (user === false || user === null || user === 'null') {
      setalllr(0)
      setalltz(0)
      setsqAmount(0)
      setisSq(false)
      setlp(false)
      setbalance(0)
      settotalSupply(false)
      settokenAmount(false)
      setprofit(false)
      setendorderlist([])
      setorderlist2([])
      oldlist = []
    } else {
      const invcode = getParams.getAll('invcode')
      if (invcode.length > 0) {
        if (signtoken === false || signtoken === null || signtoken === 'null') {
          emitter.emit("checksigntoken", "Hello")
        } else {
          goinputcode()
        }
      }

      if (nowcz) {
        getType(true)
      }
    }
  }, [user])

  useEffect(() => {
    if (signtoken === false || signtoken === null || signtoken === 'null') {

    } else {
      const invcode = getParams.getAll('invcode')
      if (invcode.length > 0) {
        goinputcode()
      }
    }

  }, [signtoken])

  const goinputcode = (async () => {

    const invcode = getParams.getAll('invcode')

    let re = axios({
      url: 'active/invite/code',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: invcode[0]
      }
    })
    re.then(async (res) => {
      if (res.success === '000032') {
        getuserinfo()
      } else {
       
      }
    })
  })

  const getuserinfo = (async () => {
    let that = this
    let re = axios({
      url: 'active/user/info',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setpageInfo(res.content)
        const invcode = getParams.getAll('invcode')
        if (res.content.isInvite === 0) {
          goinputcodeend(invcode)
        }
      } else {
        message.destroy('czlodaingg')
      }
    })
  })

  const goinputcodeend = ((invcode) => {
    let re = axios({
      url: 'active/invite/inputcode',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: invcode[0]
      }
    })
    re.then(async (res) => {
    })
  })



  const getHyYe = (async () => {
    let str = ''
    if (!cz) {
      return false
    }
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    message.loading({ content: str, key: 'czlodaingg', duration: 0 });
    if (window.ethereum === undefined) {
      setshowpriceErrormsg(false)
      setshowpriceErrormsg2(false)
      const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
      web3.eth.defaultAccount = user
      var contractAddress1 = cz.tokenAddress;
      var contractAddress2 = linkData[linktype.toString()].gcaddress;
      var contractAddress3 = cz.address;
      var contractAddress4 = cz.address;
      var contractAddress5 = linkData[linktype.toString()].jgaddress;
      var hyitem1 = await new web3.eth.Contract(jsonrc1, contractAddress1)
      var hyitem2 = await new web3.eth.Contract(jsonrc2, contractAddress2)
      var hyitem3 = await new web3.eth.Contract(jsonrc3, contractAddress3)
      var hyitem4 = await new web3.eth.Contract(jsonrc4, contractAddress4)
      var hyitem5 = await new web3.eth.Contract(jsonrc5, contractAddress5)
      hy1w = hyitem1
      hy2w = hyitem2
      hy3w = hyitem3
      hy4w = hyitem4
      hy5w = hyitem5
      sethy1(hyitem1)
      sethy2(hyitem2)
      sethy3(hyitem3)
      sethy4(hyitem4)
      sethy5(hyitem5)
      getendlist()
      getListern()
      message.destroy('czlodaingg')
    } else {
      setshowpriceErrormsg(false)
      setshowpriceErrormsg2(false)
      const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
      web3.eth.defaultAccount = user
      const web31 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
      web31.eth.defaultAccount = user
      var contractAddress1 = cz.tokenAddress;
      var contractAddress2 = linkData[linktype.toString()].gcaddress;
      var contractAddress3 = cz.address;
      var contractAddress4 = cz.address;
      var contractAddress5 = linkData[linktype.toString()].jgaddress;
      var hyitem1 = await new web3.eth.Contract(jsonrc1, contractAddress1)
      var hyitem2 = await new web3.eth.Contract(jsonrc2, contractAddress2)
      var hyitem3 = await new web3.eth.Contract(jsonrc3, contractAddress3)
      var hyitem4 = await new web3.eth.Contract(jsonrc4, contractAddress4)
      var hyitem5 = await new web3.eth.Contract(jsonrc5, contractAddress5)
      var hyitem1s = await new web31.eth.Contract(jsonrc1, contractAddress1)
      var hyitem2s = await new web31.eth.Contract(jsonrc2, contractAddress2)
      var hyitem3s = await new web31.eth.Contract(jsonrc3, contractAddress3)
      var hyitem4s = await new web31.eth.Contract(jsonrc4, contractAddress4)
      var hyitem5s = await new web31.eth.Contract(jsonrc5, contractAddress5)
      hy1w = hyitem1
      hy2w = hyitem2
      hy3w = hyitem3
      hy4w = hyitem4
      hy5w = hyitem5
      sethy1(hyitem1)
      sethy2(hyitem2)
      sethy3(hyitem3)
      sethy4(hyitem4)
      sethy5(hyitem5)
      sethy1send(hyitem1s)
      sethy2send(hyitem2s)
      sethy3send(hyitem3s)
      sethy4send(hyitem4s)
      sethy5send(hyitem5s)
      getendlist()
      getListern()
      message.destroy('czlodaingg')
    }
  })



  useEffect(() => {
    if (hy1) {
      if (user === false || user === null || user === 'null') {
        setbalance(0)
      } else {
        gogeban = true
        gogetallowance = true
      }
    }
  }, [hy1])


  useEffect(() => {

    if (hy2) {

    }
  }, [hy2])


  useEffect(() => {
    if (hy3) {
      getVaultConfig()
    }
  }, [hy3])


  const getVaultConfig = (() => {
    hy3.methods.getVaultConfig().call(function (error, result) {
      setcollateralToken(result.collateralToken)
      setmaxNumOfOrdersPerUser(result.maxNumOfOrdersPerUser)
      setmaxOrderAmountFraction(result.maxOrderAmountFraction)
      setminExecutionFee(result.minExecutionFee)
      setminOrderAmount(Number(result.minOrderAmount) / (10 ** 18))
      setpriceOracle(result.priceOracle)
      settreasuryFee((Number(result.treasuryFee) / 100).toString())
      setwinningRate((Number(result.winningRate) / 100).toString())
      setTimeout(() => {
        gogetVaultState = true
      }, 500)

    })
  })



  const getVaultState = (() => {
    hy3w.methods.getVaultState().call(function (error, result) {

      if (result !== gogetVaultStateRes) {
        gogetVaultState = false
        gogetVaultStateRes = result
      }


      setmaxPoolAmount(Number(Number(result.maxPoolAmount) / (10 ** 18)).toFixed(2))
      setpoolAmount(Number(result.poolAmount) / (10 ** 18))

    })
  })


  useEffect(() => {
    if (maxPoolAmount && maxOrderAmountFraction) {
      let m = Number(maxPoolAmount) * maxOrderAmountFraction / 10000
      setmaxPoolAmountuser(m)
    }
  }, [maxPoolAmount, maxOrderAmountFraction])


  useEffect(() => {
    if (hy4) {
      if (timer) {
        clearInterval(timer)
      }
      timer = setInterval(() => {
        getorDerListStep()
        gogetLp = true
      }, 1000)

      setisgerawd(false)
      gogetZyinfo = true
      goGetOrderList = true
      gogettotalSupply = true
      goGetJlReward = true
    }
  }, [hy4])

  const gettotalSupply = (() => {
    hy4w.methods.totalSupply().call(function (error, result) {
      if (result !== gogettotalSupplyRes) {
        gogettotalSupply = false
        gogettotalSupplyRes = result
      }
      settotalSupply(Number(Number(result) / (10 ** 18)).toFixed(2))
    })
  })


  const getisrawd = (async () => {
    if (user === false || user === null || user === 'null') {
      return false
    }
    let res = await hy4w.methods.isReward().call()
    if (getJlRewardRes !== res) {
      getJlRewardRes = res
      goGetJlReward = false
    }
    if (res) {
      let res1 = await hy4.methods.getUserFee(user).call()
      setjlamount(`${Number(Number(res1) / (10 ** 18)).toFixed(2)}`)
      setisgerawd(true)
    }
  })



  const getRewards = (async () => {
    let ntime = dayjs().unix()

    if (ntime - TQTime <= 2) {
      return false
    } else {
      TQTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    if (Number(jlamount) <= 0) {
      message.error({ content: newlocal['error']['p26'][language], key: 'trangerror' })
      return false
    }

    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });


    let csflag = false
    let isres = false


    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: cz.address,
        abi: jsonrc4,
        functionName: 'getRewards',
        account: user
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: cz.address,
            abi: jsonrc4,
            functionName: 'getRewards',
            account: user,
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 15, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const getLp = (() => {
    if (user === false || user === null || user === 'null') {
      return false
    }
    hy4w.methods.balanceOf(user).call(function (error, result) {

      if (result !== gogetLpRes) {
        gogetLp = false
        gogetLpRes = result
      }


      if (result === '0') {
        setlp('0.00')
      } else {
        setlp(`${Number(Number(result) / (10 ** 18)).toFixed(2)}`)
      }
    })
  })

  const getZyinfo = (async () => {
    if (user === false || user === null || user === 'null') {
      return false
    }
    let info = await hy4w.methods.getUserLiquidity(user).call()

    if (info !== gogetZyinfoRes) {
      gogetZyinfo = false
      gogetZyinfoRes = info
    }

    setliquidityTime(info.liquidityTime)
    if (info.tokenAmount === "0") {
      settokenAmount('0.00')
    } else {
      settokenAmount(Number(info.tokenAmount) / (10 ** 18).toFixed(2))
    }

    if (info.profit === "0") {
      setprofit('0.00')
    } else {
      setprofit(Number(info.profit) / (10 ** 18).toFixed(2))
    }
  })

  const getorDerListStep = (() => {
    if (user === false || user === null || user === 'null') {
      return false
    }
    let list = [...newwlist, ...oldlist]

    let all = 0
    let lrall = 0
    let newlist = []
    let isgetorder = false
    for (let i in list) {
      let newtime = dayjs().diff(dayjs(list[i].expiryAt * 1000), 'seconds')
      let creatTime = list[i].createAt * 1000
      let newitem = list[i]
      if (newtime > 0 && newtime % 60 === 1 && (newitem.btntype === 1 || newitem.btntype === 4) && !isgetorder) {
        isgetorder = true
        goGetOrderList = true
      }
      let nowPrice = Number(createp.price)
      let creatPrice = Number(newitem.creatPrice)
      let isWin = 1
      let lr = 0
      if (nowPrice === creatPrice) {
        isWin = 3
      } else if (nowPrice > creatPrice && newitem.isLong) {
        isWin = 1
      } else if (nowPrice < creatPrice && !newitem.isLong) {
        isWin = 1
      } else {
        isWin = 2
      }
      newitem.isWin = isWin
      let min = (parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))
      let sec = (parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))
      let xsTime = `${min}:${sec}`
      newitem.xsTime = xsTime




      if (newitem.isNewXn) {
        // newitem.xsTime = newlocal['main']['p65'][language]
        newitem.btntype = 1
      }

      if (newitem.qs) {
        newitem.xsTime = newlocal['main']['p66'][language]
      }



      if (newtime >= 0 && !newitem.isNewXn) {
        newitem.xsTime = newlocal['main']['p66'][language]
      }
      if (newitem.btntype === 2) {
        newitem.xsTime = newlocal['main']['p66'][language]
      }
      if (newitem.btntype === 3) {
        newitem.xsTime = newlocal['main']['p661'][language]
      }
      if (newitem.btntype === 4) {
        newitem.xsTime = newlocal['main']['p661'][language]
      }

      if (newtime >= 0 && newitem.btntype === 1 && !newitem.isNewXn) {
        newitem.btntype = 4
      }

      if (newitem.btntype === 4 && newitem.endPrice) {
        let ep = Number(newitem.endPrice)
        if (ep === creatPrice) {
          isWin = 3
        } else if (ep > creatPrice && newitem.isLong) {
          isWin = 1
        } else if (ep < creatPrice && !newitem.isLong) {
          isWin = 1
        } else {
          isWin = 2
        }
      }

      newitem.isWin = isWin




      if (isWin === 1) {
        lr = Number(newitem.amount / 10 ** 18) * Number(newitem.winningRate) / 100
      } else if (isWin === 2) {
        lr = 0 - Number(newitem.amount / 10 ** 18)
      } else if (isWin === 3) {
        lr = 0
      }




      newitem.lr = lr
      newitem.openPrice = creatPrice
      newitem.createTime = newitem.createAt * 1000
      newitem.closeTimes = newitem.expiryAt * 1000
      if (newitem.isLong) {
        newitem.orderType = 'UP'
      } else {
        newitem.orderType = 'DOWN'
      }
      newlist.push(newitem)
      if (newitem.btntype === 1 && newtime < 0) {
        lrall += lr
        all += Number(newitem.amount / 10 ** 18)
      }
    }
    setalllr(lrall)
    setalltz(all.toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
    setorderlist2(newlist)
  })









  useEffect(() => {

    if (oldtype !== linktype) {
      oldtype = linktype
      reloadPage()
    }
  }, [linktype])


  const reloadPage = (() => {
    setTimeout(() => {
      getType(false)
      getTransTime()
      setloadam1(1)
      setloadam2(1)
    }, 1000)
  })



  const closeChangeBox2 = (() => {
    setShowTimeChangeBox(false)
  })





  const goPay = (() => {
    emitter.emit("callMe", "Hello")
  })




  const setShowPrice = ((value) => {
    setprice(value)
  })





  const checkprice = (() => {
    setshowpriceErrormsg2(false)
    setshowpriceErrormsg(false)
    if (price > balance || balance === 0) {
      setshowpriceErrormsg2(true)
    } else if (maxPoolAmount <= 0) {
      message.error({ content: newlocal['main']['p472'][language], key: 'trangerror' })
    } else if (price < Number(minOrderAmount)) {
      setpriceErrormsg(newlocal['main']['p47'][language] + ' ' + minOrderAmount + ' ' + (cz ? cz.symbol : ''))
      setshowpriceErrormsg(true)
    } else if (price > Number(maxPoolAmountuser)) {
      setpriceErrormsg(newlocal['main']['p471'][language] + ' ' + maxPoolAmountuser + ' ' + (cz ? cz.symbol : ''))
      setshowpriceErrormsg(true)
    } else if (price > sqAmount || sqAmount === 0) {
      setpriceErrormsg(newlocal['main']['p48'][language])
      setApproveBox(true)
      setshowpriceErrormsg(true)
    } else {
      setpriceErrormsg('')
      setshowpriceErrormsg2(false)
      setshowpriceErrormsg(false)
    }
    if (price === '') {
      setprice('0.00')
    }
  })




  const goOrder = ((type) => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    if (balance === 0) {
      setshowpriceErrormsg2(true)
    } else if (sqAmount !== 0 && (!isSq || sqAmount >= minOrderAmount || sqAmount >= price)) {
      if (oldlist.length >= Number(maxNumOfOrdersPerUser)) {
        setpriceErrormsg(newlocal['main']['p58'][language] + maxNumOfOrdersPerUser)
        setshowpriceErrormsg(true)
      } else {
        goTrans(type)

      }
    } else {
      setApproveBox(true)
    }
  })


  const goOrder2 = ((type) => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (balance === 0) {
      setshowpriceErrormsg2(true)
    } else if (sqAmount !== 0 && (!isSq || sqAmount >= minOrderAmount || sqAmount >= price)) {
      checkprice()
    } else {
      setApproveBox(true)
    }
  })

  const geban = (() => {

    if (userw === false || userw === null || userw === 'null') {
      return false
    }
    try {
      hy1w.methods.balanceOf(userw).call(function (error, result) {
        if (error) {
          setloadam1(3)
          return false
        }
        if (result !== gogebanRes) {
          gogeban = false
          gogebanRes = result
        }
        setloadam1(2)
        setbalance(Number(result) / (10 ** 18))
      })
    } catch (e) {
      setloadam1(3)
    }

  })


  const goTrans = (async (type) => {
    let ntime = dayjs().unix()
    if (ntime - goTransTime <= 2) {
      return false
    } else {
      goTransTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    setjyloading(true)
    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let am = price * (10 ** 18)
    am = am.toLocaleString();
    am = am.replace(/[,]/g, '');
    let times = 0
    switch (time) {
      case 0: times = 60; break;
      case 1: times = 300; break;
      case 2: times = 600; break;
      case 3: times = 900; break;
      case 4: times = 1800; break;
      case 5: times = 3600; break;
    }
    let newobj = {
      amount: am,
      createAt: dayjs().unix().toString(),
      executionFee: '10000000000000',
      expiryAt: dayjs().add(times, 'second').unix().toString(),
      isLong: type === 1 ? false : true,
      marketId: "0",
      winningRate: cz.winningRate,
      isNewXn: true
    }
    let csflag = false
    let isres = false


    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: cz.address,
        abi: jsonrc4,
        functionName: 'openPosition',
        account: user,
        args: [am, time, 0, type === 1 ? false : true],
        value: `${minExecutionFee}`.toString('Hex')
      })
    } catch (e) {
      message.destroy('trange')
      setjyloading(false)
      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: cz.address,
            abi: jsonrc4,
            functionName: 'openPosition',
            account: user,
            args: [am, time, 0, type === 1 ? false : true],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
            value: `${minExecutionFee}`.toString('Hex')
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          setjyloading(false)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          xnOrder(newobj, hash)
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, type, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          setjyloading(false)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          setjyloading(false)
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })

  const getlistenJy = (async (result, type, errortime) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var receipt = await web3.eth.getTransactionReceipt(result)
    let nowtime = Number(dayjs().unix())
    console.log('listen-hash', result, receipt)
    if (nowtime > errortime) {
      setjyloading(false)
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    } else if (receipt === null) {
      getlistenJy(result, type, errortime)
    } else if (receipt.status === true) {
      message.destroy('trange')
      if (type === 1) {
        setjyloading(false)
        message.success({ content: <div>{newlocal['main']['p71'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)} >{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        getOrderListIsCheck = true
        getOrderListHash = result
        goGetOrderList = true
        gogetallowance = true
        gogeban = true
      }
      if (type === 2) {
        setjyloading(false)
        message.success({ content: <div>{newlocal['main']['p72'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        getOrderListIsCheck = true
        getOrderListHash = result
        goGetOrderList = true
        gogetallowance = true
        gogeban = true
      }
      if (type === 3) {
        message.success({ content: <div>{newlocal['main']['p74'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        goGetOrderList = true
      }
      if (type === 4) {
        message.success({ content: <div>{newlocal['main']['p75'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        goGetOrderList = true
      }
      if (type === 5) {
        message.success({ content: <div>{newlocal['main']['p76'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        gogetLp = true
        gogeban = true
        gogetVaultState = true
        gogetZyinfo = true
        gogettotalSupply = true
      }
      if (type === 7) {
        message.success({ content: <div>{newlocal['main']['p77'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        gogetLp = true
        gogeban = true
        gogetVaultState = true
        gogetZyinfo = true
        gogettotalSupply = true
      }
      if (type === 6) {
        message.success({ content: <div>{newlocal['main']['p78'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        gogetallowance = true
      }
      if (type === 10) {
        message.success({ content: <div>{newlocal['main']['p80'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow2(false)
        let str = ''
        switch (language) {
          case 'zhcn': str = '正在加載...'; break;
          case 'en': str = 'Loading...'; break;
          default: break;
        }
        message.loading({ content: str, key: 'czlodaingg', duration: 0 });
        getTypeLengthNew()
      }
      if (type === 15) {
        message.success({ content: <div>{newlocal['main']['p104'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        goGetJlReward = true
      }
    } else {
      setjyloading(false)
      checknewwlist(result)
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }
  })

  const xnOrder = ((item, hash) => {
    let newtime = dayjs().diff(dayjs(item.expiryAt * 1000), 'seconds')
    let creatTime = item.createAt * 1000
    let newitem = {
      amount: item.amount,
      createAt: item.createAt,
      executionFee: item.executionFee,
      expiryAt: item.expiryAt,
      isLong: item.isLong,
      marketId: item.marketId,
      winningRate: item.winningRate,
      isNewXn: item.isNewXn,
      hash: hash
    }

    let nowPrice = createp.price
    let creatPrice = 0
    let isWin = false
    let lr = 0
    newitem.creatPrice = createp.price
    if (nowPrice > creatPrice && newitem.isLong) {
      isWin = true
    } else if (nowPrice < creatPrice && !newitem.isLong) {
      isWin = true
    } else {
      isWin = false
    }
    newitem.isWin = isWin
    let min = (parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))
    let sec = (parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))
    let xsTime = `${min}:${sec}`
    newitem.xsTime = xsTime
    if (newitem.isNewXn) {
      newitem.xsTime = newlocal['main']['p65'][language]
    }
    if (isWin) {
      lr = Number(newitem.amount / 10 ** 18) * Number(newitem.winningRate) / 100
    } else {
      lr = 0 - Number(newitem.amount / 10 ** 18)
    }
    newitem.lr = lr
    newitem.openPrice = creatPrice
    newitem.createTime = newitem.createAt * 1000
    newitem.closeTimes = newitem.expiryAt * 1000
    if (newitem.isLong) {
      newitem.orderType = 'UP'
    } else {
      newitem.orderType = 'DOWN'
    }

    newwlist = [newitem, ...newwlist]
  })


  const checknewwlist = ((hash) => {
    let n = []
    for (let i in newwlist) {
      if (newwlist[i].hash === hash) {

      } else {
        n = [...n, newwlist[i]]
      }
    }
    newwlist = [...n]
  })

  const approve = (async () => {
    let ntime = dayjs().unix()
    if (ntime - approveTime <= 2) {
      return false
    } else {
      approveTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    let csflag = false
    let isres = false

    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
    let am = balance * (10 ** 18)
    am = am.toLocaleString();
    am = am.replace(/[,]/g, '');

    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: cz.tokenAddress,
        abi: jsonrc1,
        functionName: 'approve',
        account: user,
        args: [cz.address, am]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: cz.tokenAddress,
            abi: jsonrc1,
            functionName: 'approve',
            account: user,
            args: [cz.address, am],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          setApproveBox(false)
          let str = ''
          switch (language) {
            case 'zhcn': str = '正在加載...'; break;
            case 'en': str = 'Loading...'; break;
            default: break;
          }
          message.loading({ content: str, keymsg });
          navigate(`/?pool=${cz.address}&type=${'1'}`)
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 6, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const getallowance = (() => {
    if (userw === false || userw === null || userw === 'null') {
      return false
    }
    try {
      hy1w.methods.allowance(userw, nowcz.address).call(function (error, result) {
        message.destroy(keymsg)
        if (result !== gogetallowanceRes) {
          gogetallowance = false
          gogetallowanceRes = result
        }

        if (error) {
          setloadam2(3)
          return false
        }

        setloadam2(2)
        if (result === "0") {
          setisSq(false)
          setsqAmount(0)
        } else {
          setisSq(true)
          setsqAmount(Number(result) / (10 ** 18))
        }
      })
    } catch (e) {
      setloadam2(3)
    }
  })

  const getListern = (async () => {
    if (user === false || user === null || user === 'null') {
      return false
    }
  })

  const getorDerList = (async () => {
    let isCheck = getOrderListIsCheck
    let hash = getOrderListHash


    if (userw === false || userw === null || userw === 'null') {
      return false
    }

    goGetJlReward = true
    hy4w.methods.getUserOrders(userw).call(async function (error, result) {



      if (error) {

      } else {
        let list = result

        if (oldOrderList !== list) {
          goGetOrderList = false
          getOrderListIsCheck = false
          getOrderListHash = false
          oldOrderList = list
        }
        let all = 0
        let lrall = 0
        let newlist = []
        let newlist1 = []
        for (let i in list) {
          let newtime = dayjs().diff(dayjs(list[i].expiryAt * 1000), 'seconds')
          let creatTime = list[i].createAt * 1000
          let t1 = list[i].createAt
          let t2 = list[i].expiryAt
          let winningRatenew = (Number(list[i].winningRate) / 100).toString()

          let newitem = {
            amount: list[i].amount,
            createAt: list[i].createAt,
            executionFee: list[i].executionFee,
            expiryAt: list[i].expiryAt,
            isLong: list[i].isLong,
            marketId: list[i].marketId,
            winningRate: winningRatenew,
            ysobj: list[i],
          }
          let newitem1 = {
            amount: list[i].amount,
            createAt: list[i].createAt,
            executionFee: list[i].executionFee,
            expiryAt: list[i].expiryAt,
            isLong: list[i].isLong,
            marketId: list[i].marketId,
            winningRate: winningRatenew,
            ysobj: list[i]
          }
          if (newtime >= 0) {
            newitem.qs = true
            newitem1.qs = true
            let promise2 = await axios.get(`data/price?time=${list[i].expiryAt}`)
            newitem.endPrice = Number(promise2.content / 10 ** 8).toFixed(2)
          } else {
            newitem.endPrice = false
          }
          let nowPrice = createp.price
          let creatPrice = 0
          let isWin = 1
          let lr = 0

          let promise = await axios.get(`data/price?time=${list[i].createAt}`)
          newitem.creatPrice = Number(promise.content / 10 ** 8).toFixed(2)


          if (nowPrice === creatPrice) {
            isWin = 3
          } else if (nowPrice > creatPrice && newitem.isLong) {
            isWin = 1
          } else if (nowPrice < creatPrice && !newitem.isLong) {
            isWin = 1
          } else {
            isWin = 2
          }
          newitem.isWin = isWin
          let min = (parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) / 60))
          let sec = (parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60)) < 10) ? `0${parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))}` : parseInt(Math.abs(Math.abs(parseInt(newtime)) % 60))
          let xsTime = `${min}:${sec}`
          newitem.xsTime = xsTime

          if (isWin === 1) {
            lr = Number(newitem.amount / 10 ** 18) * Number(newitem.winningRate) / 100
          } else if (isWin === 2) {
            lr = 0 - Number(newitem.amount / 10 ** 18)
          } else if (isWin === 3) {
            lr = 0
          }
          newitem.btntype = 1
          if (newitem.qs) {
            newitem.xsTime = newlocal['main']['p66'][language]
            if (newtime >= 180) {
              let res1 = await hy5.methods.getTokenPrice(0, t1).call()
              let res2 = await hy5.methods.getTokenPrice(0, t2).call()
              if (Number(res1) === 0 || Number(res2) === 0) {
                newitem.btntype = 3
              } else {
                newitem.btntype = 2
              }
            } else {
              newitem.btntype = 4
            }
          }
          newitem.lr = lr
          newitem.openPrice = creatPrice
          newitem.createTime = newitem.createAt * 1000
          newitem.closeTimes = newitem.expiryAt * 1000
          newitem1.openPrice = creatPrice
          newitem1.createTime = newitem.createAt * 1000
          newitem1.closeTimes = newitem.expiryAt * 1000
          if (newitem.isLong) {
            newitem.orderType = 'UP'
            newitem1.orderType = 'UP'
          } else {
            newitem.orderType = 'DOWN'
            newitem1.orderType = 'DOWN'
          }
          newlist.push(newitem)
          newlist1.push(newitem1)
        }
        newlist.sort(function (a, b) {
          return b.createAt - a.createAt;//	降序，升序则反之
        });
        newlist1.sort(function (a, b) {
          return b.createAt - a.createAt;//	降序，升序则反之
        });
        setorderlist(newlist)
        setorderlist1(newlist1)
        oldlist = newlist
        if (isCheck) {
          checknewwlist(hash)
        }
      }
    })

  })


  const ZY = (async () => {
    let ntime = dayjs().unix()
    if (ntime - ZYTime <= 2) {
      return false
    } else {
      ZYTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    if (balance === 0) {
      setshowpriceErrormsg2(true)
      return false
    }
    if (balance < Number(zy1)) {
      setshowpriceErrormsg3(true)
      return false
    }
    if (Number(zy1) <= 0) {
      return false
    }
    if (Number(zy1) > Number(sqAmount)) {
      setApproveBox(true)
      return false
    }

    let csflag = false
    let isres = false





    if (sqAmount !== 0 && (!isSq || sqAmount >= minOrderAmount)) {
      message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
      let am = zy1 * (10 ** 18)
      am = am.toLocaleString();
      am = am.replace(/[,]/g, '');





      let gaslimit = 1000000

      try {
        gaslimit = await publicClient.estimateContractGas({
          address: cz.address,
          abi: jsonrc4,
          functionName: 'addLiquidity',
          account: user,
          args: [am]
        })
      } catch (e) {
        message.destroy('trange')

        goMessor(e)
        return false
      }
      const gasPrice = await publicClient.getGasPrice()
      const nonce = await publicClient.getTransactionCount({ address: user });




      await Promise.race([
        setTimeout(async () => {
          let srequest = false
          try {
            const { request } = await publicClient.simulateContract({
              address: cz.address,
              abi: jsonrc4,
              functionName: 'addLiquidity',
              account: user,
              args: [am],
              gas: gaslimit,
              gasPrice: gasPrice,
              nonce: nonce,
            })
            isres = true
            srequest = request
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
            return false
          }
          try {
            const hash = await walletClient.writeContract(srequest)
            isres = true
            setshowmode3(false)
            let errortime = Number(dayjs().add(20, 'second').unix())
            getTypeLength(hash, 7, errortime)
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
          }
        }, 500),
        new Promise(function (resolve, reject) {
          setTimeout(function () {
            message.destroy('trange')
            if (!csflag && !isres) {
              message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
              csflag = true
            }
          }, 30000);
        })
      ]);
    } else {
      setApproveBox(true)
    }

  })

  const SH = (async () => {
    let ntime = dayjs().unix()
    if (ntime - SHTime <= 2) {
      return false
    } else {
      SHTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let csflag = false
    let isres = false

    let am = zy2 * (10 ** 18)
    am = am.toLocaleString();
    am = am.replace(/[,]/g, '');

    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: cz.address,
        abi: jsonrc4,
        functionName: 'removeLiquidity',
        account: user,
        args: [am]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: cz.address,
            abi: jsonrc4,
            functionName: 'removeLiquidity',
            account: user,
            args: [am],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {

          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          setshowmode4(false)
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 5, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);


  })


  const checkfocusPrice = (() => {
    if (price === '0.00' || price === 0 || price === '0') {
      setprice('')
    }
  })

  const goPaydB = (() => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    document.getElementsByClassName("AppH5")[0].scrollTop = 0
    navigate('/airdrop')
  })


  const gojypage = ((link) => {
    window.open(`${linkData[linktype.toString()].txklink}${link}`)

  })

  const refundPosition = (async (item) => {
    let ntime = dayjs().unix()
    if (ntime - refundPositionTime <= 2) {
      return false
    } else {
      refundPositionTime = ntime
    }

    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }


    let list = await hy4.methods.getUserOrders(user).call()
    let flag = false
    for (let m in list) {
      if (item.ysobj.amount === list[m].amount && item.ysobj.createAt === list[m].createAt && item.ysobj.executionFee === list[m].executionFee && item.ysobj.expiryAt === list[m].expiryAt && item.ysobj.isLong === list[m].isLong && item.ysobj.marketId === list[m].marketId && item.ysobj.winningRate === list[m].winningRate) {
        flag = true
      }
    }

    if (!flag) {
      goGetOrderList = true
      return false
    }



    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let csflag = false
    let isres = false


    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: cz.address,
        abi: jsonrc4,
        functionName: 'refundPosition',
        account: user,
        args: [user, item.createAt]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }

    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: cz.address,
            abi: jsonrc4,
            functionName: 'refundPosition',
            account: user,
            args: [user, item.createAt],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {

          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 4, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const closePosition = (async (item) => {
    let ntime = dayjs().unix()
    if (ntime - closePositionTime <= 2) {
      return false
    } else {
      closePositionTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }



    let list = await hy4.methods.getUserOrders(user).call()
    let flag = false
    for (let m in list) {
      if (item.ysobj.amount === list[m].amount && item.ysobj.createAt === list[m].createAt && item.ysobj.executionFee === list[m].executionFee && item.ysobj.expiryAt === list[m].expiryAt && item.ysobj.isLong === list[m].isLong && item.ysobj.marketId === list[m].marketId && item.ysobj.winningRate === list[m].winningRate) {
        flag = true
      }
    }

    if (!flag) {
      goGetOrderList = true
      return false
    }



    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });


    let csflag = false
    let isres = false


    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: cz.address,
        abi: jsonrc4,
        functionName: 'closePosition',
        account: user,
        args: [user, item.createAt, user]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });


    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: cz.address,
            abi: jsonrc4,
            functionName: 'closePosition',
            account: user,
            args: [user, item.createAt, user],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {

          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 3, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const checkChainId = (async () => {
    let chainId = `0x${chain.id.toString(16)}`

    if (chainId !== linkData[linktype.toString()].lid) {
      emitter.emit("checkcid", "Hello")
      return false
    } else {
      return true
    }
  })



  const checkmode3 = (async (bool) => {

    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }


    if (sqAmount !== 0 && (!isSq || sqAmount >= minOrderAmount)) {

    } else {
      setApproveBox(true)
      return false
    }

    setshowmode3(bool)
  })

  const checkmode4 = (async (bool) => {

    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    setshowmode4(bool)
  })

  const cx = (async (cx) => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].gcaddress;
    var hyitem = await new web3.eth.Contract(jsonrc2, contractAddress)
    var hyitem2 = await new web3.eth.Contract(jsonrc6, cx)

    var res = await hyitem.methods.queryPoolAddress(cx).call()

    var res1 = await hyitem.methods.hasBeenDeployed(res).call()

    try {
      var res3 = await hyitem2.methods.symbol().call()
      message.destroy('trange')
      settname(res3)
      setaddress(res)
      settaddress(cx)
      setsicz(res1)
    } catch (error) {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }

  })

  const creat = (async () => {
    let ntime = dayjs().unix()
    if (ntime - czTime <= 2) {
      return false
    } else {
      czTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    if (cvaleu1 === '') {
      message.error({ content: newlocal['error']['p23'][language], key: 'trangerror', duration: 3 })
      return false
    } else {
      const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
      try {
        let code = web3.eth.getCode(cvaleu1)
        if (code === '0x') {
          message.error({ content: newlocal['error']['p27'][language], key: 'trangerror', duration: 3 })
          return false
        } else {

        }
      } catch {
        message.error({ content: newlocal['error']['p27'][language], key: 'trangerror', duration: 3 })
        return false
      }
    }



    if (cvaleu4 === '') {
      message.error({ content: newlocal['error']['p24'][language], key: 'trangerror', duration: 3 })
      return false
    }
    if (Number(cvaleu4) > 10000) {
      message.error({ content: newlocal['error']['p25'][language], key: 'trangerror', duration: 3 })
      return false
    }
    let csflag = false
    let isres = false

    var contractAddress = linkData[linktype.toString()].gcaddress;
    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });



    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc2,
        functionName: 'createPool',
        account: user,
        args: [cvaleu1, cvaleu2, cvaleu3, cvaleu4]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }


    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });



    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc2,
            functionName: 'createPool',
            account: user,
            args: [cvaleu1, cvaleu2, cvaleu3, cvaleu4],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {

          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 10, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const getTypeLength = (async (result, index, errortime) => {
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      userlistlength = resp.content.length
      getlistenJy(result, index, errortime)
    })
  })



  const getTypeLengthNew = (async () => {
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      let l1 = resp.content.length
      if (l1 > userlistlength) {
        message.destroy('czlodaingg')
        getType()
        window.location.href = '/?pool=' + resp.content[0].address
      } else {
        getTypeLengthNew()
      }
    })
  })




  const go = (async (ad, type) => {
    for (let i in poolList) {
      if (poolList[i].address.toLowerCase() === ad.toLowerCase()) {
        setcz(poolList[i])
        setloadam1(1)
        setloadam2(1)
        nowcz = poolList[i]
        setshow1(false)
        setshow2(false)
        break;
      }
    }
  })

  const goReash = (() => {
    window.location.reload()
  })



  const goMessor = ((error) => {
    let msg = error.message
    console.error(msg)
    if (msg.indexOf('insufficient allowance') >= 0) {
      message.error({ content: newlocal['error']['p1'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('transfer amount exceeds balance') >= 0) {
      message.error({ content: newlocal['error']['p2'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('withdraw amount too much!') >= 0) {
      message.error({ content: newlocal['error']['p3'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('marketId not exist') >= 0) {
      message.error({ content: newlocal['error']['p4'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('intervalId not exist') >= 0) {
      message.error({ content: newlocal['error']['p5'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('total Order Amount is too large') >= 0) {
      message.error({ content: newlocal['error']['p6'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too large') >= 0) {
      message.error({ content: newlocal['error']['p7'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too small') >= 0) {
      message.error({ content: newlocal['error']['p8'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('too many remaining orders') >= 0) {
      message.error({ content: newlocal['error']['p9'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('have the same order') >= 0) {
      message.error({ content: newlocal['error']['p10'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('execution fee is too low') >= 0) {
      message.error({ content: newlocal['error']['p11'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p12'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is not ready') >= 0) {
      message.error({ content: newlocal['error']['p13'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p14'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order running') >= 0) {
      message.error({ content: newlocal['error']['p15'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is ready') >= 0) {
      message.error({ content: newlocal['error']['p16'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('reward amount is zero') >= 0) {
      message.error({ content: newlocal['error']['p17'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('getRewards approve error') >= 0) {
      message.error({ content: newlocal['error']['p18'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('ZERO_ADDRESS!') >= 0) {
      message.error({ content: newlocal['error']['p19'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('not support') >= 0) {
      message.error({ content: newlocal['error']['p20'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('factoryConfig is not initialize') >= 0) {
      message.error({ content: newlocal['error']['p21'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('CREATE2_FAILED') >= 0) {
      message.error({ content: newlocal['error']['p22'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('The total cost (gas * gas fee + value) of executing this transa') >= 0) {
      message.error({ content: newlocal['error']['p29'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('User denied transaction signature') >= 0) {
      message.error({ content: newlocal['main']['p70'][language], key: 'trangerrorss', duration: 3 })
    } else {
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
    }
  })

  console.log(orderItem)

  return (
    <div className="mainh5" onClick={() => goPay()}>
      <div className='mainPanel'>
        <div className='mainbox'>
          <div className='selectbox'>
            <div className='assetsChangeBox' onClick={() => setshowmode1(true)}>
              <img className='ico' src={btc} />
              <div className='name'>{'BTC/USDT'}</div>
              <img className='btn' src={newselect} />
            </div>
          </div>
          <div className='kLineBox'  >
            {/* <iframe className='kllinebox' frameflag={frameflag1} style={{ border: 'none' }} src={`https://trading.bbz.com/?token=${user}&lang=${language === 'zhcn' ? 'zh_TW' : language}&addres=${cz ? cz.address : 'null'}&linktype=${linktype}test&stype=phone`}></iframe> */}
            <div className='charts'>
              <TVChartContainer token={user} lang={language === 'zhcn' ? 'zh_TW' : language} poolAddress={cz} linktype={`${linktype}test`} stype={'phone'}/>
            </div>
          </div>
        </div>
      </div>



      <div className='rightPanelbox'>
        <div className='rightPanel'>
          <div className='tabbox'>
            <div className={tab2 === 1 ? 'item active' : 'item'} onClick={() => setTab2(1)}>
              {
                tab2 === 1 ? <img className='ico' src={mianmenu12} /> : <img className='ico' src={mianmenu11} />
              }

              <div className='text' >{newlocal['main']['p16'][language]}</div>
            </div>
            <div className={tab2 === 2 ? 'item active' : 'item'} onClick={() => setTab2(2)}>
              {
                tab2 === 2 ? <img className='ico' src={mianmenu32} /> : <img className='ico' src={mianmenu31} />
              }
              <div className='text' >{newlocal['main']['p17'][language]}</div>
            </div>
          </div>
          {
            tab2 === 1 && <div>
              <div className='pooltitle'>{newlocal['main']['p55'][language]}<div onClick={() => setshow2(true)} className='rb'><img className='rbi' src={addpool}></img>{newlocal['main']['p100'][language]}</div></div>
              <div className='poolCheckBox2' onClick={() => { setshowmode2(true) }} >
                <Avatar className='poolico' size={36} style={{ color: '#ffffff', backgroundColor: Avaterlist[cz.colorindex] }} onClick={() => setshowmode2(true)}>{cz ? cz.name[0] : ''}</Avatar>
                <div className='poolinfo' onClick={() => setshowmode2(true)}>
                  <div className='top'>{(cz ? cz.name : '')} pool</div>
                </div>
                <div className={cz.issc ? 'selectwz r44' : 'selectwz r108'}  >{cz ? cz.winningRate : 'loding...'}%</div>
                <img className={cz.issc ? 'selectico r13' : 'selectico r74'} src={newselect} onClick={() => setshowmode2(true)} />
                {
                  !cz.issc && <div className='starbtn'>
                    <img className='selectstar' src={cz.issc ? starb : starh} onClick={(e) => { goshouc(cz, cz.issc ? 'NO' : 'YES', e) }} />
                  </div>
                }
              </div>
              <div className='nummberbox'>
                <div className='top'>
                  <div className='tp1'>{newlocal['main']['p20'][language]}</div>
                  <pre className='tp2'>
                    <pre className='tp3'>
                      {newlocal['main']['p21'][language]}: {(user === false || user === null || user === 'null') ? '0.00' : loadam1 === 1 ? <img className='loadico' src={loadingz}></img> : loadam1 === 3 ? newlocal['main']['p107'][language] : balance ? Number(balance).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                    </pre>
                    <pre className='tp3'>
                      {newlocal['main']['p212'][language]}: {(user === false || user === null || user === 'null') ? '0.00' : loadam2 === 1 ? <img className='loadico' src={loadingz}></img> : loadam2 === 3 ? newlocal['main']['p107'][language] : sqAmount ? Number(sqAmount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                    </pre>
                  </pre>
                </div>
              </div>
              <div className='nummberbox1'>
                <div className='bottom'>
                  <input className='bt1' value={price} onFocus={() => checkfocusPrice()} onBlur={() => checkprice()} onChange={(e) => { setShowPrice(e.currentTarget.value.replace(/^(0+)|[^\d]+/g, '')) }} />
                  <div className='bp2'>{(cz ? cz.symbol : '')}</div>
                </div>
              </div>
              {
                showpriceErrormsg && <div className='priceErrBox'>
                  <img className='priceErrIco' src={errico} />
                  <div className='priceErrText '>{priceErrormsg}</div>
                </div>
              }

              {
                showpriceErrormsg2 && <div className='priceErrBox' >
                  <img className='priceErrIco' src={errico} />
                  <div className='priceErrText '>{`${newlocal['main']['p59'][language]} ${cz.symbol}`}<span onClick={() => goPaydB()} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#9096AD' }}>{`${newlocal['main']['p60'][language]} ${cz.symbol}`}</span></div>
                </div>
              }


              <div className='nummberbox'>
                <div className='top'>
                  <div className='tp1'> {newlocal['main']['p49'][language]}</div>
                </div>
              </div>
              <div className='timebox'>
                {
                  timelist && timelist.map((item, index) => {
                    return <div className={time === index ? 'changeItem  changeItemactive' : 'changeItem'} onClick={() => { settime(index); closeChangeBox2() }}>{`${item.value}${newlocal['main'][item.type][language]}`}</div>
                  })
                }
              </div>





              <div className='pooltextbox'>
                <Popover content={<div className='popbox'>
                  <pre className='popname'>{newlocal['main']['p93'][language]}</pre>
                </div>} trigger="hover">
                  <div className='p1'>{newlocal['main']['p24'][language]}</div></Popover>
                <div className='p2'>{Number(price * (100 - Number(treasuryFee)) / 100 * cz.winningRate / 100).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}{cz ? cz.symbol : ''}</div>
              </div>
              <div className='pooltextbox'>
                <Popover content={<div className='popbox'>
                  <pre className='popname'>{newlocal['main']['p94'][language]}</pre>
                </div>} trigger="hover">
                  <div className='p1'>{newlocal['main']['p25'][language]}</div></Popover>
                <div className='p2'>{Number(price * (100 - Number(treasuryFee)) / 100).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}{cz ? cz.symbol : ''}</div>
              </div>
              <div className='pooltextbox'>
                <Popover content={<div className='popbox'>
                  <pre className='popname'>{newlocal['main']['p95'][language]}{treasuryFee}%</pre>
                </div>} trigger="hover">
                  <div className='p1'>{newlocal['main']['p26'][language]}</div></Popover>
                <div className='p2'>{Number(price * Number(treasuryFee) / 100).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}{cz ? cz.symbol : ''}</div>
              </div>
              {
                jyloading ? <div className='btnBox'>
                  {
                    <div className='downBtn5'>
                      <img className='loadico' src={loadingzwhite}></img>
                    </div>
                  }
                  {
                    <div className='topBtn5'>
                      <img className='loadico' src={loadingzwhite}></img>
                    </div>
                  }

                </div> : <div className='btnBox'>
                  {
                    (time === '' || price < minOrderAmount || price > sqAmount || showpriceErrormsg || price === 0) ? <div className='downBtn' onClick={() => goOrder2(2)}>
                      <span className='t'>{newlocal['main']['p28'][language]}</span>
                      <img className='i' src={looktopbtn} />
                    </div> : <div className='downBtn' onClick={() => goOrder(2)}>
                      <span className='t'>{newlocal['main']['p28'][language]}</span>
                      <img className='i' src={looktopbtn} />
                    </div>
                  }
                  {
                    (time === '' || price < minOrderAmount || price > sqAmount || showpriceErrormsg || price === 0) ? <div className='topBtn' onClick={() => goOrder2(1)}>
                      <span className='t'>{newlocal['main']['p27'][language]}</span>
                      <img className='i' src={lookdownbtn} />
                    </div> : <div className='topBtn' onClick={() => goOrder(1)}>
                      <span className='t'>{newlocal['main']['p27'][language]}</span>
                      <img className='i' src={lookdownbtn} />
                    </div>
                  }

                </div>
              }

            </div>
          }
          {
            tab2 === 2 && <div>
              <div className='pooltitle'>{newlocal['main']['p551'][language]}<div onClick={() => setshow2(true)} className='rb'><img className='rbi' src={addpool}></img>{newlocal['main']['p100'][language]}</div></div>
              <div className='poolCheckBox2' onClick={() => { setshowmode2(true) }} >
                <Avatar className='poolico' size={36} style={{ color: '#ffffff', backgroundColor: Avaterlist[cz.colorindex] }} onClick={() => setshowmode2(true)}>{cz ? cz.name[0] : ''}</Avatar>
                <div className='poolinfo' onClick={() => setshowmode2(true)}>
                  <div className='top'>{(cz ? cz.name : '')} pool</div>
                  <div className='bottom'>
                    <Popover content={<div className='popbox'>
                      <pre className='popname'>{newlocal['main']['p96'][language]}</pre>
                    </div>} trigger="hover">
                      <div className='bp1'>{newlocal['main']['p291'][language]}</div></Popover>
                    <div className='bp2'>{cz ? Number(cz.weekRate * 100).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'loding...'}%</div>
                  </div>
                </div>
                <img className={cz.issc ? 'selectico r13' : 'selectico r74'} src={newselect} onClick={() => setshowmode2(true)} />
                {
                  !cz.issc && <div className='starbtn'>
                    <img className='selectstar' src={cz.issc ? starb : starh} onClick={() => { goshouc(cz, cz.issc ? 'NO' : 'YES') }} />
                  </div>
                }

              </div>
              <div className='allbox'>
                <div className='box1'>
                  <div className='box2'></div>
                  <div className='p1'>{newlocal['main']['p30'][language]}（{(cz ? cz.symbol : '')}）</div>
                  <div className='p2'>{Number(maxPoolAmount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                </div>
                {/* <div className='p3'>≈${maxPoolAmount}</div> */}
                <div className='p4'>{newlocal['main']['p31'][language]}</div>
                <div className='copyBox'>
                  <div className='copyPbox'>
                    <span className='addres'>{(cz ? cz.address.slice(0, 6) + '...' + cz.address.slice(cz.address.length - 6, cz.address.length) : '')}</span>
                  </div>
                  <button
                    data-clipboard-text={(cz ? cz.address : '')}
                    className="copy-btn2"
                    type="button"
                    style={{ background: 'transparent', border: 'none' }}
                  ><img className='img' src={copy} /></button>
                </div>
              </div>
              <div className='moreinfo'>
                <Popover content={<div className='popbox'>
                  <pre className='popname'>{newlocal['main']['p88'][language]}</pre>
                </div>} trigger="hover">
                  <div className='left'>{newlocal['main']['p32'][language]}</div></Popover>
                <div className='right'>
                  <div className='top'>{(user === false || user === null || user === 'null') ? '0.00' : lp ? Number(lp).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'} LP</div>
                  <div className='bottom'>≈{(user === false || user === null || user === 'null' || totalSupply === '0.00') ? '0.00' : (lp && maxPoolAmount && totalSupply) ? Number(Number(lp) * Number(maxPoolAmount) / Number(totalSupply)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'} {(cz ? cz.symbol : '')}</div>
                </div>
              </div>
              <div className='moreinfo'>
                <Popover content={<div className='popbox'>
                  <pre className='popname'>{newlocal['main']['p89'][language]}</pre>
                </div>} trigger="hover">
                  <div className='left'>{newlocal['main']['p33'][language]}</div></Popover>
                <div className='right'>
                  <div className='top green'>{(user === false || user === null || user === 'null' || totalSupply === '0.00') ? '0.00' : (lp && maxPoolAmount && totalSupply && tokenAmount && profit) ? Number(Number(lp) * Number(maxPoolAmount) / Number(totalSupply) - Number(tokenAmount) + Number(profit)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'} {(cz ? cz.symbol : '')}</div>
                  {/* <div className='bottom'>≈$0.00</div> */}
                </div>
              </div>

              <div className='btnBox'>

                {
                  lp && lp !== '0.00' && <div className='topBtn1' onClick={() => checkmode3(true)}>
                    <span className='t'>{newlocal['main']['p34'][language]}</span>
                  </div>
                }
                {
                  lp && lp !== '0.00' && <div className='topBtn2' onClick={() => checkmode4(true)}>
                    <span className='t'>{newlocal['main']['p35'][language]}</span>
                  </div>
                }
                {
                  (!lp || lp === '0.00') && <div className='topBtn3' onClick={() => checkmode3(true)}>
                    <span className='t'>{newlocal['main']['p36'][language]}</span>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {
          isgerawd && <div className='rightPanel'>
            <div className='pooltitle' style={{ padding: '0' }}>{newlocal['main']['p101'][language]}</div>
            <div className='moreinfo'>
              <div className='left'>{newlocal['main']['p102'][language]}</div>
              <div className='right'>
                <div className='top'>{Number(jlamount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT</div>
                <div className='bottom'>≈{Number(Number(jlamount) / Number(tokenp)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} esBBZ</div>
              </div>
            </div>

            <div className='btnBox'>
              <div className='topBtn3' onClick={() => getRewards()}>
                <span className='t'>{newlocal['main']['p103'][language]}</span>
              </div>
            </div>
          </div>
        }

      </div>


      <div className='mainPanel'>
        <div className='orderBox'>
          <div className='tabBox'>
            <div className={tab === 1 ? 'tab active' : 'tab'} onClick={() => setTab(1)}>{newlocal['main']['p2'][language]}</div>
            <div className={tab === 2 ? 'tab active' : 'tab'} onClick={() => setTab(2)}>{newlocal['main']['p3'][language]}</div>
          </div>
          {
            tab === 1 && <div className='tablebox'>
              <div className='title1box'>
                <div className='title1'>{newlocal['main']['p4'][language]}</div>
                <div className='fcbbox'>
                  <Popover content={<div className='popbox'>
                    <pre className='popname'>{newlocal['main']['p90'][language]}</pre>
                  </div>} trigger="hover">
                    <div className='title2'>{newlocal['main']['p5'][language]}</div></Popover>
                  <div className='title3'>{alltz} {(cz ? cz.symbol : '')}</div>
                </div>
                <div className='fcbbox'>
                  <Popover content={<div className='popbox'>
                    <pre className='popname'>{newlocal['main']['p91'][language]}</pre>
                  </div>} trigger="hover">
                    <div className='title4'>{newlocal['main']['p6'][language]}</div></Popover>
                  <div className='title5' style={{ color: alllr >= 0 ? '#31CB7F' : '#FF5F57' }}>{alllr.toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {(cz ? cz.symbol : '')}</div>
                </div>
              </div>
              <div className='headerbox'>
                <div className='title2'>{newlocal['main']['p8'][language]}</div>
                <div className='title4'>{newlocal['main']['p10'][language]}/{newlocal['main']['p11'][language]}</div>
                <div className='title4'>{newlocal['main']['p61'][language]}</div>
                <div className='title7'>{newlocal['main']['p13'][language]}</div>
              </div>
              {
                orderlist2.map((item) => {
                  return <div className='itembox' onClick={() => { setorderItem(item); setordershowItem(true) }}>
                    <div className='title2' style={{ color: item.btntype === 3 || item.btntype === 2 ? '#9096ad' : '' }}>BTC/USDT <span className='tag' style={{ backgroundColor: item.isLong ? '#31CB7F' : '#FF5F57' }}>{item.isLong ? newlocal['main']['p15'][language] : newlocal['main']['p14'][language]}</span></div>
                    <div className='title4' style={{ color: item.btntype === 3 || item.btntype === 2 ? '#9096ad' : '' }}>{Number(`${item.amount / 10 ** 18}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<span>{item.winningRate}%</span></div>
                    <div className='title4' style={{ color: item.btntype === 3 || item.btntype === 2 ? '#9096ad' : '' }}>{item.isNewXn && <img className='loadico' src={loadingz}></img>}{!item.isNewXn ? (item.btntype === 3 ? '...' : item.creatPrice) : ''}</div>
                    <div className='title8' style={{ color: (item.isWin === 1 || item.isWin === 3) ? '#31CB7F' : '#FF5F57' }}>
                      {`${((item.btntype === 1 && !item.isNewXn) || (item.btntype === 4 && item.endPrice && item.endPrice !== 'NaN')) ? (item.lr.toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : ''}`}
                      {item.isNewXn && <span className='ptcl3'>{newlocal['main']['p65'][language]}</span>}
                      {item.btntype === 2 && <span className='ptcl2' onClick={() => closePosition(item)} style={{ color: '#9096ad' }}>{newlocal['main']['p79'][language]}</span>}
                      {item.btntype === 2 && <span className='ptcl' onClick={() => closePosition(item)} style={{ color: '#3061e8' }}>{newlocal['main']['p67'][language]}</span>}
                      {item.btntype === 3 && <Popover content={<div className='popbox'><pre className='popname'>{newlocal['main']['p92'][language]}</pre></div>} trigger="hover"><span className='ptcl2' onClick={() => refundPosition(item)} style={{ color: '#9096ad' }}>{newlocal['main']['p79'][language]}</span></Popover>}
                      {item.btntype === 3 && <span className='ptcl' onClick={() => refundPosition(item)} style={{ color: '#3061e8' }}>{newlocal['main']['p68'][language]}</span>}
                      {item.btntype === 4 && <span className='ptcl3' >{newlocal['main']['p66'][language]}</span>}
                    </div>
                  </div>
                })
              }
              {
                orderlist2.length <= 0 && <div className='nulltext'>{newlocal['main']['p56'][language]}</div>
              }

            </div>
          }

          {
            tab === 2 && <div className='tablebox'>
              <div className='headerbox'>
                <div className='title2'>{newlocal['main']['p8'][language]}</div>
                <div className='title4'>{newlocal['main']['p10'][language]}/{newlocal['main']['p11'][language]}</div>
                <div className='title4'>{newlocal['main']['p61'][language]}</div>
                <div className='title7'>{newlocal['main']['p13'][language]}</div>
              </div>
              {
                endorderlist.map((item) => {
                  return <div className='itembox' onClick={() => { setorderItem2(item); setordeshowItem2(true) }}>
                    <div className='title2'>BTC/USDT <span className='tag' style={{ backgroundColor: item.isLong ? '#31CB7F' : '#FF5F57' }}>{item.isLong ? newlocal['main']['p15'][language] : newlocal['main']['p14'][language]}</span></div>
                    <div className='title4'>{Number(`${item.amount / 10 ** 18}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<span>{cz.winningRate}%</span></div>
                    <div className='title4'>{Number(`${item.createPrice / 10 ** 8}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    <div className='title9' style={{ color: (item.isWin === 1 || item.isWin === 3) ? '#31CB7F' : (item.isWin === 2) ? '#FF5F57' : '#ffffff' }}>
                      <div className='bbbb'>
                        {item.isWin !== 4 ? `${(item.lr.toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}` : ''}
                        {item.isWin === 4 && <span className='ptcl2' style={{ color: '#9096ad' }}>{newlocal['main']['p79'][language]}</span>}
                        {item.isWin === 4 && <span className='ptcl' style={{ color: '#ffffff' }}>{newlocal['main']['p681'][language]}</span>}
                      </div>
                      <img onClick={() => gojypage(item.hash)} src={getgoll} className='getgoll'></img>
                    </div>
                  </div>
                })
              }
              {
                endorderlist.length <= 0 && <div className='nulltext'>{newlocal['main']['p57'][language]}</div>
              }
              {
                endorderlist.length > 0 && <ConfigProvider locale={language === 'zhcn' ? zhHK : enUs}>
                  <Pagination size="small" showSizeChanger={true} onChange={(page, pageSize) => { changepagenumber(page, pageSize) }} current={Number(pageNumber)} total={Number(pageTotal)} />
                </ConfigProvider>
              }
            </div>
          }

        </div>
      </div>


      {
        showmode1 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p37'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshowmode1(false)}></img>
            <div className='langitemIco' onClick={() => { setshowmode1(false) }}><img className='btnico' src={btc} />BTC/USDT</div>
          </div>
        </div>
      }
      {
        showmode2 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p38'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshowmode2(false)}></img>
            <div className='toptitlebbox'>
              <div className='tp1'>{newlocal['main']['p85'][language]}</div>
              <div className='tp2'>{newlocal['main']['p86'][language]}</div>
            </div>
            <div className='scorllboox'>
              {
                poolList.map((item) => {
                  return <div className='langitemIco' onClick={() => { setcz(item); setloadam1(1); setloadam2(1); nowcz = item; setshowmode2(false); navigate(`/?pool=${item.address}`) }}> <Avatar size={30} style={{ color: '#ffffff', backgroundColor: Avaterlist[item.colorindex] }} className='btnico'>{item.name[0]}</Avatar>{item.name} pool<div className='btngreen'>{item.winningRate}%</div></div>
                })
              }
            </div>


          </div>
        </div>
      }

      {
        showmode3 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p39'][language]}{(cz ? cz.symbol : '')}</div>
            <img className='closeico' src={close} onClick={() => setshowmode3(false)}></img>

            <div className='nummberbox'>
              <div className='top'>
                <div className='tp1'>{newlocal['main']['p41'][language]}</div>
                <div className='tp2'>{newlocal['main']['p42'][language]}：{sqAmount ? Number(sqAmount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</div>
              </div>
              <div className='bottom'>
                <input className='bt1' type={'nummber'} value={zy1} onChange={(e) => { setzy1(e.currentTarget.value) }} />
                <div className='bp2'>{(cz ? cz.symbol : '')}</div>
              </div>
            </div>
            {
              showpriceErrormsg2 && <div className='priceErrBox' >
                <img className='priceErrIco' src={errico} />
                <div className='priceErrText'>{`${newlocal['main']['p59'][language]} ${cz.symbol}`}<span onClick={() => goPaydB()} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#9096AD' }}>{`${newlocal['main']['p60'][language]} ${cz.symbol}`}</span></div>
              </div>
            }
            {
              showpriceErrormsg3 && <div className='priceErrBox' >
                <img className='priceErrIco' src={errico} />
                <div className='priceErrText '>{`${newlocal['main']['p48'][language]}`}</div>
              </div>
            }
            <div className='btn' onClick={() => ZY()}>
              <span className='t'>{newlocal['main']['p43'][language]}</span>
            </div>

          </div>
        </div>
      }

      {
        showmode4 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p40'][language]}{(cz ? cz.symbol : '')}</div>
            <img className='closeico' src={close} onClick={() => setshowmode4(false)}></img>
            <div className='nummberbox'>
              <div className='top'>
                <div className='tp1'>{newlocal['main']['p44'][language]}</div>
                <div className='tp2'>{newlocal['main']['p45'][language]}：{lp ? Number(lp).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</div>
              </div>
              <div className='bottom'>
                <input className='bt1' type={'nummber'} value={zy2} onChange={(e) => { setzy2(e.currentTarget.value) }} />
                <div className='bp2'>{(cz ? cz.symbol : '')}</div>
              </div>
            </div>

            <div className='btn' onClick={() => SH()}>
              <span className='t'>{newlocal['main']['p46'][language]}</span>
            </div>

          </div>
        </div>
      }

      {
        approveBox && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p51'][language] + ' ' + (cz ? cz.symbol : '')}</div>
            <img className='closeico' src={close} onClick={() => setApproveBox(false)}></img>
            <div className='info'>{newlocal['main']['p52'][language] + ' ' + (cz ? cz.symbol : '') + ' ' + (Number(linktype) === 1 ? newlocal['main']['p53arb'][language] : newlocal['main']['p53bsc'][language])}</div>

            <div className='btn' onClick={() => approve(false)}>
              <span className='t'>{newlocal['main']['p54'][language]}</span>
            </div>

          </div>
        </div>
      }

      {
        show1 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['cz']['p11'][language]}</div>
            <img className='closeico' src={close} onClick={() => { setshow1(false); settaddress(''); setaddress(''); setsicz('') }}></img>
            <div className='sbox'>
              <img className='ico' src={search} />
              <input className='input' placeholder={newlocal['cz']['p12'][language]} value={value} onChange={(e) => { setvalue(e.currentTarget.value.toLowerCase()) }} />
            </div>
            {
              value !== '' && address !== '' && taddress !== '' && <div className='listbox'>
                <div className='item'>
                  <div className='left'>
                    <Avatar className='ico' size={36} style={{ color: '#ffffff', backgroundColor: Avaterlist[0] }}>{tname[0]}</Avatar>
                    <div className='tbox'>
                      <div className='tt'>{tname ? tname + ' Pool' : ''}</div>
                      <div className='tb'>{tname ? tname : ''}</div>
                    </div>
                  </div>
                  {
                    iscz && <div className='right' onClick={() => { go(address, 1) }}>
                      {newlocal['cz']['p13'][language]}
                    </div>
                  }
                  {
                    !iscz && address !== '' && taddress !== '' && <div className='rightbtn' onClick={() => { creat() }}>
                      {newlocal['cz']['p14'][language]}
                    </div>
                  }

                </div>
              </div>
            }

            <div className='btn' onClick={() => cx(value)}><div className='t'>{newlocal['cz']['p26'][language]}</div></div>
          </div>
        </div>
      }
      {
        show2 && <div className='langboxmask'>
          <div className='langBox langBox1 langBox324'>
            <div className='title'>{newlocal['creatpool']['p1'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow2(false)}></img>
            <div className='craetbox'>
              <div className='p1'>{newlocal['creatpool']['p2'][language]}</div>
              <input value={cvaleu1} className='input1' placeholder={newlocal['textTip']['p1'][language]} onChange={(e) => { setcvaleu1(e.currentTarget.value.toLowerCase()) }}></input>
              <div className='p1'>{newlocal['creatpool']['p3'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu2(200)} className={cvaleu2 === 200 ? 'changeitem changeitemacitve' : 'changeitem'}>2%</div>
                <div onClick={() => setcvaleu2(500)} className={cvaleu2 === 500 ? 'changeitem changeitemacitve' : 'changeitem'}>5%</div>
                <div onClick={() => setcvaleu2(1000)} className={cvaleu2 === 1000 ? 'changeitem changeitemacitve' : 'changeitem'}>10%</div>
              </div>
              <div className='p1'>{newlocal['creatpool']['p4'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu3(7500)} className={cvaleu3 === 7500 ? 'changeitem changeitemacitve' : 'changeitem'}>75%</div>
                <div onClick={() => setcvaleu3(8000)} className={cvaleu3 === 8000 ? 'changeitem changeitemacitve' : 'changeitem'}>80%</div>
                <div onClick={() => setcvaleu3(8500)} className={cvaleu3 === 8500 ? 'changeitem changeitemacitve' : 'changeitem'}>85%</div>
                <div onClick={() => setcvaleu3(9000)} className={cvaleu3 === 9000 ? 'changeitem changeitemacitve' : 'changeitem'}>90%</div>
              </div>
              <div className='p1'>{newlocal['creatpool']['p5'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu4(500)} className={cvaleu4 === 500 ? 'changeitem changeitemacitve' : 'changeitem'}>5%</div>
                <div onClick={() => setcvaleu4(1000)} className={cvaleu4 === 1000 ? 'changeitem changeitemacitve' : 'changeitem'}>10%</div>
                <div onClick={() => setcvaleu4(2000)} className={cvaleu4 === 2000 ? 'changeitem changeitemacitve' : 'changeitem'}>20%</div>
              </div>
              <div className='btn  btnnew' onClick={() => creat()}><div className='t'>{newlocal['cz']['p18'][language]}</div></div>
            </div>
          </div>
        </div>
      }
      {
        frameflag && <div className='langboxmask'>
          <div className='langBox'>
            <div className='title' style={{ textAlign: 'center' }}>{newlocal['main']['p105'][language]}</div>
            <div className='btn' onClick={() => goReash()}><div className='t'>{newlocal['main']['p106'][language]}</div></div>
          </div>
        </div>
      }
      {
        showorderItem && <div className='langboxmask'>
          <div className='langBoxorder'>
            <img className='closeico' src={close} onClick={() => setordershowItem(false)}></img>
            <div className='ordert'>
              BTC/USDT <span className='tag' style={{ backgroundColor: orderItem.isLong ? '#31CB7F' : '#FF5F57' }}>{orderItem.isLong ? newlocal['main']['p15'][language] : newlocal['main']['p14'][language]}</span>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p10'][language]}</div>
              <div className='ooggg2'>{Number(`${orderItem.amount / 10 ** 18}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p11'][language]}</div>
              <div className='ooggg2'>{orderItem.winningRate}%</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p61'][language]}</div>
              <div className='ooggg2'>{orderItem.isNewXn && <img className='loadico' src={loadingz}></img>}{!orderItem.isNewXn ? (orderItem.btntype === 3 ? '...' : orderItem.creatPrice) : ''}</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p62'][language]}</div>
              <div className='ooggg2'>{orderItem.btntype === 4 ? (orderItem.endPrice && orderItem.endPrice !== 'NaN') ? orderItem.endPrice : newlocal['main']['p662'][language] : btcPrice.price}</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p63'][language]}</div>
              <div className='ooggg3'>
                {!orderItem.isNewXn && <span className='time1' style={{ color: orderItem.btntype === 3 || orderItem.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem.createAt * 1000).format('YYYY-MM-DD')}</span>}
                {!orderItem.isNewXn && <span className='time2' style={{ color: orderItem.btntype === 3 || orderItem.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem.createAt * 1000).format('HH:mm:ss')}</span>}
                {orderItem.isNewXn && <img className='loadico' src={loadingz}></img>}
              </div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p12'][language]}</div>
              <div className='ooggg3'>
                {(orderItem.btntype === 1 && !orderItem.isNewXn) ? orderItem.xsTime : ''}
                {orderItem.isNewXn && <img className='loadico' src={loadingz}></img>}
                {orderItem.btntype !== 1 && <span className='time1' style={{ color: orderItem.btntype === 3 || orderItem.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem.expiryAt * 1000).format('YYYY-MM-DD')}</span>}
                {orderItem.btntype !== 1 && <span className='time2' style={{ color: orderItem.btntype === 3 || orderItem.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem.expiryAt * 1000).format('HH:mm:ss')}</span>}
              </div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p13'][language]}</div>
              <div className='ooggg3' style={{ color: (orderItem.isWin === 1 || orderItem.isWin === 3) ? '#31CB7F' : '#FF5F57' }} >
                {`${((orderItem.btntype === 1 && !orderItem.isNewXn) || (orderItem.btntype === 4 && orderItem.endPrice && orderItem.endPrice !== 'NaN')) ? (orderItem.lr.toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : ''}`}
                {orderItem.isNewXn && <span className='ptcl3'>{newlocal['main']['p65'][language]}</span>}
                {orderItem.btntype === 2 && <span className='ptcl2' onClick={() => closePosition(orderItem)} style={{ color: '#9096ad' }}>{newlocal['main']['p79'][language]}</span>}
                {orderItem.btntype === 2 && <span className='ptcl' onClick={() => closePosition(orderItem)} style={{ color: '#3061e8' }}>{newlocal['main']['p67'][language]}</span>}
                {orderItem.btntype === 3 && <Popover content={<div className='popbox'><pre className='popname'>{newlocal['main']['p92'][language]}</pre></div>} trigger="hover"><span className='ptcl2' onClick={() => refundPosition(orderItem)} style={{ color: '#9096ad' }}>{newlocal['main']['p79'][language]}</span></Popover>}
                {orderItem.btntype === 3 && <span className='ptcl' onClick={() => refundPosition(orderItem)} style={{ color: '#3061e8' }}>{newlocal['main']['p68'][language]}</span>}
                {orderItem.btntype === 4 && <span className='ptcl3' >{newlocal['main']['p66'][language]}</span>}
              </div>
            </div>
          </div>
        </div>
      }

      {
        showorderItem2 && <div className='langboxmask'>
          <div className='langBoxorder'>
            <img className='closeico' src={close} onClick={() => setordeshowItem2(false)}></img>
            <div className='ordert'>
              BTC/USDT <span className='tag' style={{ backgroundColor: orderItem2.isLong ? '#31CB7F' : '#FF5F57' }}>{orderItem2.isLong ? newlocal['main']['p15'][language] : newlocal['main']['p14'][language]}</span>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p10'][language]}</div>
              <div className='ooggg2'>{Number(`${orderItem2.amount / 10 ** 18}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p11'][language]}</div>
              <div className='ooggg2'>{cz.winningRate}%</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p61'][language]}</div>
              <div className='ooggg2'>{Number(`${orderItem2.createPrice / 10 ** 8}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p62'][language]}</div>
              <div className='ooggg2'>{Number(`${orderItem2.expiryPrice / 10 ** 8}`).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p63'][language]}</div>
              <div className='ooggg3'>
                {!orderItem2.isNewXn && <span className='time1' style={{ color: orderItem2.btntype === 3 || orderItem2.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem2.createAt * 1000).format('YYYY-MM-DD')}</span>}
                {!orderItem2.isNewXn && <span className='time2' style={{ color: orderItem2.btntype === 3 || orderItem2.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem2.createAt * 1000).format('HH:mm:ss')}</span>}
              </div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p12'][language]}</div>
              <div className='ooggg3'>
                {orderItem2.btntype !== 1 && <span className='time1' style={{ color: orderItem2.btntype === 3 || orderItem2.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem2.expiryAt * 1000).format('YYYY-MM-DD')}</span>}
                {orderItem2.btntype !== 1 && <span className='time2' style={{ color: orderItem2.btntype === 3 || orderItem2.btntype === 2 ? '#9096ad' : '' }}>{dayjs(orderItem2.expiryAt * 1000).format('HH:mm:ss')}</span>}
              </div>
            </div>
            <div className='orderg'>
              <div className='ooggg1'>{newlocal['main']['p13'][language]}</div>
              <div className='ooggg3' style={{ color: (orderItem2.isWin === 1 || orderItem2.isWin === 3) ? '#31CB7F' : '#FF5F57' }} >
                <div className='bbbb'>
                  {orderItem2.isWin !== 4 ? `${(orderItem2.lr.toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}` : ''}
                  {orderItem2.isWin === 4 && <span className='ptcl2' style={{ color: '#9096ad' }}>{newlocal['main']['p79'][language]}</span>}
                  {orderItem2.isWin === 4 && <span className='ptcl' style={{ color: '#ffffff' }}>{newlocal['main']['p681'][language]}</span>}
                </div>
                <img onClick={() => gojypage(orderItem2.hash)} src={getgoll} className='getgoll'></img>
              </div>
            </div>
          </div>
        </div>
      }



    </div>
  );
}



export default MainNew;
