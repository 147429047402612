import './index.less';
import toplogo from '../../static/toplogo.png'
import { Select } from 'antd';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import discord from '../../static/discord.png'
import telegram from '../../static/telegram.png'
import twitter from '../../static/twitter.png'
import discordnew from '../../static/discordnew.png'
import telegramnew from '../../static/telegramnew.png'
import twitternew from '../../static/twitternew.png'
import newlocal from '../../unilt/newlocal'


const { Option } = Select;

function NewBottomModeH5() {
  const language = useSelector(state => state.language)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let [ico1, setico1] = useState(false)
  let [ico2, setico2] = useState(false)
  let [ico3, setico3] = useState(false)

  const handleChange = (value) => {
    dispatch({ type: 'SETLANGUAGE', language: value })
  };

  const goPage1 = (() => {
    document.getElementsByClassName("AppH5")[0].scrollTop = 0
  })

  const goPage4 = (() => {
    window.open(`https://twitter.com/bbztrade`, "_blank",);
  })

  const goPage5 = (() => {
    window.open(`https://discord.gg/JFKKTDBdH7`, "_blank",);

  })

  const goPage6 = (() => {
    window.open(`https://t.me/BBZTrade`, "_blank",);
  })

  const goPage7 = (() => {
    if (language === 'zhcn') {
      window.open('https://bbz-trade.gitbook.io/bbz/tiao-kuan/tiao-kuan-he-xi-ze')
    } else if (language === 'en') {
      window.open('https://bbz-trade.gitbook.io/bbz-1/terms/terms-and-conditions')
    }
  })

  return (
    <div className="bottomboxh5">
      <div className='cont'>
        <div className='mode1'>
          <img className='bottomlogo' src={toplogo} />
        </div>
        <div className='mode3'>
          <img className='ico' onClick={() => goPage4()} onMouseEnter={() => setico1(true)} onMouseLeave={() => setico1(false)} src={ico1 ? twitter : twitternew} />
          <img className='ico' onClick={() => goPage5()} onMouseEnter={() => setico2(true)} onMouseLeave={() => setico2(false)} src={ico2 ? discord : discordnew} />
          <img className='ico' onClick={() => goPage6()} onMouseEnter={() => setico3(true)} onMouseLeave={() => setico3(false)} src={ico3 ? telegram : telegramnew} />
        </div>
        <div className='mode2'>
          <div className='link' onClick={() => goPage7()}>{newlocal['bottombox']['link1'][language]}</div>
          <div className='link1'>BBZ © 2023</div>
        </div>
      </div>
    </div>
  );
}

export default NewBottomModeH5;
