import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover, Avatar, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import newlocal from '../../unilt/newlocal'
import emitter from '../../unilt/emit';
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import { useNavigate, useLocation, useParams, useSearchParams, useRouteError } from "react-router-dom";
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import axios from '../../unilt/axios';
import close from '../../static/close.png'
import goback from '../../static/h5/goback.png'

var Web3 = require('web3')
let lbTime = 0

const jsonrc1 = [
  {
    "type": "function",
    "name": "mint",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getResidueAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getTotalAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getAlreadyMintAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
]

const jsonrc2 = [
  {
    "type": "function",
    "name": "createPool",
    "inputs": [{ "name": "collateralToken", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "queryPoolAddress",
    "inputs": [{ "name": "collateralToken", "type": "address" }],
    "outputs": [{ "name": "address", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


let provider = false
let islock = false
let czTime = 0
let userlistlength = 0


function MyScores() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  const nprovider = useSelector(state => state.nprovider)
  const [pageInfo, setpageInfo] = useState(false)
  const renderuserRef = useRef(true)
  const signtoken = useSelector(state => state.signtoken)
  const [getParams, setParam] = useSearchParams()
  const [boxlist, setboxlist] = useState([])
  const [setitem, setsetitem] = useState(false)
  const [show, setshow] = useState(false)
  const typeC = useRef(false)

  let [pageSize, setpageSize] = useState(10)
  let [pageNumber, setpageNumber] = useState(1)
  let [pageTotal, setpageTotal] = useState(0)

  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false

      const type = getParams.getAll('type')
      typeC.current = type[0]

      if (user === false || user === null || user === 'null') {

      } else {
        getdbstart()
      }
      islock = true
      return
    }
  }, [])



  useEffect(() => {
    if (renderuserRef.current) {
      renderuserRef.current = false
    } else {
      if (user === false || user === null || user === 'null') {

      } else {
        if (islock) {
          getdbstart()
        }
      }
    }

  }, [user])


  const getdbstart = (() => {
    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }
    if (user === false || user === null || user === 'null') {

    } else {

      let str = ''
      switch (language) {
        case 'zhcn': str = '正在加載...'; break;
        case 'en': str = 'Loading...'; break;
        default: break;
      }
      message.loading({ content: str, key: 'czlodaingg', duration: 0 });
      getuserinfo(true)
    }
  })

  useEffect(() => {
    if (signtoken === false || signtoken === null || signtoken === 'null') {

    } else {
      getdbstart()
    }

  }, [signtoken])

  const getuserinfo = (async (ispd = false) => {
    let that = this
    let re = axios({
      url: 'active/user/info',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setpageInfo(res.content)
        message.destroy('czlodaingg')
        getbox()
      } else {
        message.destroy('czlodaingg')
      }

    })
  })


  const getbox = (() => {
    let re = axios({
      url: 'active/user/integral',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        pageNum: pageNumber,
        pageSize: pageSize
      }
    })
    re.then(async (res) => {
      let list = res.content.list
      setboxlist(list)
      setpageTotal(res.content.total)
    })
  })

  const changepagenumber = ((page, size) => {
    if (size !== pageSize) {
      setpageNumber(1)
      setpageSize(size)
    } else {
      setpageNumber(page)

    }
  })

  useEffect(() => {
    getbox()
  }, [pageNumber, pageSize])

  const gobacks = (()=>{
    document.getElementsByClassName("AppH5")[0].scrollTop = 0
    navigate('/blindbox')
  })

  return (
    <div className="myscoresh5" >
      <div className='title'><img className='back' onClick={()=>gobacks()} src={goback}></img>{`${newlocal['box']['p2'][language]} （${pageInfo.integralAmount}）`}</div>
      {
        boxlist.map((item) => {
          return <div className='itembox'>
            <div className='p1'>
              {item.orderType === 1 ? `${newlocal['box']['p25'][language]}` : ``}
              {item.orderType === 2 ? `${newlocal['box']['p27'][language]}` : ``}
              {item.orderType === 3 ? `${newlocal['box']['p26'][language]}` : ``}
              {item.orderType === 4 ? `${newlocal['box']['p39'][language]}` : ``}
              {item.orderType === 5 ? `${newlocal['box']['p8'][language]}` : ``}
              {item.orderType === 6 ? `${newlocal['box']['p40'][language]}` : ``}
              {item.orderType === 10 ? `${newlocal['box']['p41'][language]}` : ``}
              <div className='p3'>
                {dayjs(item.updateTime).format('YYYY-MM-DD')}
              </div>
            </div>
            <div className='p2'>
              {item.ransactionType === 'INCOME' ? `+${item.ransactionAmount}` : ``}
              {item.ransactionType === 'EXPEND' ? `-${item.ransactionAmount}` : ``}
            </div>
          </div>
        })
      }
      <Pagination size="small" showSizeChanger={true} onChange={(page, pageSize) => { changepagenumber(page, pageSize) }} current={Number(pageNumber)} total={Number(pageTotal)} />
    </div>
  );
}

export default MyScores;
