import { useState, useEffect, useRef } from 'react';
import { message, Popover } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import './index.less';
import toplogo from '../../static/toplogo.png'
import emitter from '../../unilt/emit'
import checkYs from '../../static/checkYs.png'
import newlocal from '../../unilt/newlocal'
import linkGash from '../../static/linkGash.png'
import close from '../../static/close.png'
import metaico2 from '../../static/metaico2.png'
import bsc from '../../static/bsc.png'
import arb from '../../static/arb.png'
import base from '../../static/base.png'
import metamask from '../../static/metamask.png'
import CoinbaseWallet from '../../static/CoinbaseWallet.png'
import OKXWallet from '../../static/OKXWallet.png'
import walletconnect from '../../static/walletconnect.png'
import arbico from '../../static/arbico.png'
import mianmenu42 from '../../static/mianmenu42.png'
import detectEthereumProvider from '@metamask/detect-provider';
import Clipboard from 'clipboard'
import linkData from '../../unilt/linkTypeData'
import axios from '../../unilt/axios';
import axiosNew from 'axios'
import dayjs from 'dayjs'
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import h5menunew from '../../static/h5/h5menunew.png'



var Web3 = require('web3')

let cmsg = ''
let startflag = false
let provider = false
let linktypeStr = '3'


const jsonrc1 = [
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [{ "name": "account", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "allowance",
    "inputs": [{ "name": "owner", "type": "address" }, { "name": "spender", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "approve",
    "inputs": [{ "name": "spender", "type": "address" }, { "name": "amount", "type": "uint256" }],
    "outputs": [{ "name": "end", "type": "bool" }]
  },
  {
    "type": "function",
    "name": "getUserUnlockesBBZAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getUserUnlockBBZAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getUserUnlockBBZAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getRewardTokens",
    "inputs": [],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'address',
          type: 'address',
        },
      ]
    }]
  },
  {
    "type": "function",
    "name": "getReceivedReward",
    "inputs": [{ "name": "address_token", "type": "address" }],
    "outputs": [{ "name": "amount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getUserReward",
    "inputs": [{ "name": "address_token", "type": "address" }],
    "outputs": [{ "name": "amount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "symbol",
    "inputs": [],
    "outputs": [{ "name": "symbol", "type": "string" }]
  },
  {
    "type": "function",
    "name": "getUserMultiplierPoints",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getNotConvertUserMultiplierPoints",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "userMultiplierTrans",
    "inputs": []
  },
  {
    "type": "function",
    "name": "getAllUserReward",
    "inputs": [],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'tokenAdddress',
          type: 'address',
        },
        {
          name: 'amount',
          type: 'uint256',
        }
      ]
    }]
  },
  {
    "type": "function",
    "name": "extractUserReward",
    "inputs": [{ "name": "address _token", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  },
  {
    "type": "function",
    "name": "pledgeBBZ",
    "inputs": [{ "name": "amount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "releaseBBZtoBBZ",
    "inputs": []
  },
  {
    "type": "function",
    "name": "extractBBZ",
    "inputs": []
  },
  {
    "type": "function",
    "name": "getSuportToken",
    "inputs": [],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'address',
          type: 'address',
        },
      ]
    }]
  }
]


function NewTopMode() {
  const { connect, connectors, error: errorsss, isLoading, pendingConnector, data: datasss } = useConnect()
  const { disconnect } = useDisconnect()
  const { address } = useAccount()
  const { chain } = useNetwork()
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  let [menu1State, setmenu1State] = useState(false)
  let [loginShow, setloginShow] = useState(false)
  let [ys, setys] = useState(false)
  let [link, setlink] = useState(false)
  let [linkgash, setlinkgash] = useState(false)
  let [linkTz, setlinkTz] = useState(false)
  let [linkmsg, setlinkmsg] = useState('')
  let [linkmsg2, setlinkmsg2] = useState('')
  let [account, setAccount] = useState(null)
  let [chainId, setChainId] = useState(null)
  let [maskItem, setMaskItem] = useState(null)
  let [CoinbaseWalletItem, setCoinbaseWalletItem] = useState(null)
  let [OKXWalletItem, setOKXWalletItem] = useState(null)
  let [showqhid, setshowqhid] = useState(false)
  let [showuser, setshowuser] = useState(false)
  const user = useSelector(state => state.user)
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const nprovider = useSelector(state => state.nprovider)
  const signtoken = useSelector(state => state.signtoken)

  let [downloadlink1, setdownloadlink1] = useState(false)
  let [downloadlink2, setdownloadlink2] = useState(false)
  let [downloadlink3, setdownloadlink3] = useState(false)

  let [interStatus, setinterStatus] = useState([true, true, [true, true, true]])
  let [inter, setinter] = useState(true)
  let [interc, setinterc] = useState('')


  let [showsigntoken, setshowsigntoken] = useState(false)


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const locationUse = useLocation()
  const renderRef = useRef(true)
  const rendernetRef = useRef(true)
  const renderAcouuntRef = useRef(true)
  const renderChainRef = useRef(true)


  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false
      emitter.addListener("hiddentc", () => {
        setlinkgash(true)
      });

      emitter.addListener("checksigntoken", () => {
        setshowsigntoken(true)
      });

      emitter.addListener("checkcid", () => {
        checkChainId()
      });

      emitter.addListener("loginout", () => {
        goulougout()
      });

      const copy2 = new Clipboard('.copy-c');
      copy2.on('success', e => {
        message.success({ content: newlocal['main']['copytip'][language], key: 'sadasddsa' })
      });
      copy2.on('error', function (e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
      });
      // watchEth()
      return
    }
  }, [])

  useEffect(() => {
    if (rendernetRef.current) {
      rendernetRef.current = false
      setInterval(() => {
        goStartNetTest()
      }, 5000)
      goStartNetTest1()
      return
    } else {
      let flag = true
      for (let i in interStatus) {
        if (i < 2) {
          if (interStatus[i] === false) {
            flag = false
            break;
          }
        } else if (i === 2) {
          if (interStatus[2][0] === false && interStatus[2][1] === false && interStatus[2][2] === false) {
            flag = false
            break;
          }
        }
      }
      setinter(flag)
    }
  }, [interStatus, interc])


  const goStartNetTest = (() => {
    getKxStatus()
    getJkStatus()
  })

  const goStartNetTest1 = (() => {
    getWeb3Status(0)
  })


  const getKxStatus = (() => {
    let that = this
    axiosNew({
      url: 'https://api.binance.com/api/v3/time',
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        let newinterStatus = interStatus
        newinterStatus[0] = true
        setinterStatus(newinterStatus)
        setinterc(dayjs().unix())
      } else {
        let newinterStatus = interStatus
        newinterStatus[0] = false
        setinterStatus(newinterStatus)
        setinterc(dayjs().unix())
      }
    }).catch((error) => {
      let newinterStatus = interStatus
      newinterStatus[0] = false
      setinterStatus(newinterStatus)
      setinterc(dayjs().unix())
    })
  })

  const getJkStatus = (() => {
    let that = this
    axiosNew({
      url: linkData[linktypeStr.toString()].api + 'pool/poolList',
      method: 'GET',
    }).then((res) => {
      if (res.status === 200) {
        let newinterStatus = interStatus
        newinterStatus[1] = true
        setinterStatus(newinterStatus)
        setinterc(dayjs().unix())
      } else {
        let newinterStatus = interStatus
        newinterStatus[1] = false
        setinterStatus(newinterStatus)
        setinterc(dayjs().unix())
      }
    }).catch((error) => {
      let newinterStatus = interStatus
      newinterStatus[1] = false
      setinterStatus(newinterStatus)
      setinterc(dayjs().unix())
    })
  })

  const getWeb3Status = (async (index) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    web3.eth.Contract.transcationBlockTimeout = 5
    try {
      hy.methods.getSuportToken().call(function (error, result) {
        if (error) {
          let newinterStatus = interStatus
          newinterStatus[2][index % 3] = false
          setinterStatus(newinterStatus)
          setinterc(dayjs().unix())
          setTimeout(() => {
            getWeb3Status(index + 1)
          }, 1000)
        } else {
          let newinterStatus = interStatus
          newinterStatus[2][index % 3] = true
          setinterStatus(newinterStatus)
          setinterc(dayjs().unix())
          setTimeout(() => {
            getWeb3Status(index + 1)
          }, 5000)
        }
      })
    } catch (error) {
      let newinterStatus = interStatus
      newinterStatus[2][index % 3] = false
      setinterStatus(newinterStatus)
      setinterc(dayjs().unix())
      setTimeout(() => {
        getWeb3Status(index + 1)
      }, 1000)
    }

  })

  useEffect(() => {
    provider = nprovider
  }, [nprovider])

  useEffect(() => {
    if (linktype !== undefined) {
      linktypeStr = linktype
    }
  }, [linktype])


  const watchEth = (async () => {
    if (window.ethereum === undefined) {

    } else {
      // const provider = await detectEthereumProvider();
      // setMaskItem(provider)

      // window.ethereum.on('accountsChanged', handleAccountsChanged);

      // function handleAccountsChanged(accounts) {
      //   if (accounts.length === 0) {
      //     dispatch({ type: 'SETUSER', user: null })
      //     setlinkgash(true)
      //     setlinkTz(true)
      //     setlinkmsg(newlocal['topMode']['p1'][language])
      //     cmsg = newlocal['topMode']['p1'][language]
      //   } else if (accounts[0] !== account) {
      //     setAccount(accounts[0])
      //     dispatch({ type: 'SETUSER', user: accounts[0] })
      //   }
      // }


      // window.ethereum.on('chainChanged', handleChainChanged);
      // function handleChainChanged(chainId) {

      //   let bbz = localStorage.getItem('persist:bbzPrd20231028')
      //   let usernew = null
      //   if (bbz) {
      //     bbz = JSON.parse(bbz)
      //     usernew = bbz.user
      //   }
      //   if (usernew === false || usernew === null || usernew === 'null') {


      //     if (startflag) {
      //       startflag = false
      //       getuserinfo()
      //     }

      //     let flag = false
      //     let flagindex = false
      //     for (let n in linkData) {
      //       if (chainId === linkData[n].lid) {
      //         flag = true;
      //         flagindex = n;
      //         break;
      //       }
      //     }
      //     if (flag) {
      //       changlinktypedata(Number(flagindex))
      //     }
      //   } else {
      //     let flag = false
      //     let flagindex = false
      //     for (let n in linkData) {
      //       if (chainId === linkData[n].lid) {
      //         flag = true;
      //         flagindex = n;
      //         break;
      //       }
      //     }
      //     if (flag) {
      //       changlinktypedata(Number(flagindex))
      //     } else {
      //       // checkChainId()
      //       dispatch({ type: 'SETUSER', user: null })
      //     }
      //   }
      // }
    }
  })


  const checkChainId = (async (provider1 = false) => {
    let chainId = `0x${chain.id.toString(16)}`
    if (chainId !== linkData[linktype.toString()].lid) {
      // setshowqhid(true)
      setlinkmsg(`${newlocal['topMode']['boxtip2'][language]}${linkData[linktype.toString()].name}`)
      cmsg = `${newlocal['topMode']['boxtip2'][language]}${linkData[linktype.toString()].name}`
      setlinkmsg2(`${newlocal['topMode']['boxtip3'][language]}${linkData[linktype.toString()].name}`)
      message.warning({
        content: <div><div>{newlocal['topMode']['boxtip2'][language]}{linkData[linktype.toString()].name}</div><div onClick={() => connect1()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{newlocal['topMode']['boxtip3'][language]}:{linkData[linktype.toString()].name}</div></div>,
        duration: 5,
        key: 'wa42146gsgg'
      })
    }
  })



  const getMetaMask = (() => {
    if (typeof window.ethereum !== "undefined") {
      let provider1 = false;
      if (window.ethereum.providers && window.ethereum.providers.length > 0) {
        window.ethereum.providers.forEach(async (p) => {
          if (p.isMetaMask) {
            provider1 = p
          };
        });
      } else if (window.ethereum.isMetaMask) {
        provider1 = window.ethereum
      }
      if (provider1 === false) {
        setlinkTz(true)
        setlinkmsg(newlocal['topMode']['p2'][language])
        setdownloadlink1(true)
        cmsg = newlocal['topMode']['p2'][language]
      } else {
        dispatch({ type: 'SETPROVIDER', nprovider: provider1 })
        startApp(provider1)
      }
    } else {
      setlinkTz(true)
      setlinkmsg(newlocal['topMode']['p2'][language])
      setdownloadlink1(true)
      cmsg = newlocal['topMode']['p2'][language]
    }
  })




  const getCoinbaseWallet = (() => {
    if (typeof window.ethereum !== "undefined") {
      let provider1 = false;
      if (window.ethereum.providers && window.ethereum.providers.length > 0) {
        window.ethereum.providers.forEach(async (p) => {
          if (p.isCoinbaseWallet) {
            provider1 = p;
          }
        });
      } else if (window.ethereum.isCoinbaseWallet) {
        provider1 = window.ethereum
      }
      if (provider1 === false) {
        setlinkTz(true)
        setlinkmsg(newlocal['topMode']['p21'][language])
        setdownloadlink2(true)
        cmsg = newlocal['topMode']['p21'][language]
      } else {
        dispatch({ type: 'SETPROVIDER', nprovider: provider1 })
        startApp(provider1)
      }
    } else {
      setlinkTz(true)
      setlinkmsg(newlocal['topMode']['p21'][language])
      setdownloadlink2(true)
      cmsg = newlocal['topMode']['p21'][language]
    }
  })

  const getOKXWallet = (() => {
    if (typeof window.okxwallet !== "undefined") {
      let provider1 = false;
      if (window.okxwallet.isOkxWallet) {
        provider1 = window.okxwallet
      }
      if (provider1 === false) {
        setlinkTz(true)
        setlinkmsg(newlocal['topMode']['p22'][language])
        setdownloadlink3(true)
        cmsg = newlocal['topMode']['p22'][language]
      } else {
        dispatch({ type: 'SETPROVIDER', nprovider: provider1 })
        startApp(provider1)
      }
    } else {
      setlinkTz(true)
      setlinkmsg(newlocal['topMode']['p22'][language])
      setdownloadlink3(true)
      cmsg = newlocal['topMode']['p22'][language]
    }
  })





  const startApp = (async (provider1) => {
    let chainId = `0x${chain.id.toString(16)}`
    let flag = false
    let flagindex = false
    for (let n in linkData) {
      if (chainId === linkData[n].lid) {
        flag = true;
        flagindex = n;
        break;
      }
    }

    if (flag) {
      changlinktypedata(Number(flagindex))
      getuserinfo(provider1)
    } else {
      startflag = true
      checkChainId(provider1)
    }

    setlinkgash(false)
  })


  const getuserinfo = (async (provider1 = false) => {
    setAccount(address);
    dispatch({ type: 'SETUSER', user: address })
    dispatch({ type: 'SETSIGNTOKEN', signtoken: null })
  })

  const connect1 = (async () => {
    let strr = `${newlocal['topMode']['boxtip2'][language]}${linkData[linktype.toString()].name}`
    if (cmsg === strr) {
      let ethereum = provider
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: linkData[linktype.toString()].lid }],
        });
        // setshowqhid(false)

      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: linkData[linktype.toString()].lid,
                  chainName: linkData[linktype.toString()].name,
                  rpcUrls: [linkData[linktype.toString()].rpcaddress] /* ... */,
                },
              ],
            });
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: linkData[linktype.toString()].lid }],
            });
            setshowqhid(false)
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    }
  })


  const hiddenMenuttc = (() => {
    setmenu1State(false)
    setys(false)
  })



  const goPage1 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/')
  })

  const goPage2 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/pools')
  })

  const goPage3 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/airdrop')
  })

  const goPage4 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/dashboard')
  })

  const goPage5 = (() => {
    if (language === 'zhcn') {
      window.open('https://bbz-trade.gitbook.io/bbz/')
    } else if (language === 'en') {
      window.open('https://bbz-trade.gitbook.io/bbz-1/')
    }
  })

  const goPage6 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/earn')
  })

  const goPage7 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/referrals')
  })

  const goPage8 = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/blindbox')
  })


  const goPageTop = (() => {
    setloginShow(false)
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/')
  })


  const handleChange = (value) => {
    dispatch({ type: 'SETLANGUAGE', language: value })
    setys(false)
  };


  const openLINK = (() => {
    window.open(`${linkData[linktype.toString()].cklink}${user}`)
  })

  const closelink = (async () => {
    setshowuser(false)
    dispatch({ type: 'SETUSER', user: null })
    dispatch({ type: 'SETSIGNTOKEN', signtoken: null })
    disconnect()
  })

  const pdcid = (async (i) => {
    let chainId = `0x${chain.id.toString(16)}`

    if (chainId === linkData[i.toString()].lid) {
      return true
    } else {
      return false
    }
  })


  const changlinktype = (async (i) => {
    if (user === false || user === null || user === 'null') {
      setlink(false)
      dispatch({ type: 'SETLINKTYPE', linktype: i })
      setTimeout(() => {
        emitter.emit("reloadmian", "Hello")
      }, 1000)

    } else {

      let ethereum = provider

      if (user === false || user === null || user === 'null') {
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: linkData[i.toString()].lid }],
          });
          let g = pdcid(i)
          if (g) {
            setlink(false)
            dispatch({ type: 'SETLINKTYPE', linktype: i })
            setTimeout(() => {
              emitter.emit("reloadmian", "Hello")
            }, 1000)
          }
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: linkData[i.toString()].lid,
                    chainName: linkData[i.toString()].name,
                    rpcUrls: [linkData[i.toString()].rpcaddress] /* ... */,
                  },
                ],
              });
              await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: linkData[i.toString()].lid }],
              });
              setshowqhid(false)
              let g = pdcid(i)
              if (g) {
                setlink(false)
                dispatch({ type: 'SETLINKTYPE', linktype: i })
                setTimeout(() => {
                  emitter.emit("reloadmian", "Hello")
                }, 1000)
              }
            } catch (addError) {
            }
          }
        }
      } else {
        let strrrr = linkData[i.toString()].lid
        for (let m in chains) {
          if (`0x${chains[m].id.toString(16)}` === strrrr) {
            switchNetwork(chains[m].id)
            break;
          }
        }
      }
    }
  })



  const changlinktypedata = (async (i) => {
    setlink(false)
    dispatch({ type: 'SETLINKTYPE', linktype: i })
    setTimeout(() => {
      emitter.emit("reloadmian", "Hello")
    }, 1000)

  })

  const closett = (() => {
    setlinkTz(false)
    setdownloadlink1(false)
    setdownloadlink2(false)
    setdownloadlink3(false)
  })

  const openttt = ((url) => {
    window.open(url, '_blank')
  })



  useEffect(() => {
    if (renderAcouuntRef.current) {
      renderAcouuntRef.current = false
      return
    } else {
      if (address === undefined) {
        dispatch({ type: 'SETUSER', user: null })
        dispatch({ type: 'SETSIGNTOKEN', signtoken: null })
      } else {
        setAccount(address)
        dispatch({ type: 'SETUSER', user: address })
        dispatch({ type: 'SETSIGNTOKEN', signtoken: null })
      }
    }
  }, [address])

  useEffect(() => {
    if (datasss !== undefined) {
      dispatch({ type: 'SETPROVIDER', nprovider: datasss.provider })
      startApp(datasss.provider)
    }
  }, [datasss])


  useEffect(() => {
  }, [errorsss])

  useEffect(() => {
    if (renderChainRef.current) {
      renderChainRef.current = false
      return
    } else {

      if (chain === undefined) {

      } else {
        let chainId = `0x${chain.id.toString(16)}`
        let bbz = localStorage.getItem('persist:bbzPrd20231028')
        let usernew = null
        if (bbz) {
          bbz = JSON.parse(bbz)
          usernew = bbz.user
        }
        if (usernew === false || usernew === null || usernew === 'null') {


          if (startflag) {
            startflag = false
            getuserinfo()
          }

          let flag = false
          let flagindex = false
          for (let n in linkData) {
            if (chainId === linkData[n].lid) {
              flag = true;
              flagindex = n;
              break;
            }
          }
          if (flag) {
            changlinktypedata(Number(flagindex))
          }
        } else {
          let flag = false
          let flagindex = false
          for (let n in linkData) {
            if (chainId === linkData[n].lid) {
              flag = true;
              flagindex = n;
              break;
            }
          }
          if (flag) {
            changlinktypedata(Number(flagindex))
          } else {
            dispatch({ type: 'SETUSER', user: null })
            dispatch({ type: 'SETSIGNTOKEN', signtoken: null })
          }
        }
      }
    }
  }, [chain])


  const gosigin = (async () => {
    let that = this
    let re = axios({
      url: 'active/user/nonce',
      method: 'GET',
      isLetUrl: 1,
      data: {
        address: user
      }
    })
    re.then(async (res) => {
      const signature = await walletClient.signMessage({
        account: user,
        message: res.content
      })
      goSignLogin(signature, res.content )
    })
  })


  const goSignLogin = ((signcode,nonce)=>{
    let that = this
    let re = axios({
      url: 'active/user/login',
      method: 'POST',
      isLetUrl: 1,
      data: {
        address: user,
        addressChain: linkData[linktype.toString()].xm,
        sign: signcode,
        nonce: nonce
      }
    })
    re.then(async (res) => {
      dispatch({ type: 'SETSIGNTOKEN', signtoken: res.content.token})
      setshowsigntoken(false)
      localStorage.setItem(dayjs().format('YYYY-MM-DD') + 'LOGIN' + user,'ok')
    })
  })

  const goulougout = (()=>{
    dispatch({ type: 'SETSIGNTOKEN', signtoken: null})
  })


  return (
    <div className="topbox">
      <img className='logo' src={toplogo} onClick={() => goPageTop()} />
      <div className='tipssss'>{newlocal['topMode']['p7'][language]}</div>
      <ul className='nav'>
        <li className={locationUse.pathname === '/' ? 'item active' : 'item'} onClick={() => goPage1()}>{newlocal["topMode"]["nav1"][language]}</li>
        <li className={locationUse.pathname === '/pools' ? 'item active' : 'item'} onClick={() => goPage2()}>{newlocal["topMode"]["nav2"][language]}</li>
        <li className={locationUse.pathname === '/earn' ? 'item active' : 'item'} onClick={() => goPage6()}>{newlocal["topMode"]["nav21"][language]}</li>
        <li className={locationUse.pathname === '/dashboard' ? 'item active' : 'item'} onClick={() => goPage4()}>{newlocal["topMode"]["nav4"][language]}</li>
        <li className={locationUse.pathname === '/referrals' ? 'item active' : 'item'} onClick={() => goPage7()}>{newlocal["topMode"]["nav6"][language]}</li>
        <li className={locationUse.pathname === '/airdrop' ? 'item active' : 'item'} onClick={() => goPage3()}>{newlocal["topMode"]["nav3"][language]}</li>
        <li className={'item'} onClick={() => goPage5()}>{newlocal["topMode"]["nav5"][language]}</li>
      </ul>
      <div className='actionbox'>


        {
          inter ? <div className='interbox'>
            <span className='t'></span>
            <Popover content={<div className='popbox'>
              <pre className='popname'>{newlocal['topMode']['p102'][language]}</pre>
            </div>} trigger="hover">
              <div>{newlocal['topMode']['p101'][language]}</div></Popover>
          </div> : <div className='interbox'>
            <span className='h'></span>
            <Popover content={<div className='popbox'>
              <pre className='popname'>{newlocal['topMode']['p103'][language]}</pre>
            </div>} trigger="hover">
              <div>{newlocal['topMode']['p101'][language]}</div></Popover>
          </div>
        }
        {(user == null || user == false) ? <div className='btnLogin' onClick={() => setlinkgash(true)}><img className='ico' src={linkGash} />{newlocal["topMode"]["btn2"][language]}</div> : <div className='btnLogin' onClick={() => setshowuser(true)}><img className='ico' src={linkGash} />{user.substring(0, 4) + '...' + user.substring(user.length - 4, user.length)}</div>}
        
        
        <div className='btnLogin2' onClick={() => setlink(true)}>
          {linktype === 1 && <img className='ico' src={arb} />}
          {linktype === 2 && <img className='ico' src={bsc} />}
          {linktype === 3 && <img className='ico' src={base} />}
          <div className='line'></div>
          <img className='ico2' src={h5menunew} />
        </div>

        {
          ys && <div className='langboxmask'>
            <div className='langBox' >
              <div className='title'>{newlocal["topMode"]["boxtitle1"][language]}</div>
              <img className='closeico' src={close} onClick={() => setys(false)}></img>
              <div className={language === 'en' ? 'langitem active' : 'langitem'} onClick={() => handleChange('en')}>English</div>
              <div className={language === 'zhcn' ? 'langitem active' : 'langitem'} onClick={() => handleChange('zhcn')}>繁體中文</div>
            </div>
          </div>
        }
        {
          linkgash && <div className='langboxmask'>
            {
              linkTz && <div className='infobox' onClick={() => closett()}>
                <p className='infop' onClick={() => connect1()}>{linkmsg}</p>
                {
                  downloadlink1 && <p className='infop' style={{ textDecoration: 'underline' }} onClick={() => openttt('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn')}>{'download'}</p>
                }
                {
                  downloadlink2 && <p className='infop' style={{ textDecoration: 'underline' }} onClick={() => openttt('https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?utm_source=ext_app_menu')}>{'download'}</p>
                }
                {
                  downloadlink3 && <p className='infop' style={{ textDecoration: 'underline' }} onClick={() => openttt('https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge?utm_source=ext_app_menu')}>{'download'}</p>
                }
              </div>
            }

            <div className='langBox' >
              <div className='title'>{newlocal["topMode"]["btn2"][language]}</div>
              <div className='info'>{newlocal["topMode"]["boxinfo1"][language]}</div>
              <img className='closeico' src={close} onClick={() => { setlinkgash(false); setlinkTz(false); setlinkmsg('') }}></img>
              {
                connectors[0].ready && <div className='langitemIco' onClick={() => { connect({ connector: connectors[0] }); setlinkgash(false); setlinkTz(false); setlinkmsg('') }} ><img className='btnico' src={metamask} /><div>MetaMask</div></div>

              }
              {
                connectors[1].ready && <div className='langitemIco' onClick={() => { connect({ connector: connectors[1] }); setlinkgash(false); setlinkTz(false); setlinkmsg('') }} ><img className='btnico' src={CoinbaseWallet} /><div>Coinbase Wallet</div></div>

              }
              {
                connectors[2].ready && <div className='langitemIco' onClick={() => { connect({ connector: connectors[2] }); setlinkgash(false); setlinkTz(false); setlinkmsg('') }} ><img className='btnico' src={walletconnect} /><div>WalletConnect</div></div>

              }
              {/* {
                connectors[3].ready && <div className='langitemIco' onClick={() => { connect({ connector: connectors[3] }); setlinkgash(false); setlinkTz(false); setlinkmsg('') }} ><img className='btnico' src={OKXWallet} /><div>OKX Wallet</div></div>
              } */}
            </div>
          </div>
        }
        {
          link && <div className='langboxmasknew' onClick={(e)=>{e && e.stopPropagation(); setlink(false) }}>
            <div className='tmbox' onClick={(e)=>{e && e.stopPropagation(); setlink(false) }}></div>
            <div className='langBox langBox1' >
              <div className='title'>{newlocal["topMode"]["boxtitle2"][language]}</div>
              <div className={linktype === 3 ? 'langitemIco active' : 'langitemIco'} onClick={() => changlinktype(3)} ><img className='btnico' src={base} /><div className='ttt'>BASE</div></div>
              <div className={linktype === 2 ? 'langitemIco active' : 'langitemIco'} onClick={() => changlinktype(2)} ><img className='btnico' src={bsc} /><div className='ttt'>BNB Chain</div></div>
              {/* <div className={linktype === 1 ? 'langitemIco active' : 'langitemIco'} onClick={() => changlinktype(1)} ><img className='btnico' src={arb} /><div className='ttt'>Arbitrum Goerli</div></div> */}
              <div className='langitmelanghu' onClick={() => {setys(true); setlink(false) }}>
              <img className='btnico' src={checkYs} />
              <div className='ttt'>{newlocal["topMode"]["boxtitle1"][language]}</div>
              </div>
            </div>
          </div>
        }


        {
          showqhid && <div className='langboxmask'>
            <div className='infobox' style={{ cursor: 'pointer' }} onClick={() => connect1()}>
              <p className='infop'>{linkmsg}<span style={{ textDecoration: 'underline' }}>{linkmsg2}</span></p>
            </div>
          </div>
        }

        {
          showuser && <div className='langboxmask'>
            <div className='langBox' >
              <img className='closeico' src={close} onClick={() => setshowuser(false)}></img>
              {(user == null || user == false) ? '' : <div className='info1'>{user.substring(0, 4) + '...' + user.substring(user.length - 4, user.length)}</div>}
              <div data-clipboard-text={user} className={'langitem2 copy-c'}>{newlocal['topMode']['p4'][language]}</div>
              <div className={'langitem2'} onClick={() => openLINK()}>{newlocal['topMode']['p5'][language]}</div>
              <div className={'langitem2'} onClick={() => closelink()}>{newlocal['topMode']['p6'][language]}</div>
            </div>
          </div>
        }

        {
          showsigntoken && <div className='langboxmask'>
            <div className='langBox' >
              <div className='title'>{newlocal['topMode']['p1041'][language]}</div>
              <img className='closeico' src={close} onClick={() => setshowsigntoken(false)}></img>
              <div className='info'>{newlocal['topMode']['p104'][language]}</div>
              <div className='btn' onClick={() => gosigin()}>
                <span className='t'>{newlocal['topMode']['p105'][language]}</span>
              </div>
            </div>
          </div>
        }

      </div>
    </div>
  );
}


export default NewTopMode;
