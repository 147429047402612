import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import {persistStore, persistReducer} from 'redux-persist'
import rootReducer from '../reducers'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import counter from '../reducers/counter'

const persistConfig = {
  key: 'bbzPrd20231028',
  storage: storage,
  stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};


const composeEnhancers = compose
  // typeof window === 'object' &&
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //     // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  //   }) : compose

const middlewares = [
  thunkMiddleware
]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(require('redux-logger').createLogger())
}

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  // other store enhancers if any
)

const myPersistReducer = persistReducer(persistConfig, counter)

const store = createStore(myPersistReducer)

export const persistor = persistStore(store)

export const storeY = store

export default function configStore () {

  return store
}
