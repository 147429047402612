
  const testApi = {
    path: 'https://inapitest.bbz.com/traderapi/',
    wss:  'wss://tradertest.bbz.com/wss/'
  }
  
  const prodApi = {
    // path: 'https://inapitest.bbz.com/traderapi/',
    path: 'https://inapitest.bbz.com/traderapibnb/',
    wss:  'wss://tradertest.bbz.com/wss/'
  }
  
  let Api = testApi
  
  if (process.env.NODE_ENV === 'production') {
    Api = prodApi
  } else {
    Api = prodApi
  }
  
  export default Api
  