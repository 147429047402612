import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import configStore from './store'
import { persistor } from './store'
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { arbitrumGoerli,bsc,base} from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'



export const walletConnectProjectId = '879af82239ae17283f42ae72abda6fc4'


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrumGoerli,bsc,base],
  [publicProvider()],
)

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'BBZ',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: walletConnectProjectId,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'OKX Wallet',
        getProvider: () =>
          typeof window !== 'undefined' ? window.isOKExWallet : undefined,
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
})


const store = configStore();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WagmiConfig config={config}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </WagmiConfig>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
