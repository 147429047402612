import Axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
import emitter from './emit';
import Api from './api';
import linkData from './linkTypeData'

const key = 'loadinddsg';
const axios = Axios.create();
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.interceptors.request.use((config) => {
    let bbz = localStorage.getItem('persist:bbzPrd20231028')
    let language = ''
    let linktype = ''
    if (bbz)  {
        bbz = JSON.parse(bbz)
        linktype = bbz.linktype
        if (bbz.language) {
            language = JSON.parse(bbz.language)
        }  else {
            language = 'zhcn'
        }
    } else {
        linktype = '3'
        language = 'zhcn'
    }
    let str = ''
    switch (language) {
        case 'zhcn': str = '正在加載...';break;
        case 'en': str = 'Loading...';break;
        default: break;
    }
    if (config.url === 'user/sendEmail' || config.url === 'data/prices' || config.url.indexOf('data/price') === 0 || config.url === 'config/intervals' || config.url === 'pool/collect/poolList' || config.isnotlog) {
    } else {
    }
    if (config.isLetUrl) {
        config.url = linkData[config.isLetUrl.toString()].api + config.url
    } else {
        config.url = linkData[linktype.toString()].api + config.url
    }
    if (config.method === 'post') {
        config.data = qs.stringify(config.data);
    }
    if (language === 'zhcn') {
        language = 'tw'
    }
    config.headers.locale = language
    if (config.method === 'get') {
        config.params = config.data
    }
    return config;
});
// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            let res = response.data;
            //请求状态判断
            if (res.success === "1") {
                return res
            } else if (res.success === "000032") {
                return res
            } else if(res.success === "111111"){
                emitter.emit("loginout","Hello")
                message.warning({
                    content: res.message,
                    duration: 1,
                    key: 'wa42144125rning'
                })
            }else if(res.success === "02"){
                return res
            } else if(res.message) {
                message.warning({
                    content: res.message,
                    duration: 1,
                    key: 'war525252167ning1'
                })
                return Promise.reject(response.data);
            }

        } else {
            return Promise.reject(response.data);
        }
    },
    (error) => {
        if (error.response) {
            if  ( error.response.status === 401) {

            } else {
                message.error({
                    content: error.response.data.msg ? error.response.data.msg : error.response.statusText,
                    duration: 1,
                    key: 'errsafsfor'
                })
            }
        } else {
            message.error({
                content: error.message,
                duration: 1,
                key: 'errsafsforddgg'
            })
        }
        // Do something with response error
        return Promise.reject(error);
    }
);
export default axios;