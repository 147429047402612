

// let linkData = {
//   '1': {
//     api: 'https://inapitest.bbz.com/traderapi/', //API URL
//     gcaddress: '0x8CD7D485F4dF1764c8616ED194798Ef224686076', // 工厂合约
//     jgaddress: '0x55A8ED56006D1c5B7B7181a98Ea9D096cFD6fa08', // 价格合约
//     rpcaddress: 'https://endpoints.omniatech.io/v1/arbitrum/goerli/public', // 链地址
//     lid: '0x66eed', // 链ID
//     name: 'Arbitrum Goerli',
//     jn: 'Arbitrum Goerli',
//     xm: 'arb',
//     cklink: 'https://goerli.arbiscan.io/address/',
//     txklink: 'https://goerli.arbiscan.io/tx/',
//     lbaddresstbbz: '0x0E3eb5F15AfB55871ea272b095De41b8683c4dbb', //领币地址 tbbz
//     lbaddresstusdt: '0xF1ce18cAD6A68D2d51AACf62Cee388ceaD9215E9', //领币地址 tusdt
//     jladdress: '0x76c20248b5c02337D06d34373B1580CbB0AE1e8A', //奖励池
//     invaddress: '0x8F6383C40f93578f26a618F10b8cC8d39ED68117' //邀请合约
//   },
//   '2': {
//     api: 'https://inapitest.bbz.com/traderapibnb/',
//     gcaddress: '0xE254725883B8ed1532B4Cc7CE19BAB3dEf5E2699',
//     jgaddress: '0x4DA6b3c5736AAebe6aB59B67e44946472Ac5d695',
//     rpcaddress: 'https://data-seed-prebsc-1-s1.binance.org:8545',
//     lid: '0x61',
//     name: 'BNB Chain',
//     jn: 'BNB Chain',
//     xm: 'bnb',
//     cklink: 'https://testnet.bscscan.com/address/',
//     txklink: 'https://testnet.bscscan.com/tx/',
//     lbaddresstbbz: '0xeA0bBea33c3dBA6eb94997AFF7a1eEe32BBeA46b',
//     lbaddresstusdt: '0x8B0188eA538898AA91e55F7822ca049d4DA66db0',
//     jladdress: '0x992277968B9c8eb41db8489a3F8892a51e655Cea', //奖励池
//     invaddress: '0x8F6383C40f93578f26a618F10b8cC8d39ED68117' //邀请合约
//   },
//   '3': {
//     api: 'https://inapitest.bbz.com/traderapibase/',
//     gcaddress: '0x75206972d81bCb48BE6b9422910661867959e356',
//     jgaddress: '0xF5C56670ED1FF0c8eEceeaDaaC3e328dc2b4432d',
//     rpcaddress: 'https://goerli.base.org',
//     lid: '0x14a33',
//     name: 'Base',
//     jn: 'Base',
//     xm: 'base',
//     cklink: 'https://goerli.basescan.org/address/',
//     txklink: 'https://goerli.basescan.org/tx/',
//     lbaddresstbbz: '0xE87430107689f8d36c6D92007893034E0Fc63F85',
//     lbaddresstusdt: '0x01f012510f1F61e3596Ad075F1351D0272a875cc',
//     jladdress: '0xD871875F46FF95410d01b66a777e6f349017A2cD', //奖励池
//     invaddress: '0x86460642D51e4378652964Ed7C8Dad31f06f1a09'//邀请合约
//   }
// }




let linkData = {
  '1': {
    api: 'https://apitest.bbz.com/traderapi/', //API URL
    gcaddress: '0x8CD7D485F4dF1764c8616ED194798Ef224686076', // 工厂合约
    jgaddress: '0x55A8ED56006D1c5B7B7181a98Ea9D096cFD6fa08', // 价格合约
    rpcaddress: 'https://endpoints.omniatech.io/v1/arbitrum/goerli/public', // 链地址
    lid: '0x66eed', // 链ID
    name: 'Arbitrum Goerli',
    jn: 'Arbitrum Goerli',
    xm: 'arb',
    cklink: 'https://goerli.arbiscan.io/address/',
    txklink: 'https://goerli.arbiscan.io/tx/',
    lbaddresstbbz: '0x0E3eb5F15AfB55871ea272b095De41b8683c4dbb', //领币地址 tbbz
    lbaddresstusdt: '0xF1ce18cAD6A68D2d51AACf62Cee388ceaD9215E9', //领币地址 tusdt
    jladdress: '0x76c20248b5c02337D06d34373B1580CbB0AE1e8A', //奖励池
    invaddress: '0x8F6383C40f93578f26a618F10b8cC8d39ED68117' //邀请合约
  },
  '2': {
    api: 'https://apitest.bbz.com/traderapibnb/',
    gcaddress: '0x67dab622767F79bd90e92316caF6E6928B6C7Bbb',
    jgaddress: '0xff62E45C04ecf88cF8Fc2Cd9170AE34AAcE8a38B',
    rpcaddress: 'https://rpc.ankr.com/bsc',
    lid: '0x38',
    name: 'BNB Chain',
    jn: 'BNB Chain',
    xm: 'bnb',
    cklink: 'https://bscscan.com/address/',
    txklink: 'https://bscscan.com/tx/',
    lbaddresstbbz: '0xf010E3F35feA92154a04855b9675D35d871aff3b',
    lbaddresstusdt: '0x86460642D51e4378652964Ed7C8Dad31f06f1a09',
    jladdress: '0x98D264926d61918Ea775161a29c086fd962e9366', //奖励池
    invaddress: '0x01f012510f1F61e3596Ad075F1351D0272a875cc' //邀请合约
  },
  '3': {
    api: 'https://apitest.bbz.com/traderapibase/',
    gcaddress: '0xFcBA047Bd3dC2402b38B549e35a4051e7922FA68',
    jgaddress: '0xFD43979d9a9d421Ba600fD150CE1E540ADC4e1e1',
    rpcaddress: 'https://developer-access-mainnet.base.org',
    lid: '0x2105',
    name: 'Base',
    jn: 'Base',
    xm: 'base',
    cklink: 'https://basescan.org/address/',
    txklink: 'https://basescan.org/tx/',
    lbaddresstbbz: '0x2D566b4e20F7FE9d0aE1A2Ea99F2ACAfc3E969f2',
    lbaddresstusdt: '0x86460642D51e4378652964Ed7C8Dad31f06f1a09',
    jladdress: '0x37f3EABfAA97bc3E8184fBaB0BD8636e6f55ec7E', //奖励池
    invaddress: '0x01f012510f1F61e3596Ad075F1351D0272a875cc'//邀请合约
  }
}

export default linkData
