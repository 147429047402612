import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover, Avatar } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import newlocal from '../../unilt/newlocal'
import emitter from '../../unilt/emit';
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import { useNavigate, useLocation, useParams, useSearchParams, useRouteError } from "react-router-dom";
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import axios from '../../unilt/axios';
import boxbgh5 from '../../static/h5/boxbgh5.png'
import lbox from '../../static/h5/lbox.png'
import zbox from '../../static/h5/zbox.png'
import cbox from '../../static/h5/cbox.png'
import close from '../../static/close.png'

import rank1 from '../../static/h5/rank1.png'
import rank2 from '../../static/h5/rank2.png'
import rank3 from '../../static/h5/rank3.png'



var Web3 = require('web3')
let lbTime = 0

const jsonrc1 = [
  {
    "type": "function",
    "name": "mint",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getResidueAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getTotalAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getAlreadyMintAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
]

const jsonrc2 = [
  {
    "type": "function",
    "name": "createPool",
    "inputs": [{ "name": "collateralToken", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "queryPoolAddress",
    "inputs": [{ "name": "collateralToken", "type": "address" }],
    "outputs": [{ "name": "address", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


let provider = false
let islock = false
let czTime = 0
let userlistlength = 0

function Box() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  const nprovider = useSelector(state => state.nprovider)
  const [pageInfo, setpageInfo] = useState(false)
  const renderuserRef = useRef(true)
  const signtoken = useSelector(state => state.signtoken)
  const [getParams, setParam] = useSearchParams()
  const [amount, setamount] = useState(0)

  const [bboxn, setbboxn] = useState(0)
  const [zboxn, setzboxn] = useState(0)
  const [cboxn, setcboxn] = useState(0)

  let [show2, setshow2] = useState(false)
  let [cvaleu1, setcvaleu1] = useState('')
  let [cvaleu2, setcvaleu2] = useState(200)
  let [cvaleu3, setcvaleu3] = useState(7500)
  let [cvaleu4, setcvaleu4] = useState(500)
  let [rank, setrank] = useState([])


  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false
      if (user === false || user === null || user === 'null') {

      } else {
        getB()
        getRankList()
        getdbstart()
      }
      islock = true
      return
    }
  }, [])

  useEffect(() => {
    provider = nprovider
  }, [nprovider])


  const checkChainId = (async () => {
    let chainId = `0x${chain.id.toString(16)}`

    if (chainId !== linkData[linktype.toString()].lid) {
      emitter.emit("checkcid", "Hello")
      return false
    } else {
      return true
    }
  })

  const getB = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].lbaddresstusdt;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getResidueAmount().call()
    setamount(Number(res) / (10 ** 18))
  })

  const getRankList = (() => {
    let re = axios({
      url: 'active/box/ranking',
      method: 'GET',
      isLetUrl: 1,
    })
    re.then(async (res) => {
      let list = res.content
      let newlist = list.splice(0, 5)
      setrank(newlist)
    })
  })


  useEffect(() => {
    if (renderuserRef.current) {
      renderuserRef.current = false
    } else {
      if (user === false || user === null || user === 'null') {

      } else {
        if (islock) {
          getB()
          getRankList()
          getdbstart()
        }
      }
    }

  }, [user])


  const getdbstart = (() => {
    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }
    if (user === false || user === null || user === 'null') {

    } else {

      let str = ''
      switch (language) {
        case 'zhcn': str = '正在加載...'; break;
        case 'en': str = 'Loading...'; break;
        default: break;
      }
      message.loading({ content: str, key: 'czlodaingg', duration: 0 });
      getuserinfo(true)
    }
  })

  useEffect(() => {
    getB()
    getRankList()
    if (signtoken === false || signtoken === null || signtoken === 'null') {

    } else {
      getdbstart()
    }

  }, [signtoken])

  const getuserinfo = (async (ispd = false) => {
    let that = this
    let re = axios({
      url: 'active/user/info',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setpageInfo(res.content)
        message.destroy('czlodaingg')
        getbbox()
        getzbox()
        getcbox()
      } else {
        message.destroy('czlodaingg')
      }

    })
  })


  const getbbox = (() => {
    let re = axios({
      url: 'active/box/select',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        boxType: 'blue'
      }
    })
    re.then(async (res) => {
      let list = res.content
      setbboxn(list.length)
    })
  })


  const getzbox = (() => {
    let re = axios({
      url: 'active/box/select',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        boxType: 'purple'
      }
    })
    re.then(async (res) => {
      let list = res.content
      setzboxn(list.length)
    })
  })

  const getcbox = (() => {
    let re = axios({
      url: 'active/box/select',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        boxType: 'orange'
      }
    })
    re.then(async (res) => {
      let list = res.content
      setcboxn(list.length)
    })
  })


  const goPage1 = (() => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate('/referrals')
  })

  const goPage2 = (() => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate(`/?type=${1}`)
  })


  const goPage3 = (() => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate(`/?type=${2}`)
  })

  const goPage4 = (() => {
    setshow2(true)
  })

  const goPage5 = (() => {
    document.getElementsByClassName("App")[0].scrollTop = 0
    navigate(`/?type=${1}`)
  })


  const goPage6 = (() => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }
  })




  const creat = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    let ntime = dayjs().unix()
    if (ntime - czTime <= 2) {
      return false
    } else {
      czTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    if (cvaleu1 === '') {
      message.error({ content: newlocal['error']['p23'][language], key: 'trangerror', duration: 3 })
      return false
    } else {
      try {
        let code = await web3.eth.getCode(cvaleu1)
        if (code === '0x') {
          message.error({ content: newlocal['error']['p27'][language], key: 'trangerror', duration: 3 })
          return false
        } else {

        }
      } catch {
        message.error({ content: newlocal['error']['p27'][language], key: 'trangerror', duration: 3 })
        return false
      }
    }

    if (cvaleu4 === '') {
      message.error({ content: newlocal['error']['p24'][language], key: 'trangerror', duration: 3 })
      return false
    }
    if (Number(cvaleu4) > 10000) {
      message.error({ content: newlocal['error']['p25'][language], key: 'trangerror', duration: 3 })
      return false
    }

    let csflag = false
    let isres = false

    var contractAddress = linkData[linktype.toString()].gcaddress;


    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc2,
        functionName: 'createPool',
        account: user,
        args: [cvaleu1, cvaleu2, cvaleu3, cvaleu4]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    userlistlength = 0
    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc2,
            functionName: 'createPool',
            account: user,
            args: [cvaleu1, cvaleu2, cvaleu3, cvaleu4],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 1, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const getUsdc1 = (async () => {
    try {
      let ntime = dayjs().unix()
      if (ntime - lbTime <= 2) {
        return false
      } else {
        lbTime = ntime
      }
      if (user === false || user === null || user === 'null') {
        emitter.emit("hiddentc", "Hello")
        return false
      }
      let p = await checkChainId()
      if (p) {

      } else {
        return false
      }
      let csflag = false
      let isres = false

      var contractAddress = linkData[linktype.toString()].lbaddresstusdt;

      message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
      let gaslimit = 1000000
      try {
        gaslimit = await publicClient.estimateContractGas({
          address: contractAddress,
          abi: jsonrc1,
          functionName: 'mint',
          account: user,
        })
      } catch (e) {
        message.destroy('trange')

        goMessor(e)
        return false
      }


      const gasPrice = await publicClient.getGasPrice()
      const nonce = await publicClient.getTransactionCount({ address: user });

      await Promise.race([
        setTimeout(async () => {
          let srequest = false
          try {
            const { request } = await publicClient.simulateContract({
              address: contractAddress,
              abi: jsonrc1,
              functionName: 'mint',
              account: user,
              gas: gaslimit,
              gasPrice: gasPrice,
              nonce: nonce
            })
            isres = true
            srequest = request
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
            return false
          }
          try {
            const hash = await walletClient.writeContract(srequest)
            isres = true
            let errortime = Number(dayjs().add(20, 'second').unix())
            getlistenJy(hash, 10, errortime)
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
          }
        }, 500),
        new Promise(function (resolve, reject) {
          setTimeout(function () {
            message.destroy('trange')
            if (!csflag && !isres) {
              message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
              csflag = true
            }
          }, 30000);
        })
      ]);
    } catch (error) {
    }

  })


  const getTypeLength = (async (result, index, errortime) => {
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      userlistlength = resp.content.length
      getlistenJy(result, index, errortime)
    })
  })


  const getlistenJy = (async (result, type, errortime) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var receipt = await web3.eth.getTransactionReceipt(result)
    let nowtime = Number(dayjs().unix())
    if (nowtime > errortime) {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    } else if (receipt === null) {
      getlistenJy(result, type, errortime)
    } else if (receipt.status === true) {
      message.destroy('trange')
      if (type === 1) {
        message.success({ content: <div>{newlocal['main']['p80'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow2(false)
      }
      if (type === 10) {
        message.success({ content: <div>{newlocal['main']['p81'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        getB()
        getdbstart()
      }
    } else {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }
  })

  const gojypage = ((link) => {
    window.open(`${linkData[linktype.toString()].txklink}${link}`)

  })


  const goMessor = ((error) => {
    let msg = error.message
    console.error(msg)
    if (msg.indexOf('insufficient allowance') >= 0) {
      message.error({ content: newlocal['error']['p1'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('transfer amount exceeds balance') >= 0) {
      message.error({ content: newlocal['error']['p2'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('withdraw amount too much!') >= 0) {
      message.error({ content: newlocal['error']['p3'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('marketId not exist') >= 0) {
      message.error({ content: newlocal['error']['p4'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('intervalId not exist') >= 0) {
      message.error({ content: newlocal['error']['p5'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('total Order Amount is too large') >= 0) {
      message.error({ content: newlocal['error']['p6'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too large') >= 0) {
      message.error({ content: newlocal['error']['p7'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too small') >= 0) {
      message.error({ content: newlocal['error']['p8'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('too many remaining orders') >= 0) {
      message.error({ content: newlocal['error']['p9'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('have the same order') >= 0) {
      message.error({ content: newlocal['error']['p10'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('execution fee is too low') >= 0) {
      message.error({ content: newlocal['error']['p11'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p12'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is not ready') >= 0) {
      message.error({ content: newlocal['error']['p13'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p14'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order running') >= 0) {
      message.error({ content: newlocal['error']['p15'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is ready') >= 0) {
      message.error({ content: newlocal['error']['p16'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('reward amount is zero') >= 0) {
      message.error({ content: newlocal['error']['p17'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('getRewards approve error') >= 0) {
      message.error({ content: newlocal['error']['p18'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('ZERO_ADDRESS!') >= 0) {
      message.error({ content: newlocal['error']['p19'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('not support') >= 0) {
      message.error({ content: newlocal['error']['p20'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('factoryConfig is not initialize') >= 0) {
      message.error({ content: newlocal['error']['p21'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('CREATE2_FAILED') >= 0) {
      message.error({ content: newlocal['error']['p22'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('The total cost (gas * gas fee + value) of executing this transa') >= 0) {
      message.error({ content: newlocal['error']['p29'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('User denied transaction signature') >= 0) {
      message.error({ content: newlocal['main']['p70'][language], key: 'trangerrorss', duration: 3 })
    } else {
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
    }
  })


  const goOpenBox = ((type) => {
    navigate(`/openbox?type=${type}`)
  })

  const myscores = (() => {
    navigate(`/myscores`)
  })


  const goTarand = (() => {
    navigate(`/`)
  })
  
  const goRank = (() => {
    navigate(`/rank`)
  })

  return (
    <div className="boxpageh5" >
      <div className='topbg'>
        <pre className='bgtitle'>{newlocal['box']['p43'][language]}</pre>
        <img className='bgimg' src={boxbgh5} ></img>
      </div>
      <div className='contbox'>
        <div className='boxbox'>
          <div className='topcontbox1'>
            <div className='tipbox'>
              <div className='tp1'>{newlocal['box']['p1'][language]}</div>
              <div className='tp2'>{(bboxn + zboxn + cboxn).toLocaleString('EN-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
            </div>
            <div className='tipbox' onClick={() => myscores()}>
              <div className='tp1'>{newlocal['box']['p2'][language]}</div>
              <div className='tp2'>{pageInfo ? pageInfo.integralAmount.toLocaleString('EN-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</div>
            </div>
          </div>
          <div className='boxbox2'>
            <div className='boxnumbox' onClick={() => goOpenBox('blue')}>
              <img className='boxico' src={lbox} />
              <div className='boxnum'>{bboxn}</div>
            </div>
            <div className='boxnumbox' onClick={() => goOpenBox('purple')}>
              <img className='boxico' src={zbox} />
              <div className='boxnum'>{zboxn}</div>
            </div>
            <div className='boxnumbox' onClick={() => goOpenBox('orange')}>
              <img className='boxico' src={cbox} />
              <div className='boxnum'>{cboxn}</div>
            </div>
          </div>
        </div>


        <div className='boxbox'>
          <div className='topcontbox2'>
            <div className='title'>{newlocal['box']['p42'][language]}</div>
            <pre className='info2'>{newlocal['lb']['p24'][language]}</pre>

            {
              amount === 0 ? <div className='btn' onClick={() => goTarand()}>{`${newlocal['lb']['p102'][language]}`} </div>
                :
                <div className='btn' onClick={() => getUsdc1()}>{`${newlocal['lb']['p101'][language]} ${amount} TUSDC`} </div>
            }

          </div>

        </div>

      </div>

      <div className='rankbox'>
      <div className='titlebox'>
            <div className='p1'>{newlocal['rank']['p1'][language]}</div>
            <div className='p2' onClick={()=>goRank()} >{newlocal['rank']['p2'][language]}</div>
          </div>
          <div className='headerbox'>
            <div className='item1'>{newlocal['rank']['p3'][language]}</div>
            <div className='item2'>{newlocal['rank']['p4'][language]}</div>
            <div className='item3'>{newlocal['rank']['p5'][language]}</div>
          </div>
          {
            rank.map((item,index)=>{
              return  <div className='itembox'>
              <div className='item1'><div className='icobox'>{index === 0 && <img className='rankico' src={rank1}></img>}{index === 1 && <img className='rankico' src={rank2}></img>}{index === 2 && <img className='rankico' src={rank3}></img>}</div>{index + 1} </div>
              <div className='item2'>{item.address.substring(0, 7) + '...' + item.address.substring(item.address.length - 7, item.address.length)}</div>
              <div className='item3'>{Number(item.boxNum).toLocaleString('EN-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
            </div>
            })
          }
      </div>


      <div className='contbox2'>
        <div className='topcontbox'>
          <div className='title'>{newlocal['box']['p9'][language]}</div>
        </div>
        <div className='actionbox'>
          <div className='bl'>
            <div className='blp1' >{newlocal['box']['p6'][language]}</div>
            <div className='blp2'>{newlocal['box']['p7'][language]}</div>
          </div>
          <div className='btn3' onClick={() => goPage1()}>{newlocal['box']['p8'][language]}</div>
        </div>
        <div className='actionbox'>
          <div className='bl'>
            <div className='blp1' >{newlocal['box']['p10'][language]}</div>
            <div className='blp2'>{newlocal['box']['p11'][language]}</div>
          </div>
          <div className='btn' onClick={() => goPage2()}>{newlocal['box']['p12'][language]}</div>
        </div>
        <div className='actionbox'>
          <div className='bl'>
            <div className='blp1' >{newlocal['box']['p13'][language]}</div>
            <div className='blp2'>{newlocal['box']['p14'][language]}</div>
          </div>
          <div className='btn' onClick={() => goPage3()}>{newlocal['box']['p15'][language]}</div>
        </div>
        <div className='actionbox'>
          <div className='bl'>
            <div className='blp1' >{newlocal['box']['p16'][language]}</div>
            <div className='blp2'>{newlocal['box']['p17'][language]}</div>
          </div>
          <div className='btn' onClick={() => goPage4()}>{newlocal['box']['p18'][language]}</div>
        </div>
        <div className='actionbox'>
          <div className='bl'>
            <div className='blp1' >{newlocal['box']['p19'][language]}</div>
            <div className='blp2'>{newlocal['box']['p20'][language]}</div>
          </div>
          <div className='btn' onClick={() => goPage5()}>{newlocal['box']['p21'][language]}</div>
        </div>
        <div className='actionbox'>
          <div className='bl'>
            <div className='blp1' >{newlocal['box']['p22'][language]}</div>
            <div className='blp2'>{newlocal['box']['p23'][language]}</div>
          </div>
          {
            localStorage.getItem(dayjs().format('YYYY-MM-DD') + 'LOGIN' + user) === 'ok' ? <div className='btn2' >{newlocal['box']['p241'][language]}</div> : <div className='btn' onClick={() => goPage6()}>{newlocal['box']['p24'][language]}</div>
          }
        </div>
      </div>

      {
        show2 && <div className='langboxmask'>
          <div className='langBox langBox1' style={{ width: '324PX' }}>
            <div className='title'>{newlocal['creatpool']['p1'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow2(false)}></img>
            <div className='craetbox'>
              <div className='p1'>{newlocal['creatpool']['p2'][language]}</div>
              <input value={cvaleu1} className='input1' placeholder={newlocal['textTip']['p1'][language]} onChange={(e) => { setcvaleu1(e.currentTarget.value.toLowerCase()) }}></input>
              <div className='p1'>{newlocal['creatpool']['p3'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu2(200)} className={cvaleu2 === 200 ? 'changeitem changeitemacitve' : 'changeitem'}>2%</div>
                <div onClick={() => setcvaleu2(500)} className={cvaleu2 === 500 ? 'changeitem changeitemacitve' : 'changeitem'}>5%</div>
                <div onClick={() => setcvaleu2(1000)} className={cvaleu2 === 1000 ? 'changeitem changeitemacitve' : 'changeitem'}>10%</div>
              </div>
              <div className='p1'>{newlocal['creatpool']['p4'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu3(7500)} className={cvaleu3 === 7500 ? 'changeitem changeitemacitve' : 'changeitem'}>75%</div>
                <div onClick={() => setcvaleu3(8000)} className={cvaleu3 === 8000 ? 'changeitem changeitemacitve' : 'changeitem'}>80%</div>
                <div onClick={() => setcvaleu3(8500)} className={cvaleu3 === 8500 ? 'changeitem changeitemacitve' : 'changeitem'}>85%</div>
                <div onClick={() => setcvaleu3(9000)} className={cvaleu3 === 9000 ? 'changeitem changeitemacitve' : 'changeitem'}>90%</div>
              </div>
              <div className='p1'>{newlocal['creatpool']['p5'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu4(500)} className={cvaleu4 === 500 ? 'changeitem changeitemacitve' : 'changeitem'}>5%</div>
                <div onClick={() => setcvaleu4(1000)} className={cvaleu4 === 1000 ? 'changeitem changeitemacitve' : 'changeitem'}>10%</div>
                <div onClick={() => setcvaleu4(2000)} className={cvaleu4 === 2000 ? 'changeitem changeitemacitve' : 'changeitem'}>20%</div>
              </div>
              <div className='btn btnnew' onClick={() => creat()}><div className='t'>{newlocal['cz']['p18'][language]}</div></div>
            </div>
          </div>
        </div>
      }


    </div>
  );
}

export default Box;
