import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import api from './api'
import axios from 'axios'

import failed from '../../static/tradingview/failed.png'
import loading from '../../static/tradingview/loading.png'

let apiconfig = {
    '1test': 'https://inapitest.bbz.com/traderapi/',
    '2test': 'https://inapitest.bbz.com/traderapibnb/',
    '3test': 'https://inapitest.bbz.com/traderapibase/',
    '1prd': 'https://apitest.bbz.com/traderapi/',
    '2prd': 'https://apitest.bbz.com/traderapibnb/',
    '3prd': 'https://apitest.bbz.com/traderapibase/'
}

let str1 = {
    'en': 'Failed to obtain\nPlease check if the network is normal',
    'zh_TW': '獲取失敗\n請檢查網路是否正常'
}

let str2 = {
    'en': 'Loading...',
    'zh_TW': '正在加載...'
}

let localp = {
    lang: 'en',
    linktype: '2test',
    poolAddress: 'none',
    token: ''
}

function TVChartContainer(props) {
    const language = useSelector(state => state.language)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [symbol, setsymbol] = useState('BTCUSDT')
    const [interval, setinterval] = useState('1')
    const [container, setcontainer] = useState('tv_chart_container')
    const [datafeedUrl, setdatafeedUrl] = useState('https://demo_feed.tradingview.com')
    const [libraryPath, setlibraryPath] = useState('/charting_library/')
    const [chartsStorageUrl, setchartsStorageUrl] = useState('https://saveload.tradingview.com')
    const [chartsStorageApiVersion, setchartsStorageApiVersion] = useState('1.2')
    const [clientId, setclientId] = useState('tradingview.com')
    const [userId, setuserId] = useState('public_user_id')
    const [fullscreen, setfullscreen] = useState(false)
    const [autosize, setautosize] = useState(true)
    const [studiesOverrides, setstudiesOverrides] = useState({})
    const [interState, setinterState] = useState(1)

    const pageOneload = useRef(true)
    const tvWidget = useRef(false)
    const isReady = useRef(false)
    const slist = useRef([])



    useEffect(() => {
        if (pageOneload.current === true) {
            pageOneload.current = false;
            const widgetOptions = {
                debug: false,
                symbol: symbol,
                datafeed: api,
                interval: interval,
                container: container,
                library_path: libraryPath,
                locale: props.lang,
                disabled_features: props.stype === 'pc' ? ["create_volume_indicator_by_default"] : ["create_volume_indicator_by_default","left_toolbar", "go_to_date", "timeframes_toolbar"],
                charts_storage_url: chartsStorageUrl,
                charts_storage_api_version: chartsStorageApiVersion,
                client_id: clientId,
                user_id: userId,
                fullscreen: fullscreen,
                autosize: autosize,
                studies_overrides: studiesOverrides,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                theme: 'dark',
                overrides: {
                    'mainSeriesProperties.priceLineColor': '#fff'
                },
            }
            tvWidget.current = new window.TradingView.widget(widgetOptions)
            tvWidget.current.onChartReady(() => {
                isReady.current = true
                props.stype === 'pc' ? tvWidget.current.activeChart()._chartWidget.model().timeScale().setRightOffset(45) : tvWidget.current.activeChart()._chartWidget.model().timeScale().setRightOffset(15)
            })
            checkmothod()
            checkInter()
        }
    }, [])

    useEffect(() => {
        if (isReady.current !== false && (props.lang !== localp.lang)) {
            isReady.current = false
            tvWidget.current.remove()
            tvWidget.current = false

            const widgetOptions = {
                debug: false,
                symbol: symbol,
                datafeed: api,
                interval: interval,
                container: container,
                library_path: libraryPath,
                locale: props.lang,
                disabled_features: ['use_localstorage_for_settings'],
                charts_storage_url: chartsStorageUrl,
                charts_storage_api_version: chartsStorageApiVersion,
                client_id: clientId,
                user_id: userId,
                fullscreen: fullscreen,
                autosize: autosize,
                studies_overrides: studiesOverrides,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                disabled_features: props.stype === 'pc' ? [] : ["left_toolbar", "go_to_date", "timeframes_toolbar"],
                theme: 'dark',
                overrides: {
                    'mainSeriesProperties.priceLineColor': '#fff'
                },
            }
            tvWidget.current = new window.TradingView.widget(widgetOptions)
            tvWidget.current.onChartReady(() => {
                isReady.current = true
                tvWidget.current.activeChart()._chartWidget.model().timeScale().setRightOffset(45)
                tvWidget.current.activeChart().defaultScrollPosition()
            })
        }
        localp = props
    }, [props])

    const checkInter = (() => {
        let that = this
        axios({
            url: 'https://api.binance.com/api/v3/time',
            method: 'GET',
        }).then((res) => {
            setinterState(2)
        }).catch((error) => {
            setinterState(3)
        })
    })

    const checkmothod = (() => {
        let that = this
        setInterval(() => {
            console.log(localp)
            console.log(isReady)
            if (localp.token !== '' && localp.token !== null && isReady.current) {
                gethistoryorder()
            } else {
                let list = slist.current
                for (let i in list) {
                    list[i].remove()
                }
                slist.current = []
            }
        }, 1000)
    })

    const gethistoryorder = (() => {
        let that = this
        let apiurl = apiconfig[String(localp.linktype)]
        axios({
            url: apiurl + 'pool/existing-orders',
            method: 'GET',
            params: {
                address: localp.token,
                poolAddress: localp.poolAddress.address,
            },
        })
            .then((res) => {
                let list = res.data.content
                let g = slist.current
                for (let i in g) {
                    g[i].remove()
                }
                slist.current = []
                let newlist = []



                for (let i = 0; i < list.length; i++) {
                    let price = Number(
                        (Number(list[i].amount) + Number(list[i].fee)) / 10 ** 18
                    )
                    let flag = list[i].orderType === 0 ? false : true
                    let str = ''
                    if (localp.lang === 'en') {
                        str = flag ? 'UP' : 'DONW'
                    }
                    if (localp.lang === 'zh_TW') {
                        str = flag ? '看多' : '看空'
                    }
                    let color = flag ? '#31cb7f' : '#ff5f57'
                    let cp = Number(list[i].createPrice / 10 ** 8)
                    let mm = tvWidget.current
                        .activeChart()
                        .createPositionLine({
                            disableUndo: 1
                        })
                        .setLineColor(color)
                        .setPrice(cp)
                        .setText(`${price}`)
                        .setQuantity(str)
                        .setBodyTextColor('#fff')
                        .setQuantityTextColor('#fff')
                        .setBodyBorderColor(color)
                        .setBodyBackgroundColor(color)
                        .setQuantityBorderColor(color)
                        .setQuantityBackgroundColor(color)
                    newlist.push(mm)
                }
                slist.current = newlist
            })
            .catch((res) => console.log(res))
    })





    return (
        <div className="TVChart">
            <div className="TVChartContainer" id={container} ></div>
            {
                interState === 3 && <div className="interbox">
                    <img src={failed} className="imgg" />
                    <pre className="ppp">{str1[localp.lang]}</pre>
                </div>
            }
            {
                interState === 1 && <div className="interbox">
                    <img src={loading} className="imgg" />
                    <pre className="ppp">{str2[localp.lang]}</pre>
                </div>
            }
        </div>
    );
}

export default TVChartContainer;
