
import { useState, useEffect, useRef, useLayoutEffect, useMemo, useCallback } from 'react';
import BasicRoute from './Router/Router'
import BasicRouteH5 from './Router/RouterH5'
import { useLocation, useSearchParams } from "react-router-dom";
// import Adjust from '@adjustcom/adjust-web-sdk';
import { useSelector, useDispatch } from 'react-redux'
import NewTopMode from './components/NewTopMode';
import NewBottomMode from './components/NewBottomMode';
import NewTopModeH5 from './components/NewTopModeH5';
import NewBottomModeH5 from './components/NewBottomModeH5';
import TopAcitve from './components/TopAcitve';
import TopAcitveH5 from './components/TopAcitveH5';
import {MyHead} from './components/PostCss'
import emitter from './unilt/emit'
import './App.less';
import { useAccount } from 'wagmi'



function App() {
  const location = useLocation();
  const path = location.pathname;
  const renderRef = useRef(true)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [getParams, setParam] = useSearchParams()
  const { address } = useAccount()
  let [isLock, setLock] = useState(false)
  let [systemType, setsystemType] = useState('none')


  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight
  })

  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })

  }, [])



  const startCheckLock = (() => {
    let dom = document.getElementsByClassName('langboxmask')
    if (dom.length === 0) {
      setLock(false)
    } else {
      try {
        document.getElementsByClassName("App")[0].scrollTop = 0
      } catch {
        document.getElementsByClassName("AppH5")[0].scrollTop = 0
      }
      
      setLock(true)
    }
    setTimeout(() => {
      startCheckLock()
    }, 100)
  })


  useEffect(() => {

    if (renderRef.current) {
      renderRef.current = false

      startCheckLock()

      const lang = getParams.getAll('lang')
      const net = getParams.getAll('net')


      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setsystemType('phone')
      } else {
        setsystemType('pc')
      }



      if (net.length !== 0) {
        let ne = net[0]
        if (ne === 'arbitrum') {
          dispatch({ type: 'SETLINKTYPE', linktype: Number('1') })
        } else if (ne === 'bsc') {
          dispatch({ type: 'SETLINKTYPE', linktype: Number('2') })
        } else if (ne === 'base') {
          dispatch({ type: 'SETLINKTYPE', linktype: Number('3') })
        }
      }
      if (lang.length !== 0) {
        let langs = lang[0]
        if (langs !== 'zhcn') {
          langs = 'en'
        }
        dispatch({ type: 'SETLANGUAGE', language: langs })
      }
      async function fetchData() {
        if (address === undefined) {
          dispatch({ type: 'SETUSER', user: null })
          localStorage.removeItem('hidden')
        } else {
          dispatch({ type: 'SETUSER', user: address })
        }
      }
      fetchData();
    }

    window.addEventListener('resize', onResize);
    return (() => {
      window.removeEventListener('resize', onResize)
    })

  }, []);





  return (
    <div>
      {
        systemType === 'pc' && <div className="App" style={{ overflow: isLock ? 'hidden' : 'auto', transformOrigin: 'left top', transform: `scale(${size.width >= 1430 ? 1 : (size.width) / 1430})`, minHeight: size.width >= 1430 ? '100vh' : 143000 / (size.width) + 'vh', flex: size.width }}>
          <div className='app-content'>
            <TopAcitve />
            <NewTopMode />
            {/* <div style={{ height: '144px' }}></div> */}
            <BasicRoute />
            <NewBottomMode />
          </div>
        </div>
      }
      {
        systemType === 'phone' && <div className="AppH5">
          <MyHead></MyHead>
          <div className='app-content'>
            <TopAcitveH5 />
            <NewTopModeH5 />
            {/* <div className='lineHeight'></div> */}
            <BasicRouteH5 />
            <NewBottomModeH5 />
          </div>
        </div>
      }
    </div>

  );
}

export default App;
