import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover, Avatar } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import newlocal from '../../unilt/newlocal'
import emitter from '../../unilt/emit';
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import { useNavigate, useLocation, useParams, useSearchParams, useRouteError } from "react-router-dom";
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import axios from '../../unilt/axios';
import lbox from '../../static/h5/lbox.png'
import zbox from '../../static/h5/zbox.png'
import cbox from '../../static/h5/cbox.png'
import openlbox from '../../static/h5/openlbox.png'
import openzbox from '../../static/h5/openzbox.png'
import opencbox from '../../static/h5/opencbox.png'

import openbboxbg1 from '../../static/h5/openbboxbg1.png'
import openbboxbg2 from '../../static/h5/openbboxbg2.png'

import openzboxbg1 from '../../static/h5/openzboxbg1.png'
import openzboxbg2 from '../../static/h5/openzboxbg2.png'

import opencboxbg1 from '../../static/h5/opencboxbg1.png'
import opencboxbg2 from '../../static/h5/opencboxbg2.png'

import close from '../../static/close.png'

import goback from '../../static/h5/goback.png'

var Web3 = require('web3')
let lbTime = 0

const jsonrc1 = [
  {
    "type": "function",
    "name": "mint",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getResidueAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getTotalAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getAlreadyMintAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
]

const jsonrc2 = [
  {
    "type": "function",
    "name": "createPool",
    "inputs": [{ "name": "collateralToken", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "queryPoolAddress",
    "inputs": [{ "name": "collateralToken", "type": "address" }],
    "outputs": [{ "name": "address", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


let provider = false
let islock = false
let czTime = 0
let userlistlength = 0


function OpenBox() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  const nprovider = useSelector(state => state.nprovider)
  const [pageInfo, setpageInfo] = useState(false)
  const renderuserRef = useRef(true)
  const signtoken = useSelector(state => state.signtoken)
  const [getParams, setParam] = useSearchParams()
  const [boxlist, setboxlist] = useState([])
  const [setitem, setsetitem] = useState(false)
  const [show, setshow] = useState(false)
  const typeC = useRef(false)


  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false

      const type = getParams.getAll('type')
      typeC.current = type[0]

      if (user === false || user === null || user === 'null') {

      } else {
        getdbstart()
      }
      islock = true
      return
    }
  }, [])



  useEffect(() => {
    if (renderuserRef.current) {
      renderuserRef.current = false
    } else {
      if (user === false || user === null || user === 'null') {

      } else {
        if (islock) {
          getdbstart()
        }
      }
    }

  }, [user])


  const getdbstart = (() => {
    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }
    if (user === false || user === null || user === 'null') {

    } else {

      let str = ''
      switch (language) {
        case 'zhcn': str = '正在加載...'; break;
        case 'en': str = 'Loading...'; break;
        default: break;
      }
      message.loading({ content: str, key: 'czlodaingg', duration: 0 });
      getuserinfo(true)
    }
  })

  useEffect(() => {
    if (signtoken === false || signtoken === null || signtoken === 'null') {

    } else {
      getdbstart()
    }

  }, [signtoken])

  const getuserinfo = (async (ispd = false) => {
    let that = this
    let re = axios({
      url: 'active/user/info',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setpageInfo(res.content)
        message.destroy('czlodaingg')
        getbox()
      } else {
        message.destroy('czlodaingg')
      }

    })
  })


  const getbox = (() => {
    let re = axios({
      url: 'active/box/select',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        boxType: typeC.current
      }
    })
    re.then(async (res) => {
      let list = res.content
      setboxlist(list)
    })
  })


  const openBoxStep1 = ((item)=>{
    setsetitem(item)
    setshow(true)
  })

  const goopen = (()=>{
    let that = this

    if (pageInfo.integralAmount < 10) {
      message.warning({ content: newlocal['box']['p38'][language], key: 'czlodain223gg', duration: 3 });

      return false
    }

    let re = axios({
      url: 'active/box/open',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        boxId: setitem.webId
      }
    })
    re.then(async (res) => {
      let amount = res.content
      let newitem = setitem
      newitem.amount = amount
      setsetitem(newitem)
      getdbstart()
    })
  })


  const gobacks = (()=>{
    document.getElementsByClassName("AppH5")[0].scrollTop = 0
    navigate('/blindbox')
  })

    
  const goShare = (() => {
    let hashtags = ['bbz']
    let msg = `${newlocal['box']['p60'][language]}${setitem.amount}${newlocal['box']['p61'][language]}`
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(msg)}&hashtags=${hashtags.join(',')}&url=${'https://tradertest.bbz.com/airdrop?invcode=' + pageInfo.inviteCode}`, "_blank",);
  })

  return (
    <div className="openboxH5" >
      {
        typeC.current === 'blue' && <div className='title'><img className='back' onClick={()=>gobacks()} src={goback}></img>{`${newlocal['box']['p32'][language]} (${boxlist.length})`}</div>
      }
      {
        typeC.current === 'purple' && <div className='title'><img className='back' onClick={()=>gobacks()} src={goback}></img>{`${newlocal['box']['p33'][language]} (${boxlist.length})`}</div>
      }
      {
        typeC.current === 'orange' && <div className='title'><img className='back' onClick={()=>gobacks()} src={goback}></img>{`${newlocal['box']['p34'][language]} (${boxlist.length})`}</div>
      }
      <div className='boxbox'>
        {boxlist.map((item,index)=>{
          return <div className='boxitem' onClick={()=>openBoxStep1(item)}>
            { typeC.current === 'blue'   &&  <img className='ico' src={ item.amount === 0 ? lbox : openlbox } />}
            { typeC.current === 'purple' &&  <img className='ico' src={ item.amount === 0 ? zbox : openzbox } />}
            { typeC.current === 'orange' &&  <img className='ico' src={ item.amount === 0 ? cbox : opencbox } />}
            {item.amount === 0 && <div className='p1'>{newlocal['box']['p35'][language]}</div>}
            {item.amount > 0 && <div className='p2'>{`${item.amount} BBZ`}</div>}
          </div>
        })}
      </div>
      {
        show && <div className='oboxmask'> 
          <div className='obox'>
            <img className='closeice' src={close} onClick={()=>setshow(false)}/>
            { typeC.current === 'blue' && setitem.amount === 0  &&  <img className='oboxtopbg' src={openbboxbg1} />}
            { typeC.current === 'blue' && setitem.amount > 0  &&  <img className='oboxtopbg' src={openbboxbg2} />}

            { typeC.current === 'purple' && setitem.amount === 0  &&  <img className='oboxtopbg' src={openzboxbg1} />}
            { typeC.current === 'purple' && setitem.amount > 0  &&  <img className='oboxtopbg' src={openzboxbg2} />}

            { typeC.current === 'orange' && setitem.amount === 0  &&  <img className='oboxtopbg' src={opencboxbg1} />}
            { typeC.current === 'orange' && setitem.amount > 0  &&  <img className='oboxtopbg' src={opencboxbg2} />}
            { setitem.amount === 0  && <div className='p1'>{newlocal['box']['p31'][language]}</div>}
            { setitem.amount === 0  && <div className='p2'>{newlocal['box']['p36'][language]}：{pageInfo ? pageInfo.integralAmount.toLocaleString('EN-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</div>}
            { setitem.amount === 0  && <div className='btn' onClick={()=>goopen()}>{newlocal['box']['p37'][language]}</div>}
            { setitem.amount > 0  && <div className='p3'>{newlocal['box']['p30'][language]}{' '}BBZ token</div>}
            { setitem.amount > 0  && <div className='p4'>{setitem.amount} token</div>}
            { setitem.amount > 0  && <div className='btn' onClick={()=>goShare()} >{newlocal['box']['p59'][language]}</div>}
          </div>
        </div>
      }
    </div>
  );
}

export default OpenBox;
