export const SETUSER = 'SETUSER'
export const SETLANGUAGE = 'SETLANGUAGE'
export const SETASSETS = 'SETASSETS'
export const SETBBZPRICE = 'SETBBZPRICE'
export const SETBTCPRICE = 'SETBTCPRICE'
export const SETASSETSTYPE = 'SETASSETSTYPE'
export const SETETHPRICE = 'SETETHPRICE'
export const SETACTIVELIST = 'SETACTIVELIST'
export const SETLINKTYPE = 'SETLINKTYPE'
export const SETPROVIDER = 'SETPROVIDER'
export const SETSIGNTOKEN = 'SETSIGNTOKEN'