import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover, Avatar, Collapse, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import newlocal from '../../unilt/newlocal'
import starw from '../../static/star-w.png'
import starh from '../../static/star-h.png'
import starb from '../../static/star-b.png'
import search from '../../static/search.png'
import btc from '../../static/btc.png'
import eth from '../../static/eth.png'
import newselect from '../../static/newselect.png'
import newusdt from '../../static/newusdt.png'
import close from '../../static/close.png'
import emitter from '../../unilt/emit';
import axios from '../../unilt/axios';
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import copywhite from '../../static/copywhite.png'
import h5b from '../../static/h5/h5b.png'
import h5t from '../../static/h5/h5t.png'

import h5add from '../../static/h5/h5add.png'
import h5seach from '../../static/h5/h5seach.png'


import Clipboard from 'clipboard'
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import { getContract } from 'viem'
const { Panel } = Collapse;
var Web3 = require('web3')

const Avaterlist = ['#F28D0A', '#7C26CC', '#F45E3E', '#0A8DF2', '#3FBD6D', '#08C8C0', '#D50202', '#32AA92']

const jsonrc1 = [
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [{ "name": "account", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "allowance",
    "inputs": [{ "name": "owner", "type": "address" }, { "name": "spender", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "approve",
    "inputs": [{ "name": "spender", "type": "address" }, { "name": "amount", "type": "uint256" }],
    "outputs": [{ "name": "end", "type": "bool" }]
  }
]

const jsonrc2 = [
  {
    "type": "function",
    "name": "createPool",
    "inputs": [{ "name": "collateralToken", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "queryPoolAddress",
    "inputs": [{ "name": "collateralToken", "type": "address" }],
    "outputs": [{ "name": "address", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


const jsonrc3 = [
  {
    "type": "function",
    "name": "getVaultState",
    "inputs": [],
    "outputs": [{ "name": "poolAmount", "type": "uint256" }, { "name": "maxPoolAmount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getVaultConfig",
    "inputs": [],
    "outputs": [{ "name": "factoryAddress", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "minExecutionFee", "type": "uint256" }, { "name": "collateralToken", "type": "address" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }, { "name": "minOrderAmount", "type": "uint256" }, { "name": "maxNumOfOrdersPerUser", "type": "uint256" }, { "name": "priceOracle", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


const jsonrc4 = [
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [{ "name": "account", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "addLiquidity",
    "inputs": [{ "name": "amount", "type": "uint256" }],
  },
  {
    "type": "function",
    "name": "removeLiquidity",
    "inputs": [{ "name": "lpAmount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "openPosition",
    "inputs": [{ "name": "amount", "type": "uint256" },
    { "name": "intervalType", "type": "uint8" },
    { "name": "indexAssetId", "type": "uint8" },
    { "name": "isLong", "type": "bool" }],
  },
  {
    "type": "function",
    "name": "getUserOrders",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'marketId',
          type: 'uint8',
        },
        {
          name: 'amount',
          type: 'uint256',
        },
        {
          name: 'isLong',
          type: 'bool',
        },
        {
          name: 'winningRate',
          type: 'uint256',
        },
        {
          name: 'executionFee',
          type: 'uint256',
        },
        {
          name: 'createAt',
          type: 'uint256',
        },
        {
          name: 'expiryAt',
          type: 'uint256',
        },
      ],
    }]
  },
  {
    "type": "function",
    "name": "closePosition",
    "inputs": [{ "name": "user", "type": "address" },
    { "name": "orderTime", "type": "Uint256 " },
    { "name": "executionFeeReceiver", "type": "address" }]
  }
]

const jsonrc5 = [
  {
    "type": "function",
    "name": "symbol",
    "inputs": [],
    "outputs": [{ "name": "symbol", "type": "string" }]
  }
]


const jsonrc6 = [
  {
    "type": "function",
    "name": "totalSupply",
    "inputs": [],
    "outputs": [{ "name": "d", "type": "uint256" }]
  }
]

let oldtype = 0
let czTime = 0
let provider = false

let userlistlength = 0

function Cz() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const user = useSelector(state => state.user)
  const linktype = useSelector(state => state.linktype)
  const navigate = useNavigate();
  let [tab, settab] = useState(3)
  let [show1, setshow1] = useState(false)
  let [show2, setshow2] = useState(false)
  let [value, setvalue] = useState('')
  const renderRef = useRef(true)
  let [hy1, sethy1] = useState(false)
  let [hy2, sethy2] = useState(false)
  let [hy3, sethy3] = useState(false)
  let [hy4, sethy4] = useState(false)
  let [tname, settname] = useState(false)
  let [collateralToken, setcollateralToken] = useState(false)
  let [maxNumOfOrdersPerUser, setmaxNumOfOrdersPerUser] = useState(false)
  let [maxOrderAmountFraction, setmaxOrderAmountFraction] = useState(false)
  let [minExecutionFee, setminExecutionFee] = useState(false)
  let [minOrderAmount, setminOrderAmount] = useState(0)
  let [priceOracle, setpriceOracle] = useState(false)
  let [treasuryFee, settreasuryFee] = useState(false)
  let [winningRate, setwinningRate] = useState(false)
  let [poolList, setpoolList] = useState([])
  let [poolList1, setpoolList1] = useState([])

  let [czs, setczs] = useState(false)
  let [poolAmount, setpoolAmount] = useState(false)
  let [maxPoolAmount, setmaxPoolAmount] = useState(false)
  let [userTip, setUserTip] = useState(false)

  let [address, setaddress] = useState('')
  let [taddress, settaddress] = useState('')
  let [iscz, setsicz] = useState(false)

  let [cvaleu1, setcvaleu1] = useState('')
  let [cvaleu2, setcvaleu2] = useState(200)
  let [cvaleu3, setcvaleu3] = useState(7500)
  let [cvaleu4, setcvaleu4] = useState(500)

  let [ctab2, setctab2] = useState(500)
  let [ctab1, setctab1] = useState(500)


  const nprovider = useSelector(state => state.nprovider)



  useEffect(() => {
    if (oldtype !== linktype) {
      oldtype = linktype
      reloadPage('new')
    }
  }, [linktype])

  useEffect(() => {
    if (renderRef.current) {
      oldtype = 0
      renderRef.current = false
      return
    }
  }, [])

  useEffect(() => {
    provider = nprovider
  }, [nprovider])

  const reloadPage = ((type = 'old') => {
    setpoolList([])
    setTimeout(() => {
      getType(type)
    }, 1000)
  })


  const checkChainId = (async () => {
    let chainId = `0x${chain.id.toString(16)}`
    if (chainId !== linkData[linktype.toString()].lid) {
      emitter.emit("checkcid", "Hello")
      return false
    } else {
      return true
    }
  })


  const getType = (async (type) => {
    let str = ''
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    message.loading({ content: str, key: 'czlodaingg', duration: 0 });


    let res = axios({
      url: 'pool/poolList',
      method: 'GET',
    })
    res.then(async (resp) => {
      for (let i in resp.content) {
        resp.content[i].winningRate = (Number(resp.content[i].winningRate) / 100).toString()
        resp.content[i].colorindex = i % 7
        resp.content[i].issc = false
        resp.content[i].maxPoolAmount = resp.content[i].totalToken
      }
      console.log(type)
      if (user === false || user === null || user === 'null') {
        message.destroy('czlodaingg')
        setpoolList(resp.content)
        settab(2)
      } else {
        getType1(resp.content, type)
      }
    })
  })


  const getType1 = (async (list, type) => {
    let nowlist = list ? list : poolList
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      for (let i in resp.content) {
        resp.content[i].winningRate = (Number(resp.content[i].winningRate) / 100).toString()
        resp.content[i].colorindex = i % 7
        resp.content[i].issc = false
        resp.content[i].maxPoolAmount = resp.content[i].totalToken

        for (let j in nowlist) {
          if (nowlist[j].address === resp.content[i].address) {
            nowlist[j].issc = true
          }
        }
      }
      if (type !== 'no') {
        if (resp.content.length > 0 && type === 'new') {
          settab(1)
        } else {
          settab(2)
        }
      } else {
      }
      message.destroy('czlodaingg')
      setpoolList1(resp.content)
      setpoolList(nowlist)

    })
  })





  const cx = (async (cx) => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let res = axios({
      url: 'pool/pool-info',
      method: 'GET',
      data: {
        poolAddress: cx
      }
    })
    res.then(async (resp) => {
      message.destroy('trange')
      if (resp.content && resp.content.id) {
        settname(resp.content.symbol)
        setaddress(cx)
        settaddress(cx)
        setsicz(true)
      } else {
        setsicz('null')
      }
    }).catch(() => {
      message.destroy('trange')
      setsicz('null')
    })
  })

  const creat = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    let ntime = dayjs().unix()
    if (ntime - czTime <= 2) {
      return false
    } else {
      czTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    if (cvaleu1 === '') {
      message.error({ content: newlocal['error']['p23'][language], key: 'trangerror', duration: 3 })
      return false
    } else {
      try {
        let code = await web3.eth.getCode(cvaleu1)
        if (code === '0x') {
          message.error({ content: newlocal['error']['p27'][language], key: 'trangerror', duration: 3 })
          return false
        } else {

        }
      } catch {
        message.error({ content: newlocal['error']['p27'][language], key: 'trangerror', duration: 3 })
        return false
      }
    }

    if (cvaleu4 === '') {
      message.error({ content: newlocal['error']['p24'][language], key: 'trangerror', duration: 3 })
      return false
    }
    if (Number(cvaleu4) > 10000) {
      message.error({ content: newlocal['error']['p25'][language], key: 'trangerror', duration: 3 })
      return false
    }

    let csflag = false
    let isres = false

    var contractAddress = linkData[linktype.toString()].gcaddress;


    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let gaslimit = 1000000

    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc2,
        functionName: 'createPool',
        account: user,
        args: [cvaleu1, cvaleu2, cvaleu3, cvaleu4]
      })
    } catch (e) {
      message.destroy('trange')

      goMessor(e)
      return false
    }
    const gasPrice = await publicClient.getGasPrice()
    const nonce = await publicClient.getTransactionCount({ address: user });

    userlistlength = 0
    await Promise.race([
      setTimeout(async () => {
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc2,
            functionName: 'createPool',
            account: user,
            args: [cvaleu1, cvaleu2, cvaleu3, cvaleu4],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce,
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getTypeLength(hash, 1, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      }, 500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })

  const getTypeLength = (async (result, index, errortime) => {
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      userlistlength = resp.content.length
      getlistenJy(result, index, errortime)
    })
  })



  const getTypeLengthNew = (async () => {
    let res = axios({
      url: 'pool/collect/poolList',
      method: 'GET',
      data: {
        address: user
      }
    })
    res.then(async (resp) => {
      let l1 = resp.content.length
      if (l1 > userlistlength) {
        message.destroy('czlodaingg')
        getType()
      } else {
        getTypeLengthNew()
      }
    })
  })



  const getlistenJy = (async (result, type, errortime) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var receipt = await web3.eth.getTransactionReceipt(result)
    let nowtime = Number(dayjs().unix())
    if (nowtime > errortime) {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    } else if (receipt === null) {
      getlistenJy(result, type, errortime)
    } else if (receipt.status === true) {
      message.destroy('trange')
      if (type === 1) {
        message.success({ content: <div>{newlocal['main']['p80'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow2(false)
        let str = ''
        switch (language) {
          case 'zhcn': str = '正在加載...'; break;
          case 'en': str = 'Loading...'; break;
          default: break;
        }
        message.loading({ content: str, key: 'czlodaingg', duration: 0 });
        getTypeLengthNew()
      }
    } else {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }
  })

  const gojypage = ((link) => {
    window.open(`${linkData[linktype.toString()].txklink}${link}`)

  })


  const go = (async (ad, type) => {
    navigate(`/?pool=${ad}&type=${type}`)
  })

  const stab = (async (n) => {
    if (n === 2) {
      settab(n)
      getType()
      return false
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    if (n === 1) {
      settab(n)
      getType1(false, 'no')
    }
  })

  const goshouc = (async (item, type) => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    let res = axios({
      url: 'pool/collect/follow',
      method: 'POST',
      data: {
        address: user,
        poolAddress: item.address,
        isFollow: type
      }
    })
    res.then(async (resp) => {
      if (type === 'yes') {
        message.success({ content: newlocal['cz']['p20'][language], key: 'sdffggs' })
      }
      getType()

      if (tab === 2 && type === 'YES') {
        settab(1)
      }
      if (tab === 1) {
      }
    })
  })


  const copyToClipboard = ((value) => {
    var text = value;
    var input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    message.success({ content: newlocal['main']['copytip'][language], key: 'sdff' })
  })


  const goMessor = ((error) => {
    let msg = error.message
    console.error(msg)
    if (msg.indexOf('insufficient allowance') >= 0) {
      message.error({ content: newlocal['error']['p1'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('transfer amount exceeds balance') >= 0) {
      message.error({ content: newlocal['error']['p2'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('withdraw amount too much!') >= 0) {
      message.error({ content: newlocal['error']['p3'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('marketId not exist') >= 0) {
      message.error({ content: newlocal['error']['p4'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('intervalId not exist') >= 0) {
      message.error({ content: newlocal['error']['p5'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('total Order Amount is too large') >= 0) {
      message.error({ content: newlocal['error']['p6'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too large') >= 0) {
      message.error({ content: newlocal['error']['p7'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too small') >= 0) {
      message.error({ content: newlocal['error']['p8'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('too many remaining orders') >= 0) {
      message.error({ content: newlocal['error']['p9'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('have the same order') >= 0) {
      message.error({ content: newlocal['error']['p10'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('execution fee is too low') >= 0) {
      message.error({ content: newlocal['error']['p11'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p12'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is not ready') >= 0) {
      message.error({ content: newlocal['error']['p13'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p14'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order running') >= 0) {
      message.error({ content: newlocal['error']['p15'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is ready') >= 0) {
      message.error({ content: newlocal['error']['p16'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('reward amount is zero') >= 0) {
      message.error({ content: newlocal['error']['p17'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('getRewards approve error') >= 0) {
      message.error({ content: newlocal['error']['p18'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('ZERO_ADDRESS!') >= 0) {
      message.error({ content: newlocal['error']['p19'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('not support') >= 0) {
      message.error({ content: newlocal['error']['p20'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('factoryConfig is not initialize') >= 0) {
      message.error({ content: newlocal['error']['p21'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('CREATE2_FAILED') >= 0) {
      message.error({ content: newlocal['error']['p22'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('The total cost (gas * gas fee + value) of executing this transa') >= 0) {
      message.error({ content: newlocal['error']['p29'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('User denied transaction signature') >= 0) {
      message.error({ content: newlocal['main']['p70'][language], key: 'trangerrorss', duration: 3 })
    } else {
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
    }
  })


  const setccctab2 = ((bb) => {
    setctab2(bb)
  })

  const setccctab1 = ((bb) => {
    setctab1(bb)
  })


  return (
    <div className="czh5" >
      <div className='topcbox'>
        <div className='tabbox'>
          <div className={tab === 1 ? 'item active' : 'item'} onClick={() => stab(1)}>
            {tab === 1 && <img className='star' src={starw} />}
            {tab !== 1 && <img className='star' src={starh} />}
            <div className='t'>{newlocal['cz']['p1'][language]}</div>
          </div>
          <div className={tab === 2 ? 'item active' : 'item'} onClick={() => stab(2)}>
            <div className='t'>{newlocal['cz']['p2'][language]}</div>
          </div>
        </div>

        <div className='actionbox'>
          <div className='btnbox' onClick={()=>setshow1(true)}>
            <img className='btnimg' src={h5seach} />
          </div>
          <div className='line'></div>
          <div className='btnbox' onClick={()=>setshow2(true)}>
            <img className='btnimg1' src={h5add} />
          </div>
        </div>
      </div>

      {
        tab === 1 && <div className='tablebox'>
          <Collapse collapsible="header" activeKey={[ctab1]} accordion={true} onChange={(bb) => setccctab1(bb)}>
            {
              poolList1.map((item, index) => {
                return <Panel header={<div className='itembox'><div className='item1' showArrow={false}>
                  <Avatar className='ico' style={{ color: '#ffffff', backgroundColor: Avaterlist[item.colorindex] }}>{item.name[0]}</Avatar>
                </div>
                  <div className='item2'>
                    <div className='tbox'>
                      <div className='tt'>{(item ? item.name : '')} Pool</div>
                      <div className='tb'>{newlocal['cz']['p23'][language]}{':  '}{Number(Number(item.maxPoolAmount) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) === 'NaN' ? '0.00' : Number(Number(item.maxPoolAmount) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                  </div>
                  <div className='item3'>{(item ? item.winningRate : '')}%</div>
                  <div className='item4'>{Number(ctab2) === Number(index) ? <img className='ico' src={h5t} /> : <img className='ico' src={h5b} />}</div>
                </div>} key={index}>
                  <div className='infobox'>
                    <div className='infoitem'>
                      <div className='infop1'>{newlocal['cz']['p21'][language]}</div>
                      <div className='infop2'>{Number(Number(item.tradingVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop1'>{newlocal['cz']['p6'][language]}</div>
                      <div className='infop2'>{(item ? item.winningRate : '')}%</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop1'>{newlocal['cz']['p22'][language]}</div>
                      <div className='infop2'>{Number(Number(item.tradingVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop3'>
                        <div className='infop4'>{newlocal['cz']['p25'][language]}</div>
                        <div className='infop5'>{item.tokenAddress.substring(0, 4) + '...' + item.tokenAddress.substring(item.tokenAddress.length - 4, item.tokenAddress.length)}</div>
                      </div>
                      <div className='infop6' data-clipboard-text={item.tokenAddress} onClick={() => { copyToClipboard(item.tokenAddress) }}>{newlocal['textTip']['p2'][language]}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop3'>
                        <div className='infop4'>{newlocal['cz']['p24'][language]}</div>
                        <div className='infop5'>{item.address.substring(0, 4) + '...' + item.address.substring(item.address.length - 4, item.address.length)}</div>
                      </div>
                      <div className='infop6' data-clipboard-text={item.address} onClick={() => { copyToClipboard(item.address) }}>{newlocal['textTip']['p2'][language]}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='bttm' onClick={() => { go(item.address, 1) }}>{newlocal['cz']['p9'][language]}</div>
                      <div className='bttm' onClick={() => { go(item.address, 2) }}>{newlocal['cz']['p10'][language]}</div>
                    </div>
                  </div>
                </Panel>
              })
            }
          </Collapse>
          {
            poolList1.length <= 0 && <div className='nuutext'>{newlocal['cz']['p19'][language]}</div>
          }
        </div>
      }


      {
        tab === 2 && <div className='tablebox'>
          <Collapse collapsible="header" activeKey={[ctab2]} accordion={true} onChange={(bb) => setccctab2(bb)}>
            {
              poolList.map((item, index) => {
                return <Panel header={<div className='itembox'><div className='item1' showArrow={false}>
                  <Avatar className='ico' style={{ color: '#ffffff', backgroundColor: Avaterlist[item.colorindex] }}>{item.name[0]}</Avatar>
                </div>
                  <div className='item2'>
                    <div className='tbox'>
                      <div className='tt'>{(item ? item.name : '')} Pool</div>
                      <div className='tb'>{newlocal['cz']['p23'][language]}{':  '}{Number(Number(item.maxPoolAmount) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) === 'NaN' ? '0.00' : Number(Number(item.maxPoolAmount) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                  </div>
                  <div className='item3'>{(item ? item.winningRate : '')}%</div>
                  <div className='item4'>{Number(ctab2) === Number(index) ? <img className='ico' src={h5t} /> : <img className='ico' src={h5b} />}</div>
                </div>} key={index}>
                  <div className='infobox'>
                    <div className='infoitem'>
                      <div className='infop1'>{newlocal['cz']['p21'][language]}</div>
                      <div className='infop2'>{Number(Number(item.tradingVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop1'>{newlocal['cz']['p6'][language]}</div>
                      <div className='infop2'>{(item ? item.winningRate : '')}%</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop1'>{newlocal['cz']['p22'][language]}</div>
                      <div className='infop2'>{Number(Number(item.tradingVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop3'>
                        <div className='infop4'>{newlocal['cz']['p25'][language]}</div>
                        <div className='infop5'>{item.tokenAddress.substring(0, 4) + '...' + item.tokenAddress.substring(item.tokenAddress.length - 4, item.tokenAddress.length)}</div>
                      </div>
                      <div className='infop6' data-clipboard-text={item.tokenAddress} onClick={() => { copyToClipboard(item.tokenAddress) }}>{newlocal['textTip']['p2'][language]}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='infop3'>
                        <div className='infop4'>{newlocal['cz']['p24'][language]}</div>
                        <div className='infop5'>{item.address.substring(0, 4) + '...' + item.address.substring(item.address.length - 4, item.address.length)}</div>
                      </div>
                      <div className='infop6' data-clipboard-text={item.address} onClick={() => { copyToClipboard(item.address) }}>{newlocal['textTip']['p2'][language]}</div>
                    </div>
                    <div className='infoitem'>
                      <div className='bttm' onClick={() => { go(item.address, 1) }}>{newlocal['cz']['p9'][language]}</div>
                      <div className='bttm' onClick={() => { go(item.address, 2) }}>{newlocal['cz']['p10'][language]}</div>
                    </div>
                  </div>
                </Panel>
              })
            }
          </Collapse>
        </div>
      }

      {
        show1 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['cz']['p11'][language]}</div>
            <img className='closeico' src={close} onClick={() => { setshow1(false); settaddress(''); setaddress(''); setsicz('') }}></img>
            <div className='sbox'>
              <img className='ico' src={search} />
              <input className='input' placeholder={newlocal['cz']['p12'][language]} value={value} onChange={(e) => { setvalue(e.currentTarget.value.toLowerCase()) }} />
            </div>
            {
              value !== '' && address !== '' && taddress !== '' && <div className='listbox'>
                <div className='item'>
                  <div className='left'>
                    {/* <img className='star' src={starh} /> */}
                    <Avatar className='ico' size={36} style={{ color: '#ffffff', backgroundColor: Avaterlist[0] }}>{tname[0]}</Avatar>
                    <div className='tbox'>
                      <div className='tt'>{tname ? tname + ' Pool' : ''}</div>
                      <div className='tb'>{tname ? tname : ''}</div>
                    </div>
                  </div>
                  {
                    iscz && <div className='right' onClick={() => { go(address, 1) }}>
                      {newlocal['cz']['p13'][language]}
                    </div>
                  }


                </div>
              </div>
            }
            {
              iscz === 'null' && <div className='nulltext' >请输入有效地址！</div>
            }

            <div className='btn' onClick={() => cx(value)}><div className='t'>{newlocal['cz']['p26'][language]}</div></div>
          </div>
        </div>
      }
      {
        show2 && <div className='langboxmask'>
          <div className='langBox langBox1' style={{ width: '324PX' }}>
            <div className='title'>{newlocal['creatpool']['p1'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow2(false)}></img>
            <div className='craetbox'>
              <div className='p1'>{newlocal['creatpool']['p2'][language]}</div>
              <input value={cvaleu1} className='input1' placeholder={newlocal['textTip']['p1'][language]} onChange={(e) => { setcvaleu1(e.currentTarget.value.toLowerCase()) }}></input>
              <div className='p1'>{newlocal['creatpool']['p3'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu2(200)} className={cvaleu2 === 200 ? 'changeitem changeitemacitve' : 'changeitem'}>2%</div>
                <div onClick={() => setcvaleu2(500)} className={cvaleu2 === 500 ? 'changeitem changeitemacitve' : 'changeitem'}>5%</div>
                <div onClick={() => setcvaleu2(1000)} className={cvaleu2 === 1000 ? 'changeitem changeitemacitve' : 'changeitem'}>10%</div>
              </div>
              <div className='p1'>{newlocal['creatpool']['p4'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu3(7500)} className={cvaleu3 === 7500 ? 'changeitem changeitemacitve' : 'changeitem'}>75%</div>
                <div onClick={() => setcvaleu3(8000)} className={cvaleu3 === 8000 ? 'changeitem changeitemacitve' : 'changeitem'}>80%</div>
                <div onClick={() => setcvaleu3(8500)} className={cvaleu3 === 8500 ? 'changeitem changeitemacitve' : 'changeitem'}>85%</div>
                <div onClick={() => setcvaleu3(9000)} className={cvaleu3 === 9000 ? 'changeitem changeitemacitve' : 'changeitem'}>90%</div>
              </div>
              <div className='p1'>{newlocal['creatpool']['p5'][language]}</div>
              <div className='changitembox'>
                <div onClick={() => setcvaleu4(500)} className={cvaleu4 === 500 ? 'changeitem changeitemacitve' : 'changeitem'}>5%</div>
                <div onClick={() => setcvaleu4(1000)} className={cvaleu4 === 1000 ? 'changeitem changeitemacitve' : 'changeitem'}>10%</div>
                <div onClick={() => setcvaleu4(2000)} className={cvaleu4 === 2000 ? 'changeitem changeitemacitve' : 'changeitem'}>20%</div>
              </div>
              <div className='btn btnnew'  onClick={() => creat()}><div className='t'>{newlocal['cz']['p18'][language]}</div></div>
            </div>
          </div>
        </div>
      }



    </div>
  );
}

export default Cz;
