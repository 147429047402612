var newlocal = {
    "topMode": {
        "nav1": {
            "zhcn": "交易",
            "en": "Trade",
        },
        "nav2": {
            "zhcn": "池子",
            "en": "Pools",
        },
        "nav21": {
            "zhcn": "赚取",
            "en": "Earn",
        },
        "nav3": {
            "zhcn": "空投",
            "en": "Airdrop",
        },
        "nav4": {
            "zhcn": "儀表板",
            "en": "Dashboard",
        },
        "nav5": {
            "zhcn": "文件",
            "en": "Docs",
        },
        "nav6": {
            "zhcn": "推薦",
            "en": "Referral",
        },
        "nav7": {
            "zhcn": "盲盒",
            "en": "Mystery Box",
        },
        "btn1": {
            "zhcn": "交易",
            "en": "Trade",
        },
        "btn2": {
            "zhcn": "連結錢包",
            "en": "Connect Wallet",
        },
        "boxtitle1": {
            "zhcn": "選擇語言",
            "en": "Select Language",
        },
        "boxtitle2": {
            "zhcn": "選擇網絡",
            "en": "Select network",
        },
        "boxinfo1": {
            "zhcn": "如果沒有錢包，可以選擇提供商並立即建一個。",
            "en": "If you don't have a wallet, you can choose a provider and immediately create one.",
        },
        "boxtip1": {
            "zhcn": "無法偵測MetaMask錢包",
            "en": "MetaMask Wallet not detected.",
        },
        "boxtip2": {
            "zhcn": "您的錢包未連接到。",
            "en": "Your wallet is not connected to .",
        },
        "boxtip3": {
            "zhcn": "切換到",
            "en": "Switch to",
        },
        "p1": {
            "zhcn": "請連接到 MetaMask。",
            "en": "Please connect to MetaMask.",
        },
        "p2": {
            "zhcn": "請安裝 MetaMask！",
            "en": "Please install MetaMask!",
        },
        "p21": {
            "zhcn": "請安裝 Coinbase Wallet！",
            "en": "Please install Coinbase Wallet!",
        },
        "p22": {
            "zhcn": "請安裝 OKX Wallet！",
            "en": "Please install OKX Wallet!",
        },
        "p3": {
            "zhcn": "您是否安裝了多個錢包？",
            "en": "Do you have multiple wallets installed?",
        },
        "p4": {
            "zhcn": "複製地址",
            "en": "Copy address",
        },
        "p5": {
            "zhcn": "在瀏覽器中查看",
            "en": "View in Explorer",
        },
        "p6": {
            "zhcn": "斷開連接",
            "en": "Disconnect",
        },
        "p7": {
            "zhcn": "測試網",
            "en": "Testnet",
        },
        "p101": {
            "zhcn": "網路",
            "en": "Network",
        },
        "p102": {
            "zhcn": "網路連線正常",
            "en": "Network connection is normal",
        },
        "p103": {
            "zhcn": "網路連線錯誤",
            "en": "Network connection error",
        },
        "p1041": {
            "zhcn": "驗證地址",
            "en": "Verify address",
        },
        "p104": {
            "zhcn": "點擊驗證按鈕，驗證您的錢包地址",
            "en": "Click the Send button to verify your wallet address",
        },
        "p105": {
            "zhcn": "驗證",
            "en": "Verify",
        },
        "p106": {
            "zhcn": "快來領取盲盒",
            "en": "Get the Mystery Box",
        },
        "p107": {
            "zhcn": "和測試幣！",
            "en": "and test coins!",
        },
        "p108": {
            "zhcn": "領取",
            "en": "Claim",
        }
    },
    "bottombox": {
        "link1": {
            "zhcn": "條款及細則",
            "en": "Terms and Conditions",
        },
        "link2": {
            "zhcn": "推薦條款",
            "en": "Referral Terms",
        },
        "link3": {
            "zhcn": "多媒體工具",
            "en": "Media Kit",
        }
    },
    "main":{
        "copytip": {
            "zhcn": "複製成功",
            "en": "Replicating Success",
        },
        "p1": {
            "zhcn": "收益",
            "en": "Profit",
        },
        "p2": {
            "zhcn": "當前倉位",
            "en": "Active",
        },
        "p3": {
            "zhcn": "歷史訂單",
            "en": "History",
        },
        "p4": {
            "zhcn": "正在交易",
            "en": "Trading in progress",
        },
        "p5": {
            "zhcn": "交易規模",
            "en": "Quantity",
        },
        "p6": {
            "zhcn": "盈虧",
            "en": "Profit/Loss",
        },
        "p7": {
            "zhcn": "幣種",
            "en": "Currency",
        },
        "p8": {
            "zhcn": "資產",
            "en": "Asset",
        },
        "p9": {
            "zhcn": "交易類型",
            "en": "Type",
        },
        "p10": {
            "zhcn": "交易規模",
            "en": "Quantity",
        },
        "p11": {
            "zhcn": "收益",
            "en": "Profit",
        },
        "p12": {
            "zhcn": "結束時間",
            "en": "End Time",
        },
        "p13": {
            "zhcn": "盈虧",
            "en": "Profit/Loss",
        },
        "p14": {
            "zhcn": "看空",
            "en": "Short",
        },
        "p15": {
            "zhcn": "看多",
            "en": "Long",
        },
        "p16": {
            "zhcn": "交易",
            "en": "Trade",
        },
        "p17": {
            "zhcn": "質押",
            "en": "Staking",
        },
        "p18": {
            "zhcn": "收益率",
            "en": "Profit margin",
        },
        "p19": {
            "zhcn": "交易",
            "en": "Trade",
        },
        "p20": {
            "zhcn": "數量",
            "en": "Quantity",
        },
        "p21": {
            "zhcn": "餘額",
            "en": "Balance",
        },
        "p211": {
            "zhcn": "可用",
            "en": "Available",
        },
        "p212": {
            "zhcn": "授權金額",
            "en": "Authorization amount",
        },
        "p22": {
            "zhcn": "持倉時間",
            "en": "Holding time",
        },
        "p23": {
            "zhcn": "設定時間",
            "en": "Set time",
        },
        "p24": {
            "zhcn": "盈利",
            "en": "Profit",
        },
        "p25": {
            "zhcn": "虧損",
            "en": "Loss",
        },
        "p26": {
            "zhcn": "費用",
            "en": "Fee",
        },
        "p27": {
            "zhcn": "看空",
            "en": "Short",
        },
        "p28": {
            "zhcn": "看多",
            "en": "Long",
        },
        "p29": {
            "zhcn": "收益率",
            "en": "Profit margin",
        },
        "p291": {
            "zhcn": "年利率",
            "en": "APR",
        },
        "p30": {
            "zhcn": "總數量",
            "en": "Total",
        },
        "p31": {
            "zhcn": "合約地址",
            "en": "Contract address",
        },
        "p32": {
            "zhcn": "質押倉位",
            "en": "Staking position",
        },
        "p33": {
            "zhcn": "累計收益",
            "en": "Total Profit",
        },
        "p34": {
            "zhcn": "質押",
            "en": "Staking",
        },
        "p35": {
            "zhcn": "贖回",
            "en": "Redemption",
        },
        "p36": {
            "zhcn": "立即質押",
            "en": "Stake Now",
        },
        "p37": {
            "zhcn": "選擇資產",
            "en": "Select Asset",
        },
        "p38": {
            "zhcn": "選擇池子",
            "en": "Select Pool",
        },
        "p39": {
            "zhcn": "質押",
            "en": "Staking",
        },
        "p40": {
            "zhcn": "贖回",
            "en": "Redemption",
        },
        "p41": {
            "zhcn": "數量",
            "en": "Quantity",
        },
        "p42": {
            "zhcn": "最大",
            "en": "Max",
        },
        "p43": {
            "zhcn": "确认",
            "en": "Confirm",
        },
        "p44": {
            "zhcn": "數量",
            "en": "Quantity",
        },
        "p45": {
            "zhcn": "最大",
            "en": "Max",
        },
        "p46": {
            "zhcn": "确认",
            "en": "Confirm",
        },
        "p47": {
            "zhcn": "交易最小數量",
            "en": "Transaction Minimum Quantity",
        },
        "p471": {
            "zhcn": "交易最大數量",
            "en": "Transaction Maximum Quantity",
        },
        "p472": {
            "zhcn": "池子是空，无法进行交易",
            "en": "池子是空，无法进行交易",
        },
        "p48": {
            "zhcn": "超出最大可用餘額",
            "en": "Maximum available balance exceeded",
        },
        "p49": {
            "zhcn": "持倉時間",
            "en": "Holding time",
        },
        "p50": {
            "zhcn": "請選擇持倉時間",
            "en": "Please select the holding time",
        },
        "p51": {
            "zhcn": "批准",
            "en": "Approve",
        },
        "p52": {
            "zhcn": "允許 BBZ 將您的 ",
            "en": "Allow BBZ to transfer your",
        },
        "p53arb": {
            "zhcn": "用於 ARBITRUM 交易用戶體驗免責聲明：請注意，交易下達和訂單執行之間可能存在延遲，但時間戳和價格是在您的錢包簽署交易時獲取的，以確保公平準確的交易體驗。",
            "en": "For ARBITRUM trading user experience disclaimer: Please note that there may be a delay between the trade being placed and the order being executed, but the timestamp and price are captured when the transaction is signed by your wallet to ensure a fair and accurate trading experience.",
        },
        "p53bsc": {
            "zhcn": "用於 BNB 交易用戶體驗免責聲明：請注意，交易下達和訂單執行之間可能存在延遲，但時間戳和價格是在您的錢包簽署交易時獲取的，以確保公平準確的交易體驗。",
            "en": "For BNB trading user experience disclaimer: Please note that there may be a delay between the trade being placed and the order being executed, but the timestamp and price are captured when the transaction is signed by your wallet to ensure a fair and accurate trading experience.",
        },
        "p54": {
            "zhcn": "批准",
            "en": "Approve",
        },
        "p55": {
            "zhcn": "交易池",
            "en": "Trade Pools",
        },
        "p551": {
            "zhcn": "交易池",
            "en": "Trade Pools",
        },
        "p56": {
            "zhcn": "沒有進行中的訂單",
            "en": "No order in progress",
        },
        "p57": {
            "zhcn": "沒有已結束的訂單",
            "en": "No closed orders",
        },
        "p58": {
            "zhcn": "超出同時最大訂單數：",
            "en": "Exceeded the maximum number of simultaneous orders:",
        },
        "p59": {
            "zhcn": "你沒有足夠的",
            "en": "You don't have enough",
        },
        "p60": {
            "zhcn": "購買",
            "en": "Buy",
        },
        "p61": {
            "zhcn": "入場價格",
            "en": "Strike Price",
        },
        "p62": {
            "zhcn": "當前價格",
            "en": "Current Price",
        },
        "p63": {
            "zhcn": "入場時間",
            "en": "Open Time",
        },
        "p64": {
            "zhcn": "結束價格",
            "en": "Close Price",
        },
        "p65": {
            "zhcn": "处理中...",
            "en": "Processing...",
        },
        "p66": {
            "zhcn": "結算中...",
            "en": "Processing...",
        },
        "p662": {
            "zhcn": "獲取中...",
            "en": "Loading...",
        },
        "p661": {
            "zhcn": "結算失敗",
            "en": "Failed",
        },
        "p67": {
            "zhcn": "結算",
            "en": "Settlement",
        },
        "p68": {
            "zhcn": "退款",
            "en": "Refund"
        },
        "p681": {
            "zhcn": "已退款",
            "en": "Refunded"
        },
        "p69": {
            "zhcn": "交易確認正在進行中...",
            "en": "Transaction confirmation in progress..."
        },
        "p70": {
            "zhcn": "糟糕！ 有一些錯誤。 你能再試一次嗎？\n錯誤：用戶拒絕交易",
            "en": "Oops! There is some error. Can you please try again?\nError: user rejeected transaction "
        },
        "p71": {
            "zhcn": "BTC/USDT 看跌倉位",
            "en": "Position opened for BTC/USDT Down"
        },
        "p72": {
            "zhcn": "BTC/USDT 看漲倉位",
            "en": "Position opened for BTC/USDT Up"
        },
        "p73": {
            "zhcn": "在Explorer區塊鏈瀏覽器上查看",
            "en": "View on Explorer"
        },
        "p74": {
            "zhcn": "結算成功",
            "en": "Successful liquidation"
        },
        "p75": {
            "zhcn": "退單成功",
            "en": "Chargeback successful"
        },
        "p76": {
            "zhcn": "贖回成功",
            "en": "Successful redemption"
        },
        "p77": {
            "zhcn": "質押成功",
            "en": "Staking successful"
        },
        "p78": {
            "zhcn": "交易成功！",
            "en": "Transaction successful! "
        },
        "p79": {
            "zhcn": "结算失败",
            "en": "Failed"
        },
        "p80": {
            "zhcn": "創建成功",
            "en": "Created successfully"
        },
        "p81": {
            "zhcn": "領取成功",
            "en": "Receive success"
        },
        "p82": {
            "zhcn": "糟糕！出現錯誤。",
            "en": "Oops！There is some error."
        },
        "p83": {
            "zhcn": "錯誤：調用異常中缺少還原數據；",
            "en": "Error:missing revert data in call exception; "
        },
        "p831": {
            "zhcn": "錯誤：網絡連接異常；",
            "en": "Error:abnormal network connection;"
        },
        "p832": {
            "zhcn": "調用異常中缺少還原數據.",
            "en": "Missing revert data in call exception."
        },
        "p84": {
            "zhcn": "事務已還原，但沒有原因字符串.",
            "en": "Transaction reverted without a reason string."
        },
        "p85": {
            "zhcn": "名字",
            "en": "Name"
        },
        "p86": {
            "zhcn": "交易收益",
            "en": "Trading profit"
        },
        "p87": {
            "zhcn": "已添加到自選",
            "en": "Added to favorites"
        },
        "p88": {
            "zhcn": "質押代幣到池子裡，換取池子裡對等價格的LP數量，\nLP的價格是隨著交易發生變化。",
            "en": "Pledge tokens to the pool in exchange for the number of LPs with the same price in the pool, \nLP prices change with transactions."
        },
        "p89": {
            "zhcn": "從第一次質押開始累計計算收益。",
            "en": "Earnings are calculated accumulatively from the first Staking."
        },
        "p90": {
            "zhcn": "正在交易倉位的交易數量之和，\n不包括結算失敗、結算中的倉位。",
            "en": "The sum of the transaction quantities of the positions being traded, \nexcluding positions that have failed to settle and are in settlement."
        },
        "p91": {
            "zhcn": "正在交易倉位的盈虧之和，\n不包括結算失敗、結算中的倉位。",
            "en": "The sum of the profit and loss of the positions being traded, \nexcluding positions that have failed to settle or are in settlement."
        },
        "p92": {
            "zhcn": "未獲取到價格，無法進行結算，\n請手動退款。",
            "en": "The price cannot be obtained, and the settlement cannot be performed. \nPlease refund manually."
        },
        "p93": {
            "zhcn": "盈利：交易數量 x 交易收益率",
            "en": "Profit: number of transactions x transaction yield"
        },
        "p94": {
            "zhcn": "虧損：交易數量 - 費用",
            "en": "Loss: Number of Trades - Fee"
        },
        "p95": {
            "zhcn": "費用：交易數量 x ",
            "en": "Fee: transaction amount x "
        },
        "p96": {
            "zhcn": "年利率每週更新，\n具體數量取決於池子當週賺取的收益。",
            "en": "The annual interest rate is updated weekly, \nand the specific amount depends on the income earned by the pool that week."
        },
        "p97": {
            "zhcn": "秒",
            "en": "s"
        },
        "p98": {
            "zhcn": "分",
            "en": "m"
        },
        "p99": {
            "zhcn": "時",
            "en": "h"
        },
        "p100": {
            "zhcn": "創建交易池",
            "en": "Create Pools"
        },
        "p101": {
            "zhcn": "交易挖礦",
            "en": "Trade mining"
        },
        "p102": {
            "zhcn": "獎勵",
            "en": "Award"
        },
        "p103": {
            "zhcn": "領取",
            "en": "Claim"
        },
        "p104": {
            "zhcn": "領取成功",
            "en": "Claim success"
        },
        "p105": {
            "zhcn": "你還在嗎？",
            "en": "Are you still there？"
        },
        "p106": {
            "zhcn": "確認",
            "en": "Confirm"
        },
        "p107": {
            "zhcn": "獲取失敗",
            "en": "Failed to obtain"
        },
        "p108": {
            "zhcn": "網路異常，未取得到餘額！",
            "en": "Network error, balance not obtained!"
        }
    },

    "cz": {
        "p1": {
            "zhcn": "自選",
            "en": "Optional",
        },
        "p2": {
            "zhcn": "推薦",
            "en": "Recommend",
        },
        "p3": {
            "zhcn": "搜索創建交易池",
            "en": "Search and create a trading pool",
        },
        "p4": {
            "zhcn": "池子名稱",
            "en": "Pool name",
        },
        "p5": {
            "zhcn": "代幣",
            "en": "Token",
        },
        "p6": {
            "zhcn": "盈利",
            "en": "Profit",
        },
        "p7": {
            "zhcn": "交易次數",
            "en": "Tranding frequency",
        },
        "p8": {
            "zhcn": "總數量",
            "en": "Total amount",
        },
        "p9": {
            "zhcn": "交易",
            "en": "Trade",
        },
        "p10": {
            "zhcn": "質押",
            "en": "Staking",
        },
        "p11": {
            "zhcn": "搜索創建",
            "en": "Search and Create",
        },
        "p12": {
            "zhcn": "請輸入池子地址",
            "en": "Please enter the Pool address",
        },
        "p13": {
            "zhcn": "交易",
            "en": "Trade",
        },
        "p14": {
            "zhcn": "創建",
            "en": "Create",
        },
        "p15": {
            "zhcn": "創建",
            "en": "Create",
        },
        "p16": {
            "zhcn": "名稱",
            "en": "Name",
        },
        "p17": {
            "zhcn": "代幣",
            "en": "Token",
        },
        "p18": {
            "zhcn": "創建",
            "en": "Create",
        },
        "p19": {
            "zhcn": "你還沒有交易或者收藏的池子。",
            "en": "You have no trading or collection pools yet.",
        },
        "p20": {
            "zhcn": "已添加到自選",
            "en": "Added to favorites"
        },
        "p21": {
            "zhcn": "年利率",
            "en": "APR"
        },
        "p22": {
            "zhcn": "昨日交易量",
            "en": "Trading volume"
        },
        "p23": {
            "zhcn": "池子總額",
            "en": "Pool total"
        },
        "p24": {
            "zhcn": "池子合約地址",
            "en": "Pool contract address"
        },
        "p25": {
            "zhcn": "代幣合約地址",
            "en": "Token contract address"
        },
        "p26": {
            "zhcn": "查詢",
            "en": "Inquire"
        },
        "p27": {
            "zhcn": "創建交易池",
            "en": "Create Pools"
        }
    },


    "lb": {
        "p1": {
            "zhcn": "領取測試代幣",
            "en": "Receive testnet Tokens",
        },
        "p2": {
            "zhcn": "領取測試 ETH",
            "en": "Claim testnet ETH",
        },
        "p3": {
            "zhcn": "您將必須领取測試 ETH 以支付Gas費。",
            "en": "You will have to claim testnet ETH for gas fee.",
        },
        "p4": {
            "zhcn": "1.從Goerli 水龍頭領取 GoerliETH",
            "en": "1.Claim GoerliETH from Goerli faucet",
        },
        "p5": {
            "zhcn": "使用 Goerli 水龍頭",
            "en": "Using the Goerli faucet",
        },
        "p6": {
            "zhcn": "使用 Goerli Mudit 水龍頭",
            "en": "Using the Goerli Mudit faucet",
        },
        "p7": {
            "zhcn": "使用 Paradigm Multi 水龍頭",
            "en": "Using the Paradigm Multi faucet",
        },
        "p8": {
            "zhcn": "2. 橋接 GoerliETH 到 AETH",
            "en": "2.Bridge GoerliETH to AETH",
        },
        "p9": {
            "zhcn": "領取交易池測試代幣",
            "en": "Claim transaction pool testnet Tokens",
        },
        "p10": {
            "zhcn": "領取 10000 TBBZ",
            "en": "Claim 10000 TBBZ",
        },
        "p101": {
            "zhcn": "領取 ",
            "en": "Claim",
        },
        "p102": {
            "zhcn": "領取了，去交易吧！ ",
            "en": "Claimed , go trade!",
        },
        "p11": {
            "zhcn": "領取測試 TBNB",
            "en": "Claim testnet TBNB",
        },
        "p12": {
            "zhcn": "您將必須领取測試 TBNB 以支付Gas費。",
            "en": "You will have to claim testnet TBNB for gas fee.",
        },
        "p13": {
            "zhcn": "使用 BNB Chain 水龍頭",
            "en": "Using the BNB Chain faucet",
        },
        "p14": {
            "zhcn": "領取其他測試代幣",
            "en": "Claim other testnet Tokens",
        },
        "p15": {
            "zhcn": "平台目前只提供TBBZ測試代幣領取，如需領取其他的測試幣，請進入相關網站或交易所進行領取購買。",
            "en": "The platform currently only provides TBBZ testnet tokens for collection. If you need to receive other testnet tokens, please enter the relevant website or exchange to collect and purchase.",
        },
        "p16": {
            "zhcn": "領取測試 ETH",
            "en": "Claim testnet ETH",
        },
        "p17": {
            "zhcn": "您將必須领取測試 ETH 以支付Gas費。",
            "en": "You will have to claim testnet ETH for gas fee.",
        },
        "p18": {
            "zhcn": "使用 Coinbase 水龍頭",
            "en": "Using the Coinbase faucet",
        },
        "p19": {
            "zhcn": "完成任務，領取測試幣",
            "en": "Complete the task and receive test coins",
        },
        "p191": {
            "zhcn": "1.產生推薦碼連結",
            "en": "1. Generate recommendation code link",
        },
        "p20": {
            "zhcn": "2.關注BBZ官方Twitter。",
            "en": "2. Follow BBZ official Twitter.",
        },
        "p21": {
            "zhcn": "關注BBZ官方推特",
            "en": "Follow BBZ official Twitter",
        },
        "p22": {
            "zhcn": "3.連接Twitter，發Twitter。",
            "en": "3. Connect to Twitter and tweet.",
        },
        "p23": {
            "zhcn": "連結你的推特",
            "en": "Link your Twitter",
        },
        "p24": {
            "zhcn": "1.關聯Twitter，發Twitter，可以直接領取3000，沒邀請一個人可領取500測試幣。 \n 2.持有xbt  持有.bnb .meta任何資產，可直接領取3000，每多持有一種就多領取3000。 \n 3.擁有friend.tech的key大於等於1個，即可領取3000測試幣。 \n",
            "en": "1. Connect to Twitter and post on Twitter to get 3,000 coins directly. If you don’t invite anyone, you can get 500 test coins. \n 2. Hold xbt. If you own any assets such as .bnb or .meta, you can directly receive 3,000. For each additional asset you hold, you will receive an additional 3,000. \n 3. If you have more than or equal to 1 key of friend.tech, you can receive 3,000 test coins. \n",
        },
        "p25": {
            "zhcn": "如何領取測試幣？",
            "en": "How to get test coins?",
        }
    },

    
    "db": {
        "p1": {
            "zhcn": "儀表板",
            "en": "Dashboard",
        },
        "p2": {
            "zhcn": "統計數據",
            "en": "Total Stats",
        },
        "p3": {
            "zhcn": "Arbitrum Goerli 總覽",
            "en": "Arbitrum Goerli Overview",
        },
        "p31": {
            "zhcn": "BNB Chain 總覽",
            "en": "BNB Chain Overview",
        },
        "p32": {
            "zhcn": "BASE 總覽",
            "en": "BASE Overview",
        },
        "p4": {
            "zhcn": "總交易額",
            "en": "Total Volume",
        },
        "p5": {
            "zhcn": "24小時交易額",
            "en": "24h Volume",
        },
        "p6": {
            "zhcn": "總費用",
            "en": "Total Fees",
        },
        "p7": {
            "zhcn": "24小時費用",
            "en": "24h Fees",
        },
        "p8": {
            "zhcn": "總交易次數",
            "en": "Total Trades",
        },
        "p9": {
            "zhcn": "日均交易額",	
            "en": "Average Daily Trades",
        },
        "p10": {
            "zhcn": "總交易用戶數量",
            "en": "Total Traders",
        },
        "p11": {
            "zhcn": "總池子數量",
            "en": "Total Pools",
        },
    },
    "creatpool": {
        "p1": {
            "zhcn": "創建交易池",
            "en": "Create trade pool",
        },
        "p2": {
            "zhcn": "代幣地址",
            "en": "Token address",
        },
        "p3": {
            "zhcn": "交易手續費",
            "en": "Trade Fees",
        },
        "p4": {
            "zhcn": "交易收益率",
            "en": "Trade rate of return",
        },
        "p5": {
            "zhcn": "最大交易比例",
            "en": "Maximum trade Ratio",
        },
        "p6": {
            "zhcn": "輸入代幣地址",
            "en": "Enter token address",
        }
    },
    "zq": {
        "p1": {
            "zhcn": "您没有可贖回的BBZ代幣。",
            "en": "You have no BBZ tokens to redeem.",
        },
        "p2": {
            "zhcn": "您沒有可提取的BBZ代幣",
            "en": "You have no BBZ tokens to withdraw",
        },
        "p3": {
            "zhcn": "提取成功",
            "en": "Successful withdrawal",
        },
        "p4": {
            "zhcn": "賺取",
            "en": "Earn",
        },
        "p5": {
            "zhcn": "參與交易和質押BBZ以獲得獎勵。",
            "en": "Participate in trading and stake BBZ to get rewards.",
        },
        "p6": {
            "zhcn": "價格",
            "en": "Price",
        },
        "p7": {
            "zhcn": "錢包",
            "en": "Wallet",
        },
        "p8": {
            "zhcn": "託管的BBZ",
            "en": "Hosted BBZ",
        },
        "p9": {
            "zhcn": "贖回中",
            "en": "Redeeming",
        },
        "p10": {
            "zhcn": "已贖回(可提取)",
            "en": "redeemed(withdrawable)",
        },
        "p11": {
            "zhcn": "已獲得獎勵",
            "en": "Awarded",
        },
        "p12": {
            "zhcn": "已獲得積分",
            "en": "Earned points",
        },
        "p13": {
            "zhcn": "購買BBZ",
            "en": "Buy BBZ",
        },
        "p14": {
            "zhcn": "質押",
            "en": "Staking",
        },
        "p15": {
            "zhcn": "贖回",
            "en": "Redemption",
        },
        "p16": {
            "zhcn": "提取",
            "en": "Withdraw",
        },
        "p17": {
            "zhcn": "領取獎勵",
            "en": "Claim award",
        },
        "p18": {
            "zhcn": "積分",
            "en": "Points",
        },
        "p19": {
            "zhcn": "領取",
            "en": "Claim",
        },
        "p20": {
            "zhcn": "領取",
            "en": "Claim",
        },
        "p21": {
            "zhcn": "質押100個BBZ，將獲得100個esBBZ。 1個esBBZ託管1年將獲得1個積分，1個積分代表著可再領取一份獎勵。",
            "en": "Stake 100 BBZ and get 100 esBBZ. 1 esBBZ hosting for 1 year will get 1 point, and 1 point means you can receive another reward.",
        },
        "p22": {
            "zhcn": "開始贖回後,esBBZ 代幣將每秒轉換為 BBZ,並將在 100 天內完全贖回。已轉換為 BBZ的代幣可以隨時提領。",
            "en": "After starting the redemption, esBBZ tokens will be converted to BBZ every second and will be fully redeemed within 100 days. Tokens that have been converted to BBZ can be withdrawn at any time.",
        },
        "p23": {
            "zhcn": "贖回",
            "en": "Redemption",
        },
        "p24": {
            "zhcn": "贖回數量:",
            "en": "Redemption Quantity:",
        },
        "p25": {
            "zhcn": "提取",
            "en": "Withdraw",
        },
        "p26": {
            "zhcn": "提取成功將自動轉入您的錢包地址中。",
            "en": "Successful withdrawal will be automatically transferred to your wallet address.",
        },
        "p27": {
            "zhcn": "領取",
            "en": "Claim",
        },
        "p28": {
            "zhcn": "提取數量:",
            "en": "Withdraw Quantity:",
        },
        "p29": {
            "zhcn": "領取",
            "en": "Claim:",
        },
        "p30": {
            "zhcn": "提取成功將自動轉入您的錢包地址中。",
            "en": "Successful withdrawal will be automatically transferred to your wallet address.",
        },
        "p31": {
            "zhcn": "可提取:",
            "en": "Withdraw:",
        },
        "p32": {
            "zhcn": "提取數量:",
            "en": "Withdraw Quantity:",
        },
        "p33": {
            "zhcn": "您沒有可提取的",
            "en": "You have nothing to withdraw",
        },
        "p34": {
            "zhcn": "您錢包裡的BBZ不足",
            "en": "Not enough BBZ in your wallet",
        },
        "p35": {
            "zhcn": "您沒有可贖回的BBZ代幣！",
            "en": "You have no BBZ tokens to redeem!",
        },
        "p36": {
            "zhcn": "您沒有可提取的BBZ代幣！",
            "en": "You have no BBZ tokens to withdraw!",
        },
        "p37": {
            "zhcn": "Uniswap的價格",
            "en": "Uniswap price",
        },
        "p38": {
            "zhcn": "交易挖礦獲得和主動質押的BBZ。",
            "en": "BBZ obtained through transaction mining and actively Staking.",
        },
        "p39": {
            "zhcn": "還在解鎖中的BBZ。",
            "en": "BBZ still unlocked.",
        },
        "p40": {
            "zhcn": "已解鎖的BBZ，可以直接提取。",
            "en": "Unlocked BBZ can be withdrawn directly.",
        },
        "p41": {
            "zhcn": "根據託管的esBBZ的時間獲得獎勵積分。",
            "en": "Earn bonus points based on time spent hosting esBBZ",
        },
        "p42": {
            "zhcn": "所有",
            "en": "All",
        },
        "p43": {
            "zhcn": "交易手續費返還獎勵",
            "en": "Transaction Fee Rebate Rewards",
        },
        "p44": {
            "zhcn": "餘額:",
            "en": "Balance:",
        },
        "p45": {
            "zhcn": "數量",
            "en": "Quantity:",
        },
        "p46": {
            "zhcn": "積分",
            "en": "Points:",
        }
    },
    "error" : {
        "p1" : {
            "zhcn": "金額不足。",
            "en": "Insufficient amount.",
        },
        "p2" : {
            "zhcn": "金額不足。",
            "en": "Insufficient amount.",
        },
        "p3" : {
            "zhcn": "提現金額不足。",
            "en": "Insufficient withdrawal amount.",
        },
        "p4" : {
            "zhcn": "交易錯誤！",
            "en": "Transaction error!",
        },
        "p5" : {
            "zhcn": "交易錯誤！",
            "en": "Transaction error!",
        },
        "p6" : {
            "zhcn": "下單金額太大。",
            "en": "The order amount is too large.",
        },
        "p7" : {
            "zhcn": "下單金額太大。",
            "en": "The order amount is too large.",
        },
        "p8" : {
            "zhcn": "下單金額太小。",
            "en": "The order amount is too small.",
        },
        "p9" : {
            "zhcn": "已超過最大下單數量",
            "en": "The maximum order quantity has been exceeded.",
        },
        "p10" : {
            "zhcn": "請稍後交易！",
            "en": "Please trade later!",
        },
        "p11" : {
            "zhcn": "交易錯誤！",
            "en": "Transaction error!",
        },
        "p12" : {
            "zhcn": "訂單不存在。",
            "en": "Order does not exist.",
        },
        "p13" : {
            "zhcn": "訂單價格未取得價格。",
            "en": "The order price has not been obtained.",
        },
        "p14" : {
            "zhcn": "訂單不存在。",
            "en": "Order does not exist.",
        },
        "p15" : {
            "zhcn": "積分訂單正在進行中，不允許退單。",
            "en": "The order is in progress and no return is allowed.",
        },
        "p16" : {
            "zhcn": "訂單正在進行中，不允許退單。",
            "en": "The order is in progress and no return is allowed.",
        },
        "p17" : {
            "zhcn": "您沒有可提取的獎勵！",
            "en": "You have no rewards to withdraw!",
        },
        "p18" : {
            "zhcn": "授權失敗。",
            "en": "Authorization failed.",
        },
        "p19" : {
            "zhcn": "传入的collateralToken地址是0地址",
            "en": "ZERO_ADDRESS!",
        },
        "p20" : {
            "zhcn": "传入的collateralToken地址不是erc20地址",
            "en": "not support",
        },
        "p21" : {
            "zhcn": "工厂合约没有配置",
            "en": "factoryConfig is not initialize",
        },
        "p22" : {
            "zhcn": "建立的池子位址是0位址",
            "en": "CREATE2_FAILED",
        },
        "p23" : {
            "zhcn": "請填寫池子地址",
            "en": "Please fill in the pool address",
        },
        "p24" : {
            "zhcn": "請填寫最大交易金額比例",
            "en": "Please fill in the maximum transaction amount ratio",
        },
        "p25" : {
            "zhcn": "最大值為10000",
            "en": "The maximum value is 10000",
        },
        "p26" : {
            "zhcn": "暫無獎勵",
            "en": "No rewards yet",
        },
        "p27" : {
            "zhcn": "错误，請輸入ERC20代幣合约地址",
            "en": "Error, please enter the ERC20 token contract address",
        },
        "p28" : {
            "zhcn": "Gas費太低，交易失敗。",
            "en": "The gas fee is too low and the transaction fails.",
        },
        "p29" : {
            "zhcn": "賬戶餘額不足，不夠支付本次交易費用（Gas + Value）",
            "en": "Insufficient account balance to cover the transaction fee (Gas + Value)",
        }
    },
    "textTip": {
        'p1': {
            "zhcn": "請輸入ERC20代幣地址",
            "en": "Please enter the ERC20 token address",
        },
        "p2": {
            "zhcn": "複製",
            "en": "Replicating",
        },
    },
    "inv":{
        'tip1': {
            "zhcn": "邀請碼填寫成功",
            "en": "Invitation code filled in successfully",
        },
        'tip2': {
            "zhcn": "邀請碼創建成功",
            "en": "Invitation code created successfully",
        },
        'tip3': {
            "zhcn": "請填寫邀請碼",
            "en": "Please fill in the invitation code",
        },
        'tip4': {
            "zhcn": "該邀請碼無效",
            "en": "The invitation code is invalid",
        },
        'tip5': {
            "zhcn": "請填寫你要創建的邀請碼",
            "en": "Please fill in the invitation code you want to create",
        },
        'tip6': {
            "zhcn": "該邀請碼已被創建",
            "en": "The invitation code has been created",
        },
        'p1': {
            "zhcn": "推薦",
            "en": "Referrals",
        },
        "p2": {
            "zhcn": "透過BBZ推薦計畫獲得空投盲盒和更多獎勵。\n有效推薦必須由被推薦者連結到Twitter，推薦人和被推薦人都能獲得獎勵。",
            "en": "Get airdrop blind boxes and more rewards through the BBZ referral program.\nValid recommendations must be linked to Twitter by the recommended person, and both the recommender and the recommended person can receive rewards.",
        },
        'p3': {
            "zhcn": "使用推薦",
            "en": "Use Referral",
        },
        'p4': {
            "zhcn": "您的推薦",
            "en": "Your Referral",
        },
        'p5': {
            "zhcn": "輸入推薦代碼",
            "en": "Enter referral code",
        },
        'p6': {
            "zhcn": "請輸入推薦代碼，以獲得折扣",
            "en": "Please enter referral code to receive discount",
        },
        'p7': {
            "zhcn": "輸入推薦碼",
            "en": "Enter referral code",
        },
        'p8': {
            "zhcn": "輸入推薦碼",
            "en": "Enter referral code",
        },
        'p9': {
            "zhcn": "已被邀請",
            "en": "Has been invited",
        },
        'p10': {
            "zhcn": "產生推薦代碼",
            "en": "Generate referral code",
        },
        'p11': {
            "zhcn": "看來你沒有推薦代碼可以分享。 \n創建一個，開始賺取盲盒！",
            "en": "It looks like you don't have a referral code to share. \nCreate one and start earning Mystery Boxes!",
        },
        'p12': {
            "zhcn": "輸入代碼",
            "en": "Enter code",
        },
        'p13': {
            "zhcn": "輸入代碼",
            "en": "Enter code",
        },
        'p14': {
            "zhcn": "推薦總人數",
            "en": "Referral total number of people",
        },
        'p15': {
            "zhcn": "複製您的推薦碼",
            "en": "Copy your referral code",
        },
        'p16': {
            "zhcn": "複製",
            "en": "Copy",
        },
        'p17': {
            "zhcn": "分享至Twitter",
            "en": "Share To Twitter",
        },
        'p18': {
            "zhcn": "我在@bbztrade等你，有機會領取總價值2000萬的空投盲盒！\n你也可以領取哦！\n在此領取您的盲盒，獲得 $BBZ空投機會。",
            "en": "I'm waiting for you at @bbztrade for a chance to receive an airdrop Mystery box with a total value of 20 million!\nYou can also get it!\nClaim your Mystery box here and get the chance to get a $BBZ airdrop.",
        }
    },
    "box":{
        'p1': {
            "zhcn": "我的盲盒",
            "en": "My Mystery Box",
        },
        'p2': {
            "zhcn": "我的積分",
            "en": "My Points",
        },
        'p3': {
            "zhcn": "如何獲得盲盒",
            "en": "How to get a Mystery Box",
        },
        'p4': {
            "zhcn": "持有資產",
            "en": "Holding assets",
        },
        'p5': {
            "zhcn": "在BASE，根據friend.tech的key來發放盲盒，自己的key價格越高就有機會獲得更多的盲盒，但是也要遵從公平原則。 \n\nBNB Chain持有xbt .bnb .meta，其中任何一種資產都可以獲得盲盒，持有多個資產，也有可能獲得更多的盲盒，盲盒隨機發放。",
            "en": "On the BASE, Mystery Boxes are distributed based on the key of friend.tech. The higher the price of your key, the more Mystery Boxes you have the opportunity to obtain, but the principle of fairness must also be followed.\n\nThe BNB chain holds xbt.bnb.meta, and any of the assets can obtain Mystery Boxes. If you hold multiple assets, you may also obtain more Mystery Boxes, and the Mystery Boxes will be randomly distributed.",
        },
        'p6': {
            "zhcn": "推薦好友獎勵",
            "en": "Reward for referring friends",
        },
        'p7': {
            "zhcn": "推薦一個人獲得3個盲盒獎勵，被推薦人必須綁定推特才算有效推薦。",
            "en": "Recommend a person and get 3 Mystery box rewards. The recommended person must bind to Twitter to be considered a valid recommendation.",
        },
        'p8': {
            "zhcn": "推薦",
            "en": "Referral",
        },
        'p9': {
            "zhcn": "賺積分",
            "en": "Earn points",
        },
        'p10': {
            "zhcn": "交易得積分",
            "en": "Points for transactions",
        },
        'p11': {
            "zhcn": "每交易一次得3積分",
            "en": "3 point for each transaction",
        },
        'p12': {
            "zhcn": "交易",
            "en": "Trade",
        },
        'p13': {
            "zhcn": "質押得積分",
            "en": "Points earned from staking",
        },
        'p14': {
            "zhcn": "每質押一次得4積分",
            "en": "Earn 4 points for each pledge",
        },
        'p15': {
            "zhcn": "質押",
            "en": "Staking",
        },
        'p16': {
            "zhcn": "建立池子得積分",
            "en": "Earn points for creating a pool",
        },
        'p17': {
            "zhcn": "每創建一次得5積分",
            "en": "5 points for each creation",
        },
        'p18': {
            "zhcn": "創建",
            "en": "Create",
        },
        'p19': {
            "zhcn": "領取挖礦收益得積分",
            "en": "Receive points for mining earnings",
        },
        'p20': {
            "zhcn": "每領取一次得3積分",
            "en": "Earn 3 point each time you receive it",
        },
        'p21': {
            "zhcn": "領取",
            "en": "Claim",
        },
        'p22': {
            "zhcn": "每日登入",
            "en": "Daily login",
        },
        'p23': {
            "zhcn": "每日登入得2積分",
            "en": "Login every day to get 2 point",
        },
        'p24': {
            "zhcn": "登入",
            "en": "Login",
        },
        'p241': {
            "zhcn": "已登錄",
            "en": "Logged in",
        },
        'p25': {
            "zhcn": "交易",
            "en": "Trade",
        },
        'p26': {
            "zhcn": "質押",
            "en": "Staking",
        },
        'p27': {
            "zhcn": "建立池子",
            "en": "",
        },
        'p28': {
            "zhcn": "领取挖矿",
            "en": "Create a pool",
        },
        'p29': {
            "zhcn": "每日登入",
            "en": "Daily login",
        },
        'p30': {
            "zhcn": "恭喜你獲得",
            "en": "Congratulations on getting",
        },
        'p31': {
            "zhcn": "需要10個積分才能打開盲盒",
            "en": "It takes 10 points to open the Mystery Box",
        },
        'p32': {
            "zhcn": "藍色盲盒",
            "en": "Blue Mystery Box",
        },
        'p33': {
            "zhcn": "紫色盲盒",
            "en": "Purple Mystery Box",
        },
        'p34': {
            "zhcn": "橘色盲盒",
            "en": "Orange Mystery Box",
        },
        'p35': {
            "zhcn": "未開啟",
            "en": "Unopened",
        },
        'p36': {
            "zhcn": "你的積分",
            "en": "Your scores",
        },
        'p37': {
            "zhcn": "打開",
            "en": "Open",
        },
        'p38': {
            "zhcn": "積分不足",
            "en": "Not enough points",
        },
        'p39': {
            "zhcn": "獎勵",
            "en": "Award",
        },
        'p40': {
            "zhcn": "活躍",
            "en": "Active",
        },
        'p41': {
            "zhcn": "開盲盒",
            "en": "Open Mystery Box",
        },
        'p42': {
            "zhcn": "領取測試代幣",
            "en": "Claim testnet Tokens",
        },
        'p43': {
            "zhcn": "推薦朋友\n賺取獎勵！",
            "en": "Referral friends\nEarn rewards!",
        },
        'p44': {
            "zhcn": "謝謝@bbztrade\n哇，我得到了  ",
            "en": "Thanks @bbztrade\nWow, I got  ",
        },
        'p45': {
            "zhcn": " 個盲盒 ，還可以領取 ",
            "en": " Mystery Boxes and can also receive ",
        },
        'p46': {
            "zhcn": " TUSDT測試幣！\n你也可以領取哦！\n在此領取您的盲盒，獲得 $BBZ空投機會。",
            "en": " TUSDT test coins!\nYou can also get it!\nClaim your Mystery Box here and get the chance to get a $BBZ airdrop.",
        },
        'p47': {
            "zhcn": "猜猜你能領多少盲盒和測試幣？",
            "en": "Guess how many Mystery Boxes and test coins you can receive?",
        },
        'p48': {
            "zhcn": "歡迎來到 BBZ - 去中心化特殊期權交易平台",
            "en": "Welcome to BBZ - Decentralized Special Options Trading Platform",
        },
        'p49': {
            "zhcn": "連結錢包領取",
            "en": "Connect wallet collection",
        },
        'p50': {
            "zhcn": "領取",
            "en": "Claim",
        },
        'p51': {
            "zhcn": "請登入 Twitter 繼續",
            "en": "Please login to Twitter to continue",
        },
        'p52': {
            "zhcn": "連結錢包",
            "en": "Connect Wallet",
        },
        'p53': {
            "zhcn": "綁定推特",
            "en": "Bind Twitter",
        },
        'p54': {
            "zhcn": "恭喜你",
            "en": "Congratulations",
        },
        'p55': {
            "zhcn": "您就有資格獲得盲盒和測試幣",
            "en": "You are eligible for Mystery Box and test coins",
        },
        'p56': {
            "zhcn": "領取：",
            "en": "Can claim:",
        },
        'p57': {
            "zhcn": "分享並@3位朋友\n領取獎勵",
            "en": "Share and @3 friends\nClaim award",
        },
        'p58': {
            "zhcn": "領取",
            "en": "Claim",
        },
        'p59': {
            "zhcn": "分享至 Twitter 以獲得更多",
            "en": "Share to Twitter for more",
        },
        'p60': {
            "zhcn": "謝謝@bbztrade\n哇，我獲得了 ",
            "en": "Thanks @bbztrade\nWow, I got ",
        },
        'p61': {
            "zhcn": " BBZ 空投 。\n你也可以領取哦！\n在此領取您的盲盒，獲得 $BBZ空投機會。 ",
            "en": " BBZ airdrop. \nYou can also get it!\nClaim your Mystery Box here and get the chance to get a $BBZ airdrop. ",
        }
    },
    "rank": {
        'p1': {
            "zhcn": "領取盲盒排名",
            "en": "Claim Mystyle Box ranking",
        },
        'p2': {
            "zhcn": "看全部",
            "en": "View all",
        },
        'p3': {
            "zhcn": "排名",
            "en": "Rank",
        },
        'p4': {
            "zhcn": "使用者位址",
            "en": "User Address",
        },
        'p5': {
            "zhcn": "盲盒",
            "en": "Mystery Box",
        }
    }

}

export default newlocal




