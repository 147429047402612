
import MainNew from  '../pages/mainnewH5';
import Cz from  '../pages/czH5';
import Lb from  '../pages/lbH5';
import Db from  '../pages/dbH5';
import Zq from  '../pages/zqH5';
import Inv from  '../pages/invH5';
import Box from '../pages/boxH5'
import OpenBox from '../pages/openboxH5'
import MyScores from '../pages/myscoresH5'
import Rank from '../pages/rankH5'

const routerList = [
    {
        path: '',
        breadcrumbName:  '首页',
        component: MainNew,
        private: false
    },
    {
        path: 'pools',
        breadcrumbName:  '池子',
        component: Cz,
        private: false
    },
    {
        path: 'airdrop',
        breadcrumbName:  '领币',
        component: Lb,
        private: false
    },
    {
        path: 'dashboard',
        breadcrumbName:  '分析',
        component: Db,
        private: false
    },
    {
        path: 'earn',
        breadcrumbName:  '赚取',
        component: Zq,
        private: false
    },
    {
        path: 'referrals',
        breadcrumbName:  'inv',
        component: Inv,
        private: false
    },
    {
        path: 'blindbox',
        breadcrumbName:  'box',
        component: Box,
        private: false
    },
    {
        path: 'openbox',
        breadcrumbName:  'openbox',
        component: OpenBox,
        private: false
    },
    {
        path: 'myscores',
        breadcrumbName:  'myscores',
        component: MyScores,
        private: false
    },
    {
        path: 'rank',
        breadcrumbName:  'rank',
        component: Rank,
        private: false
    }
]

export default routerList;