
import MainNew from  '../pages/mainnew';
import Cz from  '../pages/cz';
import Lb from  '../pages/lb';
import Db from  '../pages/db';
import Zq from  '../pages/zq';
import Inv from  '../pages/inv';
import Box from '../pages/box'
import OpenBox from '../pages/openbox'
import MyScores from '../pages/myscores'
import Rank from '../pages/rank'

const routerList = [
    {
        path: '',
        breadcrumbName:  '首页',
        component: MainNew,
        private: false
    },
    {
        path: 'pools',
        breadcrumbName:  '池子',
        component: Cz,
        private: false
    },
    {
        path: 'airdrop',
        breadcrumbName:  '领币',
        component: Lb,
        private: false
    },
    {
        path: 'dashboard',
        breadcrumbName:  '分析',
        component: Db,
        private: false
    },
    {
        path: 'earn',
        breadcrumbName:  '赚取',
        component: Zq,
        private: false
    },
    {
        path: 'referrals',
        breadcrumbName:  'inv',
        component: Inv,
        private: false
    },
    {
        path: 'blindbox',
        breadcrumbName:  'box',
        component: Box,
        private: false
    },
    {
        path: 'openbox',
        breadcrumbName:  'openbox',
        component: OpenBox,
        private: false
    },
    {
        path: 'myscores',
        breadcrumbName:  'myscores',
        component: MyScores,
        private: false
    },
    {
        path: 'rank',
        breadcrumbName:  'rank',
        component: Rank,
        private: false
    }
]

export default routerList;