import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import newlocal from '../../unilt/newlocal'
import linkData from '../../unilt/linkTypeData'
import emitter from '../../unilt/emit';
import dayjs from 'dayjs';
import axios from '../../unilt/axios';
import wancheng from '../../static/h5/wancheng.png'
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import Clipboard from 'clipboard'
import copyw from '../../static/h5/copyw.png'

const jsonrc = [
  {
    "type": "function",
    "name": "isEfficientCode",
    "inputs": [{ "name": "code", "type": "string" }],
    "outputs": [{ "name": "end", "type": "boolean" }]
  },
  {
    "type": "function",
    "name": "getCreateCode",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "code", "type": "string" }]
  },
  {
    "type": "function",
    "name": "getBeInviteCode",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "code", "type": "string" }]
  },
  {
    "type": "function",
    "name": "getInviteFriends",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'user',
          type: 'address',
        }
      ],
    }]
  },
  {
    "type": "function",
    "name": "getUserBeInvite",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "user", "type": "address" }]
  },
  {
    "type": "function",
    "name": "createCode",
    "inputs": [{ "name": "code", "type": "string" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "setInviteCode",
    "inputs": [{ "name": "code", "type": "string" }],
    "outputs": []
  }
]
const jsonrc1 = [
  {
    "type": "function",
    "name": "mint",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getResidueAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getTotalAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getAlreadyMintAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
]


var Web3 = require('web3')
let oldtype = 0
let islock = false
let oldtab = 1
let provider = false

function Inv() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const nprovider = useSelector(state => state.nprovider)
  const signtoken = useSelector(state => state.signtoken)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  const rendertabRef = useRef(true)
  const renderuserRef = useRef(true)
  const renderlinktypeRef = useRef(true)

  const [tab, setatab] = useState(2)
  const [conttype, setconttype] = useState(1)
  const [code1, setcode1] = useState('')
  const [code2, setcode2] = useState('')
  const [invnum, setinvnum] = useState(0)

  const [pageInfo, setpageInfo] = useState(false)

  const [boxn, setboxn] = useState(0)
  const [amount, setamount] = useState(0)


  useEffect(() => {
    provider = nprovider
  }, [nprovider])



  useEffect(() => {
    if (renderRef.current) {
      oldtype = 0
      renderRef.current = false
      islock = true
      return
    }
  }, [])



  useEffect(() => {
    if (renderuserRef.current) {
      renderuserRef.current = false
    } else {
      if (user === false || user === null || user === 'null') {

      } else {
        if (islock) {
          getB()
          getdbstart()
        }
      }
    }

  }, [user])


  useEffect(() => {
    if (renderlinktypeRef.current) {
      renderlinktypeRef.current = false
    } else {
      if (oldtype !== linktype) {
        oldtype = linktype
        reloadPage('new')
      }
    }
  }, [linktype])

  const reloadPage = ((type = 'old') => {
    setTimeout(() => {
      getB()
      getdbstart()
    }, 1000)
  })


  const getdbstart = (() => {

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }





    if (user === false || user === null || user === 'null') {

    } else {

      let str = ''
      switch (language) {
        case 'zhcn': str = '正在加載...'; break;
        case 'en': str = 'Loading...'; break;
        default: break;
      }
      message.loading({ content: str, key: 'czlodaingg', duration: 0 });
      getuserinfo(true)
      getbox()
    }
  })

  const getuserinfo = (async (ispd = false) => {
    let that = this
    let re = axios({
      url: 'active/user/info',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setpageInfo(res.content)
        getinvnumber()
        if (ispd) {
          if (oldtab === 1) {
            checkmodetab1(res.content)
          } else {
            checkmodetab2(res.content)
          }
        }
      } else {
        message.destroy('czlodaingg')
      }
    })
  })

  const getinvnumber = (() => {
    let that = this
    let re = axios({
      url: 'active/invite/number',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setinvnum(res.content)
      } else {
        message.destroy('czlodaingg')
      }
    })
  })

  useEffect(() => {
    oldtab = tab

    if (tab === 2) {
      if (user === false || user === null || user === 'null') {
        setconttype(3)
      }

      if (signtoken === false || signtoken === null || signtoken === 'null') {
        setconttype(3)
      }
    }

    if (tab === 1) {
      if (user === false || user === null || user === 'null') {
        setconttype(1)
      }

      if (signtoken === false || signtoken === null || signtoken === 'null') {
        setconttype(1)
      }
    }

    if (user === false || user === null || user === 'null') {

    } else {
      getdbstart()
    }

  }, [tab])

  useEffect(() => {
    getB()
    if (signtoken === false || signtoken === null || signtoken === 'null') {

    } else {
      getdbstart()
    }

  }, [signtoken])

  const checkChainId = (async () => {
    let chainId = `0x${chain.id.toString(16)}`

    if (chainId !== linkData[linktype.toString()].lid) {
      emitter.emit("checkcid", "Hello")
      return false
    } else {
      return true
    }
  })


  const checkmodetab1 = (async (info) => {
    if (info.isInvite === 0) {
      setconttype(1)
      message.destroy('czlodaingg')
    } else {
      setconttype(2)
      message.destroy('czlodaingg')
    }
  })

  const checkmodetab2 = (async (info) => {
    if (!info.inviteCode) {
      setconttype(3)
      message.destroy('czlodaingg')
    } else {
      setcode2(info.inviteCode)
      setconttype(4)
      message.destroy('czlodaingg')
    }
  })

  const goinputcode = (async () => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }



    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }


    if (code1 === '') {
      message.error({ content: newlocal['inv']['tip3'][language], key: 'trangerror' })
      return false
    }
    let re = axios({
      url: 'active/invite/code',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: code1
      }
    })
    re.then(async (res) => {
      if (res.success === '000032') {
        goinputcodeend()
      } else {
        message.error({ content: newlocal['inv']['tip4'][language], key: 'trangerror' })
      }
    })
  })

  const goinputcodeend = (() => {
    let re = axios({
      url: 'active/invite/inputcode',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: code1
      }
    })
    re.then(async (res) => {
      message.success({ content: <div>{newlocal['inv']['tip1'][language]}</div>, duration: 3 });
      getdbstart()
    })
  })



  const gocreatcode = (async () => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }


    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    if (code2 === '') {
      message.error({ content: newlocal['inv']['tip5'][language], key: 'trangerror' })
      return false
    }

    let re = axios({
      url: 'active/invite/code',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: code2
      }
    })
    re.then(async (res) => {
      if (res.success === '000032') {
        message.error({ content: newlocal['inv']['tip6'][language], key: 'trangerror' })
      } else {
        let csflag = false
        let isres = false
        var contractAddress = linkData[linktype.toString()].invaddress;
        message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
        let gaslimit = 1000000
        try {
          gaslimit = await publicClient.estimateContractGas({
            address: contractAddress,
            abi: jsonrc,
            functionName: 'createCode',
            account: user,
            args: [code2]
          })
        } catch (e) {
          message.destroy('trange')

          goMessor(e)
          return false
        }
        const gasPrice = await publicClient.getGasPrice()
        const nonce = await publicClient.getTransactionCount({ address: user });
        await Promise.race([
          setTimeout(async () => {
            let srequest = false
            try {
              const { request } = await publicClient.simulateContract({
                address: contractAddress,
                abi: jsonrc,
                functionName: 'createCode',
                account: user,
                args: [code2],
                gas: gaslimit,
                gasPrice: gasPrice,
                nonce: nonce
              })
              isres = true
              srequest = request
            } catch (err) {
              csflag = true
              goMessor(err)
              message.destroy('trange')
              return false
            }
            try {
              const hash = await walletClient.writeContract(srequest)
              isres = true
              let errortime = Number(dayjs().add(20, 'second').unix())
              getlistenJy(hash, 2, errortime)
            } catch (err) {
              csflag = true
              goMessor(err)
              message.destroy('trange')
            }
          }, 500),
          new Promise(function (resolve, reject) {
            setTimeout(function () {
              message.destroy('trange')
              if (!csflag && !isres) {
                message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
                csflag = true
              }
            }, 30000);
          })
        ]);
      }
    })
  })


  const getlistenJy = (async (result, type, errortime) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var receipt = await web3.eth.getTransactionReceipt(result)
    let nowtime = Number(dayjs().unix())
    console.log('listen-hash', result, receipt)
    if (nowtime > errortime) {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    } else if (receipt === null) {
      getlistenJy(result, type, errortime)
    } else if (receipt.status === true) {
      message.destroy('trange')
      if (type === 1) {
        message.success({ content: <div>{newlocal['inv']['tip1'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        getdbstart()
      }
      if (type === 2) {
        message.success({ content: <div>{newlocal['inv']['tip2'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setTimeout(() => {
          getdbstart()
          window.location.reload()
        }, 1000)
      }
    } else {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['zq']['p3'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }
  })

  const gojypage = ((link) => {
    window.open(`${linkData[linktype.toString()].txklink}${link}`)
  })


  const goMessor = ((error) => {
    let msg = error.message
    console.error(msg)
    if (msg.indexOf('insufficient allowance') >= 0) {
      message.error({ content: newlocal['error']['p1'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('transfer amount exceeds balance') >= 0) {
      message.error({ content: newlocal['error']['p2'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('withdraw amount too much!') >= 0) {
      message.error({ content: newlocal['error']['p3'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('marketId not exist') >= 0) {
      message.error({ content: newlocal['error']['p4'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('intervalId not exist') >= 0) {
      message.error({ content: newlocal['error']['p5'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('total Order Amount is too large') >= 0) {
      message.error({ content: newlocal['error']['p6'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too large') >= 0) {
      message.error({ content: newlocal['error']['p7'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too small') >= 0) {
      message.error({ content: newlocal['error']['p8'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('too many remaining orders') >= 0) {
      message.error({ content: newlocal['error']['p9'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('have the same order') >= 0) {
      message.error({ content: newlocal['error']['p10'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('execution fee is too low') >= 0) {
      message.error({ content: newlocal['error']['p11'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p12'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is not ready') >= 0) {
      message.error({ content: newlocal['error']['p13'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p14'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order running') >= 0) {
      message.error({ content: newlocal['error']['p15'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is ready') >= 0) {
      message.error({ content: newlocal['error']['p16'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('reward amount is zero') >= 0) {
      message.error({ content: newlocal['error']['p17'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('getRewards approve error') >= 0) {
      message.error({ content: newlocal['error']['p18'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('ZERO_ADDRESS!') >= 0) {
      message.error({ content: newlocal['error']['p19'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('not support') >= 0) {
      message.error({ content: newlocal['error']['p20'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('factoryConfig is not initialize') >= 0) {
      message.error({ content: newlocal['error']['p21'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('CREATE2_FAILED') >= 0) {
      message.error({ content: newlocal['error']['p22'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('The total cost (gas * gas fee + value) of executing this transa') >= 0) {
      message.error({ content: newlocal['error']['p29'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('User denied transaction signature') >= 0) {
      message.error({ content: newlocal['main']['p70'][language], key: 'trangerrorss', duration: 3 })
    } else {
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
    }
  })



  const copyToClipboard = ((value) => {
    var text = value;
    var input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    message.success({ content: newlocal['main']['copytip'][language], key: 'sdff' })
  })

  const getB = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].lbaddresstusdt;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getResidueAmount().call()
    setamount(Number(res) / (10 ** 18))
  })


  const getbox = (() => {
    let re = axios({
      url: 'active/box/select',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      let list = res.content
      setboxn(list.length)
    })
  })



  const goShare = (() => {
    let hashtags = ['bbz']
    let msg = `${newlocal['inv']['p18'][language]}}`
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(msg)}&hashtags=${hashtags.join(',')}&url=${'https://tradertest.bbz.com/airdrop?invcode=' + pageInfo.inviteCode}`, "_blank",);
  })



  return (
    <div className="inv" >
      <div className='title'>{newlocal['inv']['p1'][language]}</div>
      <pre className='title1'>{`${newlocal['inv']['p2'][language]}`}</pre>
      <div className='contbox'>
        <div className='tabbox'>
          <div className={tab === 2 ? 'tab active' : 'tab'} onClick={() => { setatab(2) }}>{newlocal['inv']['p4'][language]}</div>
          <div className={tab === 1 ? 'tab active' : 'tab'} onClick={() => { setatab(1) }}>{newlocal['inv']['p3'][language]}</div>
        </div>
        {
          tab === 1 && conttype === 1 && <div className='editContBox m-t70'>
            <pre className='p3'>{newlocal['inv']['p5'][language]}</pre>
            <pre className='p4 m-t11'>{newlocal['inv']['p6'][language]}</pre>
            <input className='inp1 m-t49' value={code1} placeholder={newlocal['inv']['p7'][language]} onChange={(e) => { setcode1(e.currentTarget.value) }} />
            <div className='btn1 m-t77' onClick={() => goinputcode()}>{newlocal['inv']['p8'][language]}</div>
          </div>
        }
        {
          tab === 1 && conttype === 2 && <div className='editContBox m-t140'>
            <img className='i7' src={wancheng} />
            <pre className='p6 m-t33'>{newlocal['inv']['p9'][language]}</pre>
          </div>
        }
        {
          tab === 2 && conttype === 3 && <div className='editContBox m-t60'>
            <pre className='p3'>{newlocal['inv']['p10'][language]}</pre>
            <pre className='p4 m-t11'>{newlocal['inv']['p11'][language]}</pre>
            <input className='inp1 m-t38' value={code2} placeholder={newlocal['inv']['p12'][language]} onChange={(e) => { setcode2(e.currentTarget.value) }} />
            <div className='btn1 m-t87' onClick={() => gocreatcode()}>{newlocal['inv']['p13'][language]}</div>
          </div>
        }
        {
          tab === 2 && conttype === 4 && <div className='editContBox m-t70'>
            <pre className='p4'>{newlocal['inv']['p14'][language]}</pre>
            <pre className='p5 m-t33'>{Number(invnum).toLocaleString('EN-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</pre>
            <div className='bbox'>
              <pre className='p7'>{newlocal['inv']['p15'][language]}</pre>
              <div className='abtnbox'>
                <div className='abtn'>
                  <div className='fl1'>{window.location.origin + '/airdrop' + '?invcode=' + code2}</div>
                  <img className='copyimg' onClick={() => { copyToClipboard(window.location.origin + '/airdrop' + '?invcode=' + code2) }} src={copyw} />
                </div>
                <div className='sharebtn' onClick={()=>goShare()}>{newlocal['inv']['p17'][language]}</div>
              </div>

            </div>
          </div>
        }
      </div>
    </div>

  );
}

export default Inv;
