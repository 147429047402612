import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover, Avatar, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import newlocal from '../../unilt/newlocal'
import emitter from '../../unilt/emit';
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import { useNavigate, useLocation, useParams, useSearchParams, useRouteError } from "react-router-dom";
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import axios from '../../unilt/axios';
import close from '../../static/close.png'


import rank1 from '../../static/h5/rank1.png'
import rank2 from '../../static/h5/rank2.png'
import rank3 from '../../static/h5/rank3.png'



var Web3 = require('web3')
let lbTime = 0

const jsonrc1 = [
  {
    "type": "function",
    "name": "mint",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getResidueAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getTotalAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getAlreadyMintAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
]

const jsonrc2 = [
  {
    "type": "function",
    "name": "createPool",
    "inputs": [{ "name": "collateralToken", "type": "address" }, { "name": "treasuryFee", "type": "uint256" }, { "name": "winningRate", "type": "uint256" }, { "name": "maxOrderAmountFraction", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "queryPoolAddress",
    "inputs": [{ "name": "collateralToken", "type": "address" }],
    "outputs": [{ "name": "address", "type": "address" }]
  },
  {
    "type": "function",
    "name": "hasBeenDeployed",
    "inputs": [{ "name": "deploymentAddress", "type": "address" }],
    "outputs": [{ "name": "bool", "type": "bool" }]
  }
]


let provider = false
let islock = false
let czTime = 0
let userlistlength = 0


function Rank() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  const nprovider = useSelector(state => state.nprovider)
  const [pageInfo, setpageInfo] = useState(false)
  const renderuserRef = useRef(true)
  const signtoken = useSelector(state => state.signtoken)
  const [getParams, setParam] = useSearchParams()
  const [boxlist, setboxlist] = useState([])
  const [setitem, setsetitem] = useState(false)
  const [show, setshow] = useState(false)
  const typeC = useRef(false)




  let [rank, setrank] = useState([])

  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false
      getRankList()
      return
    }
  }, [])


  const getRankList = (() => {
    let str = ''
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    message.loading({ content: str, key: 'czlodaingg', duration: 0 });
    let re = axios({
      url: 'active/box/ranking',
      method: 'GET',
      isLetUrl: 1,
    })
    re.then(async (res) => {
      let list = res.content
      setrank(list)
      message.destroy('czlodaingg')
    })
  })



  return (
    <div className="rankH5" >
      <div className='title'>{newlocal['rank']['p1'][language]}</div>
      <div className='rankbox'>
        <div className='headerbox'>
        <div className='item1'>{newlocal['rank']['p3'][language]}</div>
            <div className='item2'>{newlocal['rank']['p4'][language]}</div>
            <div className='item3'>{newlocal['rank']['p5'][language]}</div>
        </div>
        {
          rank.map((item, index) => {
            return <div className='itembox'>
              <div className='item1'><div className='icobox'>{index === 0 && <img className='rankico' src={rank1}></img>}{index === 1 && <img className='rankico' src={rank2}></img>}{index === 2 && <img className='rankico' src={rank3}></img>}</div>{index + 1} </div>
              <div className='item2'>{item.address.substring(0, 7) + '...' + item.address.substring(item.address.length - 7, item.address.length)}</div>
              <div className='item3'>{Number(item.boxNum).toLocaleString('EN-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
            </div>
          })
        }
      </div>

    </div>
  );
}

export default Rank;
