import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover, Avatar } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import newlocal from '../../unilt/newlocal'
import emitter from '../../unilt/emit';
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import { useNavigate, useLocation, useParams, useSearchParams, useRouteError } from "react-router-dom";
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork, usePublicClient, useWalletClient } from 'wagmi'
import axios from '../../unilt/axios';

import linktwiter1 from '../../static/h5/linktwiter1.png'
import linktwiter2 from '../../static/h5/linktwiter2.png'
import wancheng from '../../static/h5/wancheng.png'
import h5r from '../../static/h5/h5r.png'

import lbh51 from '../../static/h5/lbh51.png'
import lbh52 from '../../static/h5/lbh52.png'
import lbh53 from '../../static/h5/lbh53.png'
import wanchengnew from '../../static/h5/wanchengnew.png'
import lbox from '../../static/h5/lbox.png'
import bcoin from '../../static/h5/bcoin.png'


var Web3 = require('web3')
let lbTime = 0


const jsonrc = [
  {
    "type": "function",
    "name": "isEfficientCode",
    "inputs": [{ "name": "code", "type": "string" }],
    "outputs": [{ "name": "end", "type": "boolean" }]
  },
  {
    "type": "function",
    "name": "getCreateCode",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "code", "type": "string" }]
  },
  {
    "type": "function",
    "name": "getBeInviteCode",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "code", "type": "string" }]
  },
  {
    "type": "function",
    "name": "getInviteFriends",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'user',
          type: 'address',
        }
      ],
    }]
  },
  {
    "type": "function",
    "name": "getUserBeInvite",
    "inputs": [{ "name": "user", "type": "address" }],
    "outputs": [{ "name": "user", "type": "address" }]
  },
  {
    "type": "function",
    "name": "createCode",
    "inputs": [{ "name": "code", "type": "string" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "setInviteCode",
    "inputs": [{ "name": "code", "type": "string" }],
    "outputs": []
  }
]

const jsonrc1 = [
  {
    "type": "function",
    "name": "mint",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getResidueAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getTotalAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getAlreadyMintAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
]

let provider = false
let islock = false


function Lb() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  const nprovider = useSelector(state => state.nprovider)
  const [pageInfo, setpageInfo] = useState(false)
  const renderuserRef = useRef(true)
  const signtoken = useSelector(state => state.signtoken)
  const [getParams, setParam] = useSearchParams()
  const [amount, setamount] = useState(0)
  const [gztw, setgztw] = useState(false)

  const [code2, setcode2] = useState('')

  const [step, setstep] = useState(1)
  const [boxn, setboxn] = useState(0)


  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false



      var hiddenProperty = 'hidden' in document ? 'hidden' :
        'webkitHidden' in document ? 'webkitHidden' :
          'mozHidden' in document ? 'mozHidden' : null;
      var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
      var onVisibilityChange = function () {
        if (!document[hiddenProperty]) {

          let now = Number(dayjs().unix())
          console.log(now)


          let endtimeshare = localStorage.getItem('endtimeshare') ? Number(localStorage.getItem('endtimeshare')) : 0

          if (now >= endtimeshare && endtimeshare !== 0) {

            if (user === false || user === null || user === 'null') {

            } else {
              localStorage.setItem('twshare' + user, 'ok')
              setgztw(true)
            }

          }

        } else {

        }
      }
      document.addEventListener(visibilityChangeEvent, onVisibilityChange);



      if (user === false || user === null || user === 'null') {

      } else {
        let now = Number(dayjs().unix())

        let endtimeshare = localStorage.getItem('endtimeshare') ? Number(localStorage.getItem('endtimeshare')) : 0

        if (now >= endtimeshare && endtimeshare !== 0) {

          if (user === false || user === null || user === 'null') {

          } else {
            localStorage.setItem('twshare' + user, 'ok')
            setgztw(true)
          }

        }


        const state = getParams.getAll('state')
        const code = getParams.getAll('code')
        if (state.length > 0 && code.length > 0) {
          setstep(2)
          gobindtwitter(state[0], code[0])
        }
        getB()
        getdbstart()
      }
      islock = true
      return
    }
  }, [])

  useEffect(() => {
    provider = nprovider
  }, [nprovider])

  const gobindtwitter = ((state, code) => {
    let re = axios({
      url: 'active/user/twitter/association',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        code: code,
        state: state
      }
    })
    re.then(async (res) => {
      document.getElementsByClassName("App")[0].scrollTop = 0
      navigate('/airdrop')
      getB()
      getdbstart()
    })
  })


  const checkChainId = (async () => {
    let chainId = `0x${chain.id.toString(16)}`

    if (chainId !== linkData[linktype.toString()].lid) {
      emitter.emit("checkcid", "Hello")
      return false
    } else {
      return true
    }
  })

  const golink = ((link) => {
    window.open(link)
  })


  useEffect(() => {


    if (user === false || user === null || user === 'null') {
      setgztw(false)
    } else {

      if (localStorage.getItem('isLingqu' + user,) === 'ok') {
        document.getElementsByClassName("AppH5")[0].scrollTop = 0
        navigate('/blindbox')
      }


      if (localStorage.getItem('twshare' + user) === 'ok') {
        setgztw(true)
      } else {
        setgztw(false)
      }
    }

    if (renderuserRef.current) {
      renderuserRef.current = false
    } else {

      const invcode = getParams.getAll('invcode')
      if (invcode.length > 0) {

        if (signtoken === false || signtoken === null || signtoken === 'null') {
          if (step === 2) {
            emitter.emit("checksigntoken", "Hello")
          }
        } else {
          goinputcode()
        }
      }


      if (user === false || user === null || user === 'null') {

      } else {
        if (islock) {
          getB()
          getdbstart()
        }
      }
    }

  }, [user])


  const getdbstart = (() => {

    if (user === false || user === null || user === 'null') {

    } else {



      if (signtoken === false || signtoken === null || signtoken === 'null') {

      } else {
        let str = ''
        switch (language) {
          case 'zhcn': str = '正在加載...'; break;
          case 'en': str = 'Loading...'; break;
          default: break;
        }
        message.loading({ content: str, key: 'czlodaingg', duration: 0 });
        getuserinfo(true)
        getbox()
      }
    }
  })

  useEffect(() => {
    getB()
    if (signtoken === false || signtoken === null || signtoken === 'null') {
      setpageInfo(false)
    } else {
      const invcode = getParams.getAll('invcode')
      if (invcode.length > 0) {
        goinputcode()
      }
      getdbstart()
    }

  }, [signtoken])

  const goinputcode = (async () => {

    const invcode = getParams.getAll('invcode')

    let re = axios({
      url: 'active/invite/code',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: invcode[0]
      }
    })
    re.then(async (res) => {
      if (res.success === '000032') {
        getuserinfo()
      } else {

      }
    })
  })



  const getB = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].lbaddresstusdt;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getResidueAmount().call()
    setamount(Number(res) / (10 ** 18))
  })


  const getbox = (() => {
    let re = axios({
      url: 'active/box/select',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      let list = res.content
      setboxn(list.length)
    })
  })



  const getuserinfo = (async (ispd = false) => {
    let that = this
    let re = axios({
      url: 'active/user/info',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        setpageInfo(res.content)
        const invcode = getParams.getAll('invcode')
        if (res.content.isInvite === 0 && invcode.length > 0) {
          goinputcodeend(invcode)
        } else {
          message.destroy('czlodaingg')
        }
      } else {
        message.destroy('czlodaingg')
      }

    })
  })

  const goinputcodeend = ((invcode) => {
    let re = axios({
      url: 'active/invite/inputcode',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: invcode[0]
      }
    })
    re.then(async (res) => {
      message.destroy('czlodaingg')
    })
  })



  const getUsdc1 = (async () => {
    try {
      let ntime = dayjs().unix()
      if (ntime - lbTime <= 2) {
        return false
      } else {
        lbTime = ntime
      }
      if (user === false || user === null || user === 'null') {
        emitter.emit("hiddentc", "Hello")
        return false
      }
      let p = await checkChainId()
      if (p) {

      } else {
        return false
      }
      let csflag = false
      let isres = false

      var contractAddress = linkData[linktype.toString()].lbaddresstusdt;

      message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
      let gaslimit = 1000000
      try {
        gaslimit = await publicClient.estimateContractGas({
          address: contractAddress,
          abi: jsonrc1,
          functionName: 'mint',
          account: user,
        })
      } catch (e) {
        message.destroy('trange')

        goMessor(e)
        return false
      }


      const gasPrice = await publicClient.getGasPrice()
      const nonce = await publicClient.getTransactionCount({ address: user });

      await Promise.race([
        setTimeout(async () => {
          let srequest = false
          try {
            const { request } = await publicClient.simulateContract({
              address: contractAddress,
              abi: jsonrc1,
              functionName: 'mint',
              account: user,
              gas: gaslimit,
              gasPrice: gasPrice,
              nonce: nonce
            })
            isres = true
            srequest = request
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
            return false
          }
          try {
            const hash = await walletClient.writeContract(srequest)
            isres = true
            let errortime = Number(dayjs().add(20, 'second').unix())
            getlistenJy(hash, 1, errortime)
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
          }
        }, 500),
        new Promise(function (resolve, reject) {
          setTimeout(function () {
            message.destroy('trange')
            if (!csflag && !isres) {
              message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
              csflag = true
            }
          }, 30000);
        })
      ]);
    } catch (error) {
    }

  })


  const gocreatcode = (async () => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }


    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    if (code2 === '') {
      message.error({ content: newlocal['inv']['tip5'][language], key: 'trangerror' })
      return false
    }

    let re = axios({
      url: 'active/invite/code',
      method: 'POST',
      isLetUrl: 1,
      headers: {
        token: signtoken
      },
      data: {
        inviteCode: code2
      }
    })
    re.then(async (res) => {
      if (res.success === '000032') {
        message.error({ content: newlocal['inv']['tip6'][language], key: 'trangerror' })
      } else {
        let csflag = false
        let isres = false
        var contractAddress = linkData[linktype.toString()].invaddress;
        message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
        let gaslimit = 1000000
        try {
          gaslimit = await publicClient.estimateContractGas({
            address: contractAddress,
            abi: jsonrc,
            functionName: 'createCode',
            account: user,
            args: [code2]
          })
        } catch (e) {
          message.destroy('trange')

          goMessor(e)
          return false
        }
        const gasPrice = await publicClient.getGasPrice()
        const nonce = await publicClient.getTransactionCount({ address: user });
        await Promise.race([
          setTimeout(async () => {
            let srequest = false
            try {
              const { request } = await publicClient.simulateContract({
                address: contractAddress,
                abi: jsonrc,
                functionName: 'createCode',
                account: user,
                args: [code2],
                gas: gaslimit,
                gasPrice: gasPrice,
                nonce: nonce
              })
              isres = true
              srequest = request
            } catch (err) {
              csflag = true
              goMessor(err)
              message.destroy('trange')
              return false
            }
            try {
              const hash = await walletClient.writeContract(srequest)
              isres = true
              let errortime = Number(dayjs().add(20, 'second').unix())
              getlistenJy(hash, 2, errortime)
            } catch (err) {
              csflag = true
              goMessor(err)
              message.destroy('trange')
            }
          }, 500),
          new Promise(function (resolve, reject) {
            setTimeout(function () {
              message.destroy('trange')
              if (!csflag && !isres) {
                message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
                csflag = true
              }
            }, 30000);
          })
        ]);
      }
    })
  })



  const getlistenJy = (async (result, type, errortime) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var receipt = await web3.eth.getTransactionReceipt(result)
    let nowtime = Number(dayjs().unix())
    if (nowtime > errortime) {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    } else if (receipt === null) {
      getlistenJy(result, type, errortime)
    } else if (receipt.status === true) {
      message.destroy('trange')
      if (type === 1) {
        message.success({ content: <div>{newlocal['main']['p81'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        getB()
        getdbstart()
      }
      if (type === 2) {
        message.success({ content: <div>{newlocal['inv']['tip2'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setTimeout(() => {
          getdbstart()
          window.location.reload()
        }, 1000)
      }
    } else {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }
  })

  const gojypage = ((link) => {
    window.open(`${linkData[linktype.toString()].txklink}${link}`)

  })


  const goMessor = ((error) => {
    let msg = error.message
    console.error(msg)
    if (msg.indexOf('insufficient allowance') >= 0) {
      message.error({ content: newlocal['error']['p1'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('transfer amount exceeds balance') >= 0) {
      message.error({ content: newlocal['error']['p2'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('withdraw amount too much!') >= 0) {
      message.error({ content: newlocal['error']['p3'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('marketId not exist') >= 0) {
      message.error({ content: newlocal['error']['p4'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('intervalId not exist') >= 0) {
      message.error({ content: newlocal['error']['p5'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('total Order Amount is too large') >= 0) {
      message.error({ content: newlocal['error']['p6'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too large') >= 0) {
      message.error({ content: newlocal['error']['p7'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order size is too small') >= 0) {
      message.error({ content: newlocal['error']['p8'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('too many remaining orders') >= 0) {
      message.error({ content: newlocal['error']['p9'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('have the same order') >= 0) {
      message.error({ content: newlocal['error']['p10'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('execution fee is too low') >= 0) {
      message.error({ content: newlocal['error']['p11'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p12'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is not ready') >= 0) {
      message.error({ content: newlocal['error']['p13'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p14'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('order running') >= 0) {
      message.error({ content: newlocal['error']['p15'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('price data is ready') >= 0) {
      message.error({ content: newlocal['error']['p16'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('reward amount is zero') >= 0) {
      message.error({ content: newlocal['error']['p17'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('getRewards approve error') >= 0) {
      message.error({ content: newlocal['error']['p18'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('ZERO_ADDRESS!') >= 0) {
      message.error({ content: newlocal['error']['p19'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('not support') >= 0) {
      message.error({ content: newlocal['error']['p20'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('factoryConfig is not initialize') >= 0) {
      message.error({ content: newlocal['error']['p21'][language], key: 'trangerrorss', duration: 3 })

    } else if (msg.indexOf('CREATE2_FAILED') >= 0) {
      message.error({ content: newlocal['error']['p22'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('The total cost (gas * gas fee + value) of executing this transa') >= 0) {
      message.error({ content: newlocal['error']['p29'][language], key: 'trangerrorss', duration: 3 })
    } else if (msg.indexOf('User denied transaction signature') >= 0) {
      message.error({ content: newlocal['main']['p70'][language], key: 'trangerrorss', duration: 3 })
    } else {
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
    }
  })


  const gobbztwitter = (() => {


    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }



    window.open(`https://twitter.com/bbztrade`, "_blank",);

    setTimeout(() => {
      localStorage.setItem('tw' + user, 'ok')
      setgztw(true)
    }, 20000)

  })

  const golinktwitter = (() => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }

    if (signtoken === false || signtoken === null || signtoken === 'null') {
      emitter.emit("checksigntoken", "Hello")
      return false
    }

    if (pageInfo && pageInfo.twitterId) {
      return false
    }

    let that = this
    let re = axios({
      url: 'active/user/twitter/url',
      method: 'GET',
      isLetUrl: 1,
      headers: {
        token: signtoken
      }
    })
    re.then(async (res) => {
      if (res !== undefined) {
        window.open(res.content, "_blank",);
      }
    })
  })

  const copyToClipboard = ((value) => {
    var text = value;
    var input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    message.success({ content: newlocal['main']['copytip'][language], key: 'sdff' })
  })

  const gologin = (() => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
  })


  const goShare = (() => {

    localStorage.setItem('endtimeshare', String(dayjs().add(60, 'second').unix()))


    let hashtags = ['bbz']
    let msg = `${newlocal['box']['p44'][language]}${boxn}${newlocal['box']['p45'][language]}${amount}${newlocal['box']['p46'][language]}`
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(msg)}&hashtags=${hashtags.join(',')}&url=${'https://tradertest.bbz.com/airdrop?invcode=' + pageInfo.inviteCode}`, "_blank",);

    setTimeout(() => {
      localStorage.setItem('twshare' + user, 'ok')
      setgztw(true)
    }, 60000)
  })
  const goBox = (()=>{
    localStorage.setItem('isLingqu' + user,'ok')
    document.getElementsByClassName("AppH5")[0].scrollTop = 0
    navigate('/blindbox')
  })

  return (
    <div className="lbh5" >
      {
        step === 1 && <div className='step1'>
          <pre className='title'>{newlocal['box']['p47'][language]}</pre>
          <div className='contbox'>
            <div className='p1'>{newlocal['box']['p48'][language]}</div>
            <div className='p2'>{newlocal['box']['p49'][language]}</div>
            <div className='btn' onClick={() => setstep(2)}>{newlocal['box']['p50'][language]}</div>
          </div>
          <div className='imgbox'>
            <img className='img' src={lbh53} />
          </div>
        </div>
      }

      {
        step === 2 && <div className='step2'>
          <pre className='title'>{newlocal['box']['p51'][language]}</pre>
          <div className='steplinebox'>
          <div className='linebox1' >
            {(user === false || user === null || user === 'null') && <div className='jh'>1</div>}
            {(user !== false && user !== null && user !== 'null') && <div className='wc'><img className='wcimg' src={wanchengnew}></img></div>}
            <div className='line'></div>
            {(user === false || user === null || user === 'null') && <div className='wc'>2</div>}
            {(user !== false && user !== null && user !== 'null') && !pageInfo  && <div className='jh'>2</div>}
            {(user !== false && user !== null && user !== 'null') && pageInfo && !pageInfo.twitterId && <div className='jh'>2</div>}
            {(user !== false && user !== null && user !== 'null') && pageInfo && pageInfo.twitterId && <div className='wc'><img className='wcimg' src={wanchengnew}></img></div>}
          </div>
          <div className='linebox2' >
            {(user === false || user === null || user === 'null') && <div className='btn1' onClick={() => gologin()}>{newlocal['box']['p52'][language]}</div>}
            {(user !== false && user !== null && user !== 'null') && <div className='btn2'>{user.substring(0, 4) + '...' + user.substring(user.length - 4, user.length)}</div>}
            {(user === false || user === null || user === 'null') && <div className='btn2'>{newlocal['box']['p53'][language]}</div>}
            {(user !== false && user !== null && user !== 'null') && !pageInfo && <div className='btn1' onClick={() => golinktwitter()}>{newlocal['box']['p53'][language]}</div>}
            {(user !== false && user !== null && user !== 'null') && pageInfo && !pageInfo.twitterId && <div className='btn1' onClick={() => golinktwitter()}>{newlocal['box']['p53'][language]}</div>}
            {(user !== false && user !== null && user !== 'null') && pageInfo && pageInfo.twitterId && <div className='btn2'>@{pageInfo.username}</div>}
          </div>
          </div>

          {(user !== false && user !== null && user !== 'null') && pageInfo && pageInfo.twitterId && <div className='faucetbox'>
            <div className='p3'>{newlocal['box']['p54'][language]}</div>
            <div className='p4'>{newlocal['box']['p55'][language]}</div>
            <div className='infobox'>
              <div className='ip1'>{newlocal['box']['p56'][language]}</div>
              <img className='iimg2' src={lbox} />
              <div className='ip3'>{boxn}</div>
              <img className='iimg4' src={bcoin} />
              <div className='ip5'>{amount} TUSDT</div>
            </div>
            <div className='btnbox'>
              {
                gztw && <pre className='btn4' onClick={() => { goShare() }}>{newlocal['box']['p57'][language]}</pre>
              }
              {
                gztw && <pre className='btn3' onClick={() => { goBox() }}>{newlocal['box']['p58'][language]}</pre>
              }
              {
                !gztw && <pre className='btn3' onClick={() => { goShare() }}>{newlocal['box']['p57'][language]}</pre>
              }
              {
                !gztw && <pre className='btn4'>{newlocal['box']['p58'][language]}</pre>
              }


            </div>
          </div>
          }
        </div>
      }





























      {/* <div className='title'>{newlocal['lb']['p1'][language]}</div>
      <div className='contbox'>
        <div className='box'>
          <div className='p1'>{newlocal['lb']['p19'][language]}</div>
          <div className='p7'>{newlocal['lb']['p191'][language]}</div>
          {
            (!pageInfo || !pageInfo.inviteCode) && <div className='abtn'>
            <input className='fl1' value={code2} placeholder={newlocal['inv']['p12'][language]} onChange={(e) => { setcode2(e.currentTarget.value) }} />
            <div className='fl2' onClick={() => gocreatcode()}>{newlocal['inv']['p13'][language]}</div>
            </div>
          }
          {
            (pageInfo && pageInfo.inviteCode) && <div className='abtn'>
              <div className='fl3'>{window.location.origin + '/?invcode=' + pageInfo.inviteCode}</div>
                <div className='fl2' data-clipboard-text={window.location.origin + '/?invcode=' + pageInfo.inviteCode} onClick={() => { copyToClipboard(window.location.origin + '/?invcode=' + pageInfo.inviteCode) }}>{newlocal['inv']['p16'][language]}</div>
            </div>
          }
          <div className='p7'>{newlocal['lb']['p20'][language]}</div>
          <div className='linkbox' onClick={() => gobbztwitter()}>
            <img className='linkboximg' src={linktwiter1} />
            <div className='linkboxp1'>{newlocal['lb']['p21'][language]}</div>
            {gztw ? <img className='linkboxflag1' src={wancheng} /> : <img className='linkboxflag' src={h5r} />}
          </div>
          <div className='p7'>{newlocal['lb']['p22'][language]}</div>
          <div className='linkbox' onClick={() => golinktwitter()}>
            <img className='linkboximg' src={linktwiter2} />
            <div className='linkboxp1'>{newlocal['lb']['p23'][language]}</div>
            {pageInfo && pageInfo.twitterId ? <img className='linkboxflag1' src={wancheng} /> : <img className='linkboxflag' src={h5r} />}
          </div>
        </div>
        <div className='box'>
          <div className='p1'>{newlocal['lb']['p9'][language]}</div>
          <pre className='p2'>{newlocal['lb']['p24'][language]}</pre>
          {
            amount === 0 ? <div className='btn'>{`${newlocal['lb']['p101'][language]} ${amount} TUSDC`} </div>
              :
              <div className='btn' onClick={() => getUsdc1()}>{`${newlocal['lb']['p101'][language]} ${amount} TUSDC`} </div>
          }
        </div>
      </div> */}
    </div>
  );
}

export default Lb;
