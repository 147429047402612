import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import newlocal from '../../unilt/newlocal'
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import axios from '../../unilt/axios';

function Db() {
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)
  let [pageinfo1, setpageinfo1] = useState(false)
  let [pageinfo2, setpageinfo2] = useState(false)
  let [pageinfo3, setpageinfo3] = useState(false)


  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false
      getdbstart()
      return
    }
  }, [])

  const getdbstart = (() => {
    let str = ''
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    message.loading({ content: str, key: 'czlodaingg', duration: 0 });
    getdb2()
  })

  const getdb1 = (() => {
    let res = axios({
      url: 'pool/summary',
      method: 'GET',
      isLetUrl: 1
    })
    res.then(async (resp) => {
      setpageinfo1(resp.content)
      getdb2()
    })
  })

  const getdb2 = (() => {
    let res = axios({
      url: 'pool/summary',
      method: 'GET',
      isLetUrl: 2
    })
    res.then(async (resp) => {
      setpageinfo2(resp.content)
      getdb3()
    })
  })

  const getdb3 = (() => {
    let res = axios({
      url: 'pool/summary',
      method: 'GET',
      isLetUrl: 3
    })
    res.then(async (resp) => {
      setpageinfo3(resp.content)
      message.destroy('czlodaingg')
    })
  })



  return (
    <div className="db" >
      <div className='title'>{newlocal['db']['p1'][language]}</div>
      <div className='title1'>{`${newlocal['db']['p2'][language]}(${pageinfo1 ? pageinfo1.dateTime : 'loading...'})`}</div>
      <div className='contbox'>


        {/* {
          pageinfo1 &&

          <div className='box'>
            <div className='title2'>{newlocal['db']['p3'][language]}</div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p4'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo1.totalVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p5'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo1.daysVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p6'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo1.totalFee) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p7'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo1.daysFee) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p8'][language]}</span>
              <span className='p2'>{pageinfo1.totalVolumeNumber}</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p9'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo1.dayAverageVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p10'][language]}</span>
              <span className='p2'>{pageinfo1.totalUsers}</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p11'][language]}</span>
              <span className='p2'>{pageinfo1.poolNumber}</span>
            </div>
          </div>
        } */}
        {
          pageinfo2 && <div className='box'>
            <div className='title2'>{newlocal['db']['p31'][language]}</div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p4'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo2.totalVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p5'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo2.daysVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p6'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo2.totalFee) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p7'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo2.daysFee) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p8'][language]}</span>
              <span className='p2'>{pageinfo2.totalVolumeNumber}</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p9'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo2.dayAverageVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p10'][language]}</span>
              <span className='p2'>{pageinfo2.totalUsers}</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p11'][language]}</span>
              <span className='p2'>{pageinfo2.poolNumber}</span>
            </div>
          </div>
        }



        {
          pageinfo3 && <div className='box'>
            <div className='title2'>{newlocal['db']['p32'][language]}</div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p4'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo3.totalVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p5'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo3.daysVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p6'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo3.totalFee) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p7'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo3.daysFee) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p8'][language]}</span>
              <span className='p2'>{pageinfo3.totalVolumeNumber}</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p9'][language]}</span>
              <span className='p2'>{Number(Number(pageinfo3.dayAverageVolume) / 10 ** 18).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDC</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p10'][language]}</span>
              <span className='p2'>{pageinfo3.totalUsers}</span>
            </div>
            <div className='line'>
              <span className='p1'>{newlocal['db']['p11'][language]}</span>
              <span className='p2'>{pageinfo3.poolNumber}</span>
            </div>
          </div>
        }
      </div>
    </div>

  );
}

export default Db;
