import './index.less';
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Badge, message, Popover } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import newlocal from '../../unilt/newlocal'
import linkData from '../../unilt/linkTypeData'
import dayjs from 'dayjs';
import axios from '../../unilt/axios';
import emitter from '../../unilt/emit';
import { getMap } from 'echarts';
import close from '../../static/close.png'
import errico from '../../static/errico.png'

import { useConnect,useDisconnect,useAccount,useNetwork,useSwitchNetwork,usePublicClient,useWalletClient   } from 'wagmi'


var Web3 = require('web3')


const jsonrc1 = [
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [{ "name": "account", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "allowance",
    "inputs": [{ "name": "owner", "type": "address" }, { "name": "spender", "type": "address" }],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "approve",
    "inputs": [{ "name": "spender", "type": "address" }, { "name": "amount", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getUserUnlockesBBZAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getUserUnlockBBZAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getUserUnlockBBZAmount",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getRewardTokens",
    "inputs": [],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'address',
          type: 'address',
        },
      ]
    }]
  },
  {
    "type": "function",
    "name": "getReceivedReward",
    "inputs": [{ "name": "address_token", "type": "address" }],
    "outputs": [{ "name": "amount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getUserReward",
    "inputs": [{ "name": "address_token", "type": "address" }],
    "outputs": [{ "name": "amount", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "symbol",
    "inputs": [],
    "outputs": [{ "name": "symbol", "type": "string" }]
  },
  {
    "type": "function",
    "name": "getUserMultiplierPoints",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "getNotConvertUserMultiplierPoints",
    "inputs": [],
    "outputs": [{ "name": "balance", "type": "uint256" }]
  },
  {
    "type": "function",
    "name": "userMultiplierTrans",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getAllUserReward",
    "inputs": [],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'tokenAdddress',
          type: 'address',
        },
        {
          name: 'amount',
          type: 'uint256',
        }
      ]
    }]
  },
  {
    "type": "function",
    "name": "extractUserReward",
    "inputs": [{ "name": "address _token", "type": "address" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "pledgeBBZ",
    "inputs": [{ "name": "amount", "type": "uint256" }],
    "outputs": []
  },
  {
    "type": "function",
    "name": "releaseBBZtoBBZ",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "extractBBZ",
    "inputs": [],
    "outputs": []
  },
  {
    "type": "function",
    "name": "getSuportToken",
    "inputs": [],
    "outputs": [{
      "name": "list",
      "type": 'tuple[]',
      "components": [
        {
          name: 'address',
          type: 'address',
        },
      ]
    }]
  }
]

let islock = false
let SHTime = 0
let ZYTime = 0
let approveTime = 0
const keymsg = 'loadinddsghy';
let oldtype = 0

let reasutarray = [false, false, false, false, false, false, false, false, false]
let provider = false

function Zq() {
  const { chain } = useNetwork()
  const publicClient = usePublicClient()
  const { data: walletClient} = useWalletClient()
  const language = useSelector(state => state.language)
  const linktype = useSelector(state => state.linktype)
  const user = useSelector(state => state.user)
  const navigate = useNavigate();
  const renderRef = useRef(true)

  let [bbz, setbbz] = useState(0)
  let [esbbz, setesbbz] = useState(0)
  let [shbbzing, setshbbzing] = useState(0)
  let [shbbz, seteshbbz] = useState(0)
  let [jl, setjl] = useState([])
  let [mp, setmp] = useState(0)
  let [tqmp, settqmp] = useState(0)
  let [jla, setjla] = useState([])

  let [approveBox, setApproveBox] = useState(false)

  let [show1, setshow1] = useState(false)
  let [show2, setshow2] = useState(false)
  let [show3, setshow3] = useState(false)
  let [show4, setshow4] = useState(false)

  let [showpriceErrormsg2, setshowpriceErrormsg2] = useState(false)
  let [showpriceErrormsg3, setshowpriceErrormsg3] = useState(false)

  let [zy1, setzy1] = useState(0)

  let [setitem, setsitem] = useState(false)

  let [isSq, setisSq] = useState(false)
  let [sqAmount, setsqAmount] = useState(0)
  const nprovider = useSelector(state => state.nprovider)


  useEffect(() => {
    provider = nprovider
  }, [nprovider])


  useEffect(() => {
    if (renderRef.current) {
      oldtype = 0
      renderRef.current = false
      islock = true
      getdbstart()
      return
    }
  }, [])


  useEffect(() => {
    if (user === false || user === null || user === 'null') {

    } else {
      if (islock) {
        getdbstart()
      }
    }
  }, [user])


  useEffect(() => {
    if (oldtype !== linktype) {
      oldtype = linktype
      reloadPage('new')
    }
  }, [linktype])

  const reloadPage = ((type = 'old') => {
    setTimeout(() => {
      getdbstart()
    }, 1000)
  })



  const checkChainId = (async () => {
    let chainId = `0x${chain.id.toString(16)}`

    if (chainId !== linkData[linktype.toString()].lid) {
      emitter.emit("checkcid", "Hello")
      return false
    } else {
      return true
    }
  })

  const getdbstart = (async () => {
    if (user === false || user === null || user === 'null') {
      return false
    } else {

    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    let str = ''
    switch (language) {
      case 'zhcn': str = '正在加載...'; break;
      case 'en': str = 'Loading...'; break;
      default: break;
    }
    reasutarray = [false, false, false, false, false, false, false, false, false]
    message.loading({ content: str, key: 'trangeloading', duration: 0 });
    getbbz()
    getesbbz()
    getshbbzing()
    getshbbz()
    getAllJl()
    getmp()
    gettqmp()
    getAllJl1()
    getallowance()
  })

  const checkr = (() => {
    for (let i in reasutarray) {
      if (reasutarray[i] === false) {
        return false
      }
    }
    message.destroy('trangeloading')
  })


  const getbbz = (async () => {

    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].lbaddresstbbz;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.balanceOf(user).call()
    setbbz(Number(res) / (10 ** 18))
    reasutarray[0] = true
    checkr()
  })


  const getesbbz = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.balanceOf(user).call()
    setesbbz(Number(res) / (10 ** 18))
    reasutarray[1] = true
    checkr()
  })

  const getshbbzing = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getUserUnlockesBBZAmount().call()
    setshbbzing(Number(res) / (10 ** 18))
    reasutarray[2] = true
    checkr()
  })



  const getshbbz = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getUserUnlockBBZAmount().call()
    seteshbbz(Number(res) / (10 ** 18))
    reasutarray[3] = true
    checkr()
  })

  const getAllJl = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var list = await hy.methods.getSuportToken().call()
    let listt = []
    for (let i in list) {
      let ad = list[i].address
      let m = await findjl(ad)
      listt.push(m)
    }
    setjl(listt)
    reasutarray[4] = true
    checkr()
  })


  const findjl = (async (ad) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var hy1 = await new web3.eth.Contract(jsonrc1, ad)
    var symbol = await hy1.methods.symbol().call()
    var res = await hy.methods.getReceivedReward(ad).call()
    return { symbol: symbol, amount: Number(res) / (10 ** 18) }
  })

  const findjl1 = (async (ad) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var hy1 = await new web3.eth.Contract(jsonrc1, ad)
    var symbol = await hy1.methods.symbol().call()
    var res = await hy.methods.getUserReward(ad).call()
    return { symbol: symbol, amount: Number(res) / (10 ** 18) }
  })



  const getmp = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getUserMultiplierPoints().call()
    setmp(Number(res) / (10 ** 18))
    reasutarray[5] = true
    checkr()
  })

  const gettqmp = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var res = await hy.methods.getNotConvertUserMultiplierPoints().call()
    settqmp(Number(res) / (10 ** 18))
    reasutarray[6] = true
    checkr()
  })

  const gomp = (async () => {
    if (setitem.amount <= 0) {
      message.error({ content: newlocal['zq']['p33'][language] + setitem.text, key: 'trangerror' })
      return false
    }
    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let csflag = false
    let isres = false
    
    var contractAddress = linkData[linktype.toString()].jladdress;

    let gaslimit = 1000000
    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc1,
        functionName: 'userMultiplierTrans',
        account: user,
      })
    } catch (e) {
      message.destroy('trange')
      
      goMessor(e)
      return false
    }

    
    const gasPrice = await publicClient.getGasPrice() 
    const nonce = await publicClient.getTransactionCount({  address: user});

    await Promise.race([
      setTimeout(async ()=>{
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc1,
            functionName: 'userMultiplierTrans',
            account: user,
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getlistenJy(hash, 100, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      },500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })

  const getAllJl1 = (async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var contractAddress = linkData[linktype.toString()].jladdress;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress)
    var list = await hy.methods.getAllUserReward().call()
    let listt = []
    for (let i in list) {
      let ad = list[i].tokenAdddress
      let m = await findjl1(ad)
      m.ad = ad
      listt.push(m)
      // if (Number(m.amount) > 0) {
      //   listt.push(m)
      // }
    }
    setjla(listt)
    reasutarray[7] = true
    checkr()
  })

  const gotq = (async (address) => {
    if (setitem.amount <= 0) {
      message.error({ content: newlocal['zq']['p33'][language] + setitem.text, key: 'trangerror' })
      return false
    }
    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });

    let csflag = false
    let isres = false

    var contractAddress = linkData[linktype.toString()].jladdress;

    let gaslimit = 1000000
    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc1,
        functionName: 'extractUserReward',
        account: user,
        args: [setitem.address]
      })
    } catch (e) {
      message.destroy('trange')
      
      goMessor(e)
      return false
    }


    const gasPrice = await publicClient.getGasPrice() 
    const nonce = await publicClient.getTransactionCount({  address: user});


    await Promise.race([
      setTimeout(async ()=>{
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc1,
            functionName: 'extractUserReward',
            account: user,
            args: [setitem.address],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          let errortime = Number(dayjs().add(20, 'second').unix())
          getlistenJy(hash, 101, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      },500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })

  const goBuyBBz = (() => {
    window.open('https://app.uniswap.org/#/swap')
  })

  const getallowance = (async () => {
    if (user === false || user === null || user === 'null') {
      return false
    }
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user

    var contractAddress = linkData[linktype.toString()].jladdress;
    var contractAddress1 = linkData[linktype.toString()].lbaddresstbbz;
    var hy = await new web3.eth.Contract(jsonrc1, contractAddress1)
    hy.methods.allowance(user, contractAddress).call(function (error, result) {
      if (result === "0") {
        setisSq(true)
        setsqAmount(0)
      } else {
        setisSq(true)
        setsqAmount(Number(result) / (10 ** 18))
      }
    })
    reasutarray[8] = true
    checkr()
  })

  const goZy = (() => {
    if (bbz === 0) {
      message.error({ content: newlocal['zq']['p34'][language], key: 'trangerror' })
    } else if (sqAmount === 0) {
      setApproveBox(true)
    } else {
      setshow1(true)
    }
  })

  const approve = (async () => {
    let ntime = dayjs().unix()
    if (ntime - approveTime <= 2) {
      return false
    } else {
      approveTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    let csflag = false
    let isres = false

    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
    let am = bbz * (10 ** 18)
    am = am.toLocaleString();
    am = am.replace(/[,]/g, '');


    
    var contractAddress = linkData[linktype.toString()].jladdress;
    var contractAddress1 = linkData[linktype.toString()].lbaddresstbbz;

    let gaslimit = 1000000
      try {
        gaslimit = await publicClient.estimateContractGas({
          address: contractAddress1,
          abi: jsonrc1,
          functionName: 'approve',
          account: user,
          args: [contractAddress, am]
        })
      } catch (e) {
        message.destroy('trange')
        
        goMessor(e)
        return false
      }


      const gasPrice = await publicClient.getGasPrice() 
      const nonce = await publicClient.getTransactionCount({  address: user});
  

    await Promise.race([
      setTimeout(async ()=>{
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress1,
            abi: jsonrc1,
            functionName: 'approve',
            account: user,
            args: [contractAddress, am],
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          setApproveBox(false)
          let str = ''
          switch (language) {
            case 'zhcn': str = '正在加載...'; break;
            case 'en': str = 'Loading...'; break;
            default: break;
          }
          message.loading({ content: str, keymsg });
          let errortime = Number(dayjs().add(20, 'second').unix())
          getlistenJy(hash, 6, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      },500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })



  const ZY = (async () => {
    let ntime = dayjs().unix()
    if (ntime - ZYTime <= 2) {
      return false
    } else {
      ZYTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }
    if (bbz === 0) {
      setshowpriceErrormsg2(true)
      return false
    }
    if (bbz < Number(zy1)) {
      setshowpriceErrormsg3(true)
      return false
    }
    if (Number(zy1) <= 0) {
      return false
    }
    if (Number(zy1) > Number(sqAmount)) {
      setApproveBox(true)
      return false
    }
    let csflag = false
    let isres = false

    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user

    var contractAddress = linkData[linktype.toString()].jladdress;

    if (sqAmount !== 0) {
      message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
      let am = zy1 * (10 ** 18)
      am = am.toLocaleString();
      am = am.replace(/[,]/g, '');

      let gaslimit = 1000000
      try {
        gaslimit = await publicClient.estimateContractGas({
          address: contractAddress,
          abi: jsonrc1,
          functionName: 'pledgeBBZ',
          account: user,
          args: [am]
        })
      } catch (e) {
        message.destroy('trange')
        
        goMessor(e)
        return false
      }


      const gasPrice = await publicClient.getGasPrice() 
      const nonce = await publicClient.getTransactionCount({  address: user});

      await Promise.race([
        setTimeout(async ()=>{
          let srequest = false
          try {
            const { request } = await publicClient.simulateContract({
              address: contractAddress,
              abi: jsonrc1,
              functionName: 'pledgeBBZ',
              account: user,
              args: [am],
              gas: gaslimit,
              gasPrice: gasPrice,
              nonce: nonce
            })
            isres = true
            srequest = request
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
            return false
          }
          try {
            const hash = await walletClient.writeContract(srequest)
            isres = true
            setshow1(false)
            let errortime = Number(dayjs().add(20, 'second').unix())
            getlistenJy(hash, 7, errortime)
          } catch (err) {
            csflag = true
            goMessor(err)
            message.destroy('trange')
          }
        },500),
        new Promise(function (resolve, reject) {
          setTimeout(function () {
            message.destroy('trange')
            if (!csflag && !isres) {
              message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
              csflag = true
            }
          }, 30000);
        })
      ]);
    } else {
      setApproveBox(true)
    }

  })

  const SH = (async () => {
    let ntime = dayjs().unix()
    if (ntime - SHTime <= 2) {
      return false
    } else {
      SHTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    if (Number(esbbz - shbbzing) <= 0) {
      message.error({ content: newlocal['zq']['p35'][language], key: 'trangerror' })
      return false
    }



    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user


    let am = esbbz * (10 ** 18)
    am = am.toLocaleString();
    am = am.replace(/[,]/g, '');

    if (Number(am) === 0) {
      message.error({ content: newlocal['zq']['p1'][language], key: 'trangerror' })
      return false
    }


    let csflag = false
    let isres = false



    var contractAddress = linkData[linktype.toString()].jladdress;


    let gaslimit = 1000000
    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc1,
        functionName: 'releaseBBZtoBBZ',
        account: user,
      })
    } catch (e) {
      message.destroy('trange')
      
      goMessor(e)
      return false
    }


    const gasPrice = await publicClient.getGasPrice() 
    const nonce = await publicClient.getTransactionCount({  address: user});
    



    message.destroy('trange')

    await Promise.race([
      setTimeout(async ()=>{
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc1,
            functionName: 'releaseBBZtoBBZ',
            account: user,
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          setshow2(false)
          let errortime = Number(dayjs().add(20, 'second').unix())
          getlistenJy(hash, 5, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      },500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })


  const TQ = (async () => {
    let ntime = dayjs().unix()
    if (ntime - SHTime <= 2) {
      return false
    } else {
      SHTime = ntime
    }
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
    let p = await checkChainId()
    if (p) {

    } else {
      return false
    }

    if (Number(shbbz) <= 0) {
      message.error({ content: newlocal['zq']['p36'][language], key: 'trangerror' })
      return false
    }

    message.loading({ content: newlocal['main']['p69'][language], key: 'trange', duration: 0 });
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user

    let am = shbbz * (10 ** 18)
    am = am.toLocaleString();
    am = am.replace(/[,]/g, '');


    if (Number(am) === 0) {
      message.error({ content: newlocal['zq']['p2'][language], key: 'trangerror' })
      return false
    }


    let csflag = false
    let isres = false

    var contractAddress = linkData[linktype.toString()].jladdress;


    let gaslimit = 1000000
    try {
      gaslimit = await publicClient.estimateContractGas({
        address: contractAddress,
        abi: jsonrc1,
        functionName: 'extractBBZ',
        account: user,
      })
    } catch (e) {
      message.destroy('trange')
      
      goMessor(e)
      return false
    }


    const gasPrice = await publicClient.getGasPrice() 
    const nonce = await publicClient.getTransactionCount({  address: user});
    message.destroy('trange')



    await Promise.race([
      setTimeout(async ()=>{
        let srequest = false
        try {
          const { request } = await publicClient.simulateContract({
            address: contractAddress,
            abi: jsonrc1,
            functionName: 'extractBBZ',
            account: user,
            gas: gaslimit,
            gasPrice: gasPrice,
            nonce: nonce
          })
          isres = true
          srequest = request
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
          return false
        }
        try {
          const hash = await walletClient.writeContract(srequest)
          isres = true
          setshow2(false)
          let errortime = Number(dayjs().add(20, 'second').unix())
          getlistenJy(hash, 9, errortime)
        } catch (err) {
          csflag = true
          goMessor(err)
          message.destroy('trange')
        }
      },500),
      new Promise(function (resolve, reject) {
        setTimeout(function () {
          message.destroy('trange')
          if (!csflag && !isres) {
            message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
            csflag = true
          }
        }, 30000);
      })
    ]);
  })

  const getlistenJy = (async (result, type, errortime) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(linkData[linktype.toString()].rpcaddress))
    web3.eth.defaultAccount = user
    var receipt = await web3.eth.getTransactionReceipt(result)
    let nowtime = Number(dayjs().unix())
    console.log('listen-hash', result, receipt)
    if (nowtime > errortime) {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    } else if (receipt === null) {
      getlistenJy(result, type, errortime)
    } else if (receipt.status === true) {
      message.destroy('trange')
      if (type === 5) {
        message.success({ content: <div>{newlocal['main']['p76'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow1(false)
        setshow2(false)
        setshow3(false)
        getdbstart()
      }
      if (type === 7) {
        message.success({ content: <div>{newlocal['main']['p77'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow1(false)
        setshow2(false)
        setshow3(false)
        getdbstart()
      }
      if (type === 9) {
        message.success({ content: <div>{newlocal['zq']['p3'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow1(false)
        setshow2(false)
        setshow3(false)
        getdbstart()
      }
      if (type === 6) {
        message.success({ content: <div>{newlocal['main']['p78'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setApproveBox(false)
        getdbstart()
      }
      if (type === 100) {
        message.success({ content: <div>{newlocal['main']['p78'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow4(false)
        getmp()
        gettqmp()
      }
      if (type === 101) {
        message.success({ content: <div>{newlocal['zq']['p3'][language]},<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gojypage(receipt.transactionHash)}>{newlocal['main']['p73'][language]}</div></div>, duration: 3 });
        setshow4(false)
        getAllJl()
        getAllJl1()
      }
    } else {
      message.destroy('trange')
      message.error({ content: <div><div>{newlocal['zq']['p3'][language]}</div><div>{newlocal['main']['p83'][language]}</div><div>{newlocal['main']['p84'][language]}</div></div>, duration: 3 });
    }
  })


  const gojypage = ((link) => {
    window.open(`${linkData[linktype.toString()].txklink}${link}`)

  })


  const goshow = ((text, amount, type, address = '') => {

    setsitem({
      text,
      amount,
      type,
      address
    })
    setshow4(true)
  })


  const goTQBBZ = (() => {
    if (shbbz <= 0) {
      message.error({ content: newlocal['zq']['p36'][language], key: 'trangerror' })
      return false
    } else {
      setshow3(true)
    }
  })


  const gologin = (() => {
    if (user === false || user === null || user === 'null') {
      emitter.emit("hiddentc", "Hello")
      return false
    }
  })


  const goMessor = ((error) => {
    let msg = error.message
    console.error(msg)
    if (msg.indexOf('insufficient allowance') >= 0) {
      message.error({ content: newlocal['error']['p1'][language], key: 'trangerrorss', duration: 3})
    } else if (msg.indexOf('transfer amount exceeds balance') >= 0) {
      message.error({ content: newlocal['error']['p2'][language], key: 'trangerrorss', duration: 3})
    } else if (msg.indexOf('withdraw amount too much!') >= 0) {
      message.error({ content: newlocal['error']['p3'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('marketId not exist') >= 0) {
      message.error({ content: newlocal['error']['p4'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('intervalId not exist') >= 0) {
      message.error({ content: newlocal['error']['p5'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('total Order Amount is too large') >= 0) {
      message.error({ content: newlocal['error']['p6'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('order size is too large') >= 0) {
      message.error({ content: newlocal['error']['p7'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('order size is too small') >= 0) {
      message.error({ content: newlocal['error']['p8'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('too many remaining orders') >= 0) {
      message.error({ content: newlocal['error']['p9'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('have the same order') >= 0) {
      message.error({ content: newlocal['error']['p10'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('execution fee is too low') >= 0) {
      message.error({ content: newlocal['error']['p11'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p12'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('price data is not ready') >= 0) {
      message.error({ content: newlocal['error']['p13'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('order not exist') >= 0) {
      message.error({ content: newlocal['error']['p14'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('order running') >= 0) {
      message.error({ content: newlocal['error']['p15'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('price data is ready') >= 0) {
      message.error({ content: newlocal['error']['p16'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('reward amount is zero') >= 0) {
      message.error({ content: newlocal['error']['p17'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('getRewards approve error') >= 0) {
      message.error({ content: newlocal['error']['p18'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('ZERO_ADDRESS!') >= 0) {
      message.error({ content: newlocal['error']['p19'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('not support') >= 0) {
      message.error({ content: newlocal['error']['p20'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('factoryConfig is not initialize') >= 0) {
      message.error({ content: newlocal['error']['p21'][language], key: 'trangerrorss', duration: 3})

    } else if (msg.indexOf('CREATE2_FAILED') >= 0) {
      message.error({ content: newlocal['error']['p22'][language], key: 'trangerrorss', duration: 3})
    } else if (msg.indexOf('The total cost (gas * gas fee + value) of executing this transa') >= 0) {
      message.error({ content: newlocal['error']['p29'][language], key: 'trangerrorss', duration: 3})
    }else if (msg.indexOf('User denied transaction signature') >= 0) {
      message.error({ content: newlocal['main']['p70'][language], key: 'trangerrorss', duration: 3})
    } else {
      message.error({ content: <div><div>{newlocal['main']['p82'][language]}</div><div>{newlocal['main']['p831'][language]}</div><div>{newlocal['main']['p832'][language]}</div></div>, key: 'trangerror', duration: 3 });
    }
  })


  return (
    <div className="zq" >
      <div className='title'>{newlocal['zq']['p4'][language]}</div>
      <div className='title1'>{newlocal['zq']['p5'][language]}</div>
      <div className='contbox'>


        <div className='box'>
          <div className='title2'>BBZ</div>
          <div className='mode mode1'>
            <div className='line'>
              <Popover content={<div className='popbox'>
                <pre className='popname'>{newlocal['zq']['p37'][language]}</pre>
              </div>} trigger="hover">
                <div className='p1 p3'>{newlocal['zq']['p6'][language]}</div>
              </Popover>
              <div className='c1'>$0.01</div>
            </div>
            <div className='line'>
              <div className='p1'>{newlocal['zq']['p7'][language]}</div>
              <div className='c1'>{Number(Number(bbz)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} BBZ (${Number(Number(bbz) * 0.01).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</div>
            </div>
          </div>
          <div className='mode mode1'>
            <div className='line'>
              <Popover content={<div className='popbox'>
                <pre className='popname'>{newlocal['zq']['p38'][language]}</pre>
              </div>} trigger="hover">
                <div className='p1 p3'>{newlocal['zq']['p8'][language]}</div>
              </Popover>
              <div className='c1'>{Number(Number(esbbz)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} esBBZ (${Number(Number(esbbz) * 0.01).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</div>
            </div>
            <div className='line'>
              <Popover content={<div className='popbox'>
                <pre className='popname'>{newlocal['zq']['p39'][language]}</pre>
              </div>} trigger="hover">
                <div className='p1 p3'>{newlocal['zq']['p9'][language]}</div>
              </Popover>
              <div className='c1'>{Number(Number(shbbzing)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} esBBZ (${Number(Number(shbbzing) * 0.01).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</div>
            </div>
            <div className='line'>
              <Popover content={<div className='popbox'>
                <pre className='popname'>{newlocal['zq']['p40'][language]}</pre>
              </div>} trigger="hover">
                <div className='p1 p3'>{newlocal['zq']['p10'][language]}</div>
              </Popover>
              <div className='c1'>{Number(Number(shbbz)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} BBZ (${Number(Number(shbbz) * 0.01).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</div>
            </div>
          </div>
          <div className='mode mode1'>
            {
              jl.map((item) => {
                return <div className='line'>
                  <div className='p1'>{newlocal['zq']['p11'][language]}({item.symbol})</div>
                  <div className='c1'>{Number(Number(item.amount)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                </div>
              })
            }

            <div className='line'>
              <div className='p1'>{newlocal['zq']['p12'][language]}</div>
              <div className='c1'>{Number(Number(mp)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
          </div>
          {
            (user === false || user === null || user === '') && <div className='btnbox'>
              <div className='btn1' onClick={() => gologin()}>{newlocal["topMode"]["btn2"][language]}</div>
            </div>
          }
          {
            user !== false && user !== "" && user !== null && <div className='btnbox'>
              <div className='btn' onClick={() => goBuyBBz()}>{newlocal['zq']['p13'][language]}</div>
              <div className='btn' onClick={() => goZy()}>{newlocal['zq']['p14'][language]}</div>
              <div className='btn' onClick={() => setshow2(true)}>{newlocal['zq']['p15'][language]}</div>
              <div className='btn' onClick={() => goTQBBZ()}>{newlocal['zq']['p16'][language]}</div>
            </div>
          }
        </div>

        <div className='box'>
          <div className='title2'>{newlocal['zq']['p17'][language]}</div>
          <div className='mode mode1'>
            <div className='line'>
              <Popover content={<div className='popbox'>
                <pre className='popname'>{newlocal['zq']['p41'][language]}</pre>
              </div>} trigger="hover">
                <div className='p1 p3'>{newlocal['zq']['p18'][language]}</div>
              </Popover>
              <div className='c1'>{Number(Number(tqmp)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<div className='btn' onClick={() => goshow(newlocal['zq']['p46'][language], tqmp, 1, '')} >{newlocal['zq']['p19'][language]}</div></div>
            </div>
          </div>
          {
            jla.length > 0 && <div className='mode mode2'>
              {
                jla.map((item) => {
                  return <div className='line'>
                    <Popover content={<div className='popbox'>
                      <pre className='popname'>{newlocal['zq']['p42'][language]}{item.symbol}{newlocal['zq']['p43'][language]}</pre>
                    </div>} trigger="hover">
                      <div className='p1 p3'>{item.symbol}</div>
                    </Popover>
                    <div className='c1'>{Number(Number(item.amount)).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<div className='btn' onClick={() => goshow(item.symbol, item.amount, 2, item.ad)} >{newlocal['zq']['p20'][language]}</div></div>
                  </div>
                })
              }
            </div>
          }
        </div>
      </div>
      {
        approveBox && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p51'][language] + ' ' + ('tbbz')}</div>
            <img className='closeico' src={close} onClick={() => setApproveBox(false)}></img>
            <div className='info'>{newlocal['main']['p52'][language] + ' ' + ('tbbz') + ' ' + (Number(linktype) === 1 ? newlocal['main']['p53arb'][language] : newlocal['main']['p53bsc'][language])}</div>

            <div className='btn' onClick={() => approve(false)}>
              <span className='t'>{newlocal['main']['p54'][language]}</span>
            </div>

          </div>
        </div>
      }
      {
        show1 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p39'][language]}</div>
            <div className='title1'>{newlocal['zq']['p21'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow1(false)}></img>

            <div className='nummberbox'>
              <div className='top'>
                <div className='tp1'>{newlocal['main']['p41'][language]}</div>
                <div className='tp2'>{newlocal['main']['p42'][language]}：{sqAmount ? Number(sqAmount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</div>
              </div>
              <div className='bottom'>
                <input className='bt1' type={'nummber'} value={zy1} onChange={(e) => { setzy1(e.currentTarget.value.replace(/^(0+)|[^\d]+/g, '')) }} />
                <div className='bp2'>{('tbbz')}</div>
              </div>
            </div>
            {
              showpriceErrormsg2 && <div className='priceErrBox' >
                <img className='priceErrIco' src={errico} />
                <div className='priceErrText '>{`${newlocal['main']['p59'][language]} ${'tbbz'}`}<span onClick={() => goBuyBBz()} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#9096AD', marginLeft: '10px' }}>{`${newlocal['main']['p60'][language]} ${'tbbz'}`}</span></div>
              </div>
            }
            {
              showpriceErrormsg3 && <div className='priceErrBox' >
                <img className='priceErrIco' src={errico} />
                <div className='priceErrText '>{`${newlocal['main']['p48'][language]}`}</div>
              </div>
            }
            <div className='btn' onClick={() => ZY()}>
              <span className='t'>{newlocal['main']['p43'][language]}</span>
            </div>

          </div>
        </div>
      }
      {
        show2 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['main']['p40'][language]}</div>
            <div className='title1'>{newlocal['zq']['p22'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow2(false)}></img>
            <div className='nummberbox'>
              <div className='top1'>
                <div className='tp1'>{newlocal['zq']['p45'][language]}</div>
                <div className='tp2'>{newlocal['zq']['p44'][language]}{esbbz - shbbzing ? Number(esbbz - shbbzing).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}  </div>
              </div>
              <div className='bottom1'>
                <input className='bt1' type={'nummber'} value={Number(esbbz - shbbzing).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} readOnly={true} />
                <div className='bp3'>esBBZ</div>
              </div>
            </div>

            <div className='btn' onClick={() => SH()}>
              <span className='t'>{newlocal['main']['p46'][language]}</span>
            </div>

          </div>
        </div>
      }
      {
        show3 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['zq']['p25'][language]}</div>
            <div className='title1'>{newlocal['zq']['p26'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow3(false)}></img>
            <div className='nummberbox'>
              <div className='top1'>
                <div className='tp1'>{newlocal['zq']['p45'][language]}</div>
                <div className='tp2'>{newlocal['zq']['p44'][language]}{shbbz ? Number(shbbz).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}  </div>
              </div>
              <div className='bottom1'>
                <input className='bt1' type={'nummber'} value={Number(shbbz).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} readOnly={true} />
                <div className='bp3'>BBZ</div>
              </div>
            </div>

            <div className='btn' onClick={() => TQ()}>
              <span className='t'>{newlocal['main']['p46'][language]}</span>
            </div>

          </div>
        </div>
      }
      {
        show4 && <div className='langboxmask'>
          <div className='langBox' >
            <div className='title'>{newlocal['zq']['p27'][language]}{' '}{setitem.text}</div>
            <div className='title1'>{newlocal['zq']['p30'][language]}</div>
            <img className='closeico' src={close} onClick={() => setshow4(false)}></img>
            <div className='nummberbox'>
              <div className='top1'>
                <div className='tp1'>{newlocal['zq']['p45'][language]}</div>
                <div className='tp2'>{newlocal['zq']['p44'][language]}{setitem.amount ? Number(setitem.amount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}  </div>
              </div>
              <div className='bottom1'>
                <input className='bt1' type={'nummber'} value={Number(setitem.amount).toLocaleString('EN-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} readOnly={true} />
                <div className='bp3'>{setitem.text}</div>
              </div>
            </div>

            <div className='btn' onClick={() => { setitem.type === 1 ? gomp() : gotq() }}>
              <span className='t'>{newlocal['main']['p46'][language]}</span>
            </div>

          </div>
        </div>
      }
    </div>

  );
}

export default Zq;
